/** @format */

export class HomeDefault {
	dsg: any;
	gg: any;

	constructor() {
		this.dsg = this.dsgDefault();
		this.gg = this.ggDefault();
	}

	private dsgDefault() {
		return {
			templateName: 'hp_2d',
			templateTag: '2d_expNA',
			tsid: '1470486e61d92019-09-09T15:08:25',
			pageTitle: null,
			metaDescription: null,
			staticId: null,
			content: {
				heroV2: [
					{
						image: 'DSG_HP_Odyssey_Backup_BTS_Hero',
						link: '/c/top-trends-gear',
						headline: 'Top Trends & Gear',
						altText: 'Top Trends & Gear',
						description: `Discover What's Hot Now`,
						textBoxPosition: 'center',
						textBlockBackground: 'true',
						textColor: 'dark',
						cta: [
							{
								link: '/c/top-trends-gear',
								linkTitle: 'Shop Now'
							}
						]
					}
				],
				trending: [
					{
						image: 'DSG_HP_Odyssey_Backup_BTS_Trends1_SportsMatter',
						link: 'https://www.sportsmatter.org/',
						headline: 'Help Us. Help Them.',
						altText: 'Help Us. Help Them.',
						description: 'Help Us Give 1 Million Kids a Chance to Play',
						cta: [
							{
								linkTitle: 'Learn More',
								link: 'https://www.sportsmatter.org/'
							},
							{
								linkTitle: 'Make a Donation',
								link: 'https://www.sportsmatter.org/donate/'
							}
						]
					},
					{
						image: 'DSG_HP_Odyssey_Backup_BTS_Trends2_FTW',
						link: '/s/footwear-release-calendar',
						headline: 'Just Arrived',
						description: 'New Releases From Your Favorite Brands',
						altText: 'Just Arrived',
						cta: [
							{
								link: '/s/footwear-release-calendar',
								linkTitle: 'Shop Sneaker Release Calendar'
							}
						]
					},
					{
						image: 'DSG_HP_Odyssey_Backup_BTS_Trends3_TWD',
						link: '/c/this-weeks-deals',
						headline: 'UP TO 50% OFF',
						description: `This Week's Deals`,
						altText: `This Week's Deals`,
						cta: [
							{
								linkTitle: 'Shop Now',
								link: '/c/this-weeks-deals'
							}
						]
					}
				],
				fullStoryV2: [
					{
						image: 'DSG_HP_Odyssey_Backup_BTS_Full',
						link: '/s/in-store-pickup',
						textBoxPosition: 'right',
						headline: 'Shopping Made Simple',
						description: 'Your Favorite Brands. The Hottest Styles. Fast.',
						textBlockBackground: 'true',
						textColor: 'dark',
						altText: 'Shopping Made Simple',
						cta: [
							{
								link: '/s/in-store-pickup',
								linkTitle: 'Free One-Hour Pickup. Details'
							},
							{
								linkTitle: 'Best Price Guarantee. Learn More',
								link: '/s/price-match-policy'
							}
						]
					}
				]
			}
		};
	}

	private ggDefault() {
		return {
			templateName: 'hp_2d',
			templateTag: '2d_expNA',
			tsid: 'b397df7708842019-09-10T14:36:77',
			pageTitle: null,
			metaDescription:
				'Visit Golf Galaxy to shop a wide selection of golf clubs, apparel & equipment from the top brands! Improve your game with services from our expert golf pros.',
			staticId: 'Golf Galaxy - Official Website',
			content: {
				heroV2: [
					{
						headline: 'Major Savings',
						image: 'GGHP_OdysseyBackupQ3_TWD',
						altText: 'Major Savings',
						Subhead: 'Save on the Game’s Best Brands',
						textBoxPosition: 'right',
						textBlockBackground: 'true',
						textColor: 'light',
						link: '/s/our-top-deals',
						cta: [
							{
								linkTitle: 'Shop Now',
								link: '/s/our-top-deals'
							}
						]
					}
				],
				trending: [
					{
						image: 'GGHP_OdysseyBackupQ3_NewArrivals',
						headline: 'New Arrivals',
						altText: 'New Arrivals',
						Subhead: 'Apparel, Equipment & More',
						link: '/c/new-arrivals-golf',
						cta: [
							{
								linkTitle: 'Shop Now',
								link: '/c/new-arrivals-golf'
							}
						]
					},
					{
						image: 'GGHP_OdysseyBackupQ3_Services',
						altText: 'Tour-Level Services',
						headline: 'Tour-Level Services',
						link: '/s/golf-services',
						Subhead: 'Get Fit for Your Game',
						cta: [
							{
								link: '/s/golf-services',
								linkTitle: 'Learn More'
							}
						]
					},
					{
						image: 'GGHP_OdysseyBackupQ3_LaunchCal',
						headline: 'Golf Launch Calendar',
						altText: 'Golf Launch Calendar',
						Subhead: 'Your First Look at Upcoming Gear\t',
						link: '/s/golf-launch-calendar',
						cta: [
							{
								linkTitle: 'Shop Now',
								link: '/s/golf-launch-calendar'
							}
						]
					}
				],
				fullStoryV2: [
					{
						textBoxPosition: 'center',
						headline: 'In-Store Events',
						image: 'GGHP_OdysseyBackupQ3_InStoreEvents',
						Subhead: 'See What’s Happening at Your Local Golf Galaxy',
						altText: 'In-Store Events',
						link: '/s/golf-events-and-in-store-golf-demonstrations',
						textBlockBackground: 'true',
						textColor: 'light',
						cta: [
							{
								link: '/s/golf-events-and-in-store-golf-demonstrations',
								linkTitle: 'Learn More'
							}
						]
					}
				]
			}
		};
	}

	public getDefault(chain: string) {
		return this[chain];
	}
}
