/** @format */

import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CartDao } from '../dao/cart.dao';
import { CartAnalyticsService } from '../service/cart.analytics.service';
import { Logger } from 'libs/dcsg-ngx-ecommerce-log/src/lib/service/logger.service';
import { Chain } from '@dcsg-ngx-ecommerce/core/model/chain.model';
import { CookieService } from '@dcsg-ngx-ecommerce/core/service/cookie.service';
import { DeviceService } from '@dcsg-ngx-ecommerce/core/service/device.service';
import { LocalStorageService } from '@dcsg-ngx-ecommerce/core/service/local-storage.service';
import { Store } from '@dcsg-ngx-ecommerce/core/persistence/app.store';
import { UtilityService } from '@dcsg-ngx-ecommerce/core/service/utility.service';
import { WINDOW } from '@dcsg-ngx-ecommerce/core/service/window.service';
import {
	EmitEvent,
	EventBusService,
	Events
} from '@dcsg-ngx-ecommerce/core/service/event-bus.service';
import {
	ATCItem,
	AtcMessage,
	IATC,
	LaunchConfig,
	PostATCEvent
} from '@shared/data-access/interfaces';

@Injectable({
	providedIn: 'root'
})
export class CartService {
	constructor(
		private analyticsService: CartAnalyticsService,
		private cartDao: CartDao,
		private chain: Chain,
		private cookieService: CookieService,
		private deviceService: DeviceService,
		private localStorage: LocalStorageService,
		private store: Store,
		private logger: Logger,
		private utilityService: UtilityService,
		@Inject(WINDOW) protected window: Window,
		private eventBusService: EventBusService
	) {}

	// constant
	public readonly ANALYTICS_ATC_ERROR_ID = 'DCSG_NGX_PDP_ATC_ERROR';
	public readonly ANALYTICS_ATC_ERROR_MESSAGE_PREFIX = 'PDP:';

	// component level variables
	public storeId = this.chain.storeId;
	protected chainIdentifierAbbr = this.chain.chainIdentifierAbbr;

	/**
	 * @description
	 * Initializes the Cart Service.  Does any data setup as necessary.
	 */
	public initialize(): void {
		// Current cart count
		let cartCount: string;

		/*
		 * Use the NGX cookie as the primary data, and override from the legacy cookie where necessary
		 */
		// Check to see if NGX cart count cookie is present
		if (this.cookieService.check('DCSG_NGX_CART_COUNT')) {
			// Set the Cart count
			cartCount = this.cookieService.get('DCSG_NGX_CART_COUNT');
		}

		// Check to see if DSG or GG mobile / tablet
		if (
			(this.chain.serviceIdentifierAbbr === 'dsg' ||
				this.chain.serviceIdentifierAbbr === 'gg') &&
			!this.deviceService.isDesktop()
		) {
			// Check to see if legacy cartCount cookie is present for DSG mobile / tablet
			if (this.cookieService.check('cartCount')) {
				// Set the Cart count
				cartCount = this.cookieService.get('cartCount');
			}
			// Check to see if DSG or GG desktop
		} else if (
			(this.chain.serviceIdentifierAbbr === 'dsg' ||
				this.chain.serviceIdentifierAbbr === 'gg') &&
			this.cookieService.check('DSG_CartQTY')
		) {
			// Set the Cart count
			cartCount = this.cookieService.get('DSG_CartQTY');
		}

		// Check to see if there is anything to set
		if (
			typeof cartCount !== 'undefined' &&
			typeof cartCount === 'string' &&
			cartCount.trim() !== '' &&
			cartCount.length > 0
		) {
			// Set the Cart count on the Store
			this.store.set(
				'CART_COUNT',
				isNaN(parseInt(cartCount, 10)) ? 0 : parseInt(cartCount, 10)
			);
		}

		// Listen for updates to CART_COUNT to update appropriate cookies
		this.store.select('CART_COUNT').subscribe((updatedCount: number) => {
			// Set the cart cookies
			this.setCartCookies(updatedCount);
		});
	}

	/**
	 * @description
	 * adds item(s) to cart and calls process methods for logging and events
	 * @param atcItemList
	 */
	public async addToCart(atcItemList: ATCItem): Promise<void> {
		// Fulfillment store
		let fulfillmentStore: string;

		// Flag for if Cart is new, defaulted to true
		let isNewCart = true;

		// Check for DCSG-CART cookie
		if (this.cookieService.check('DCSG-CART')) {
			// Update the flag
			isNewCart = false;
		}

		// Flag for bopl orders
		let isBopl = false;

		// Set flag based on bopis/bopl option chosen in add to cart
		const itemIsStorePickup = atcItemList.atcObject.find((x: IATC) => {
			if (x.isBopl) {
				isBopl = true;
			}
			return x.isBopis === true || x.isBopl === true;
		});

		// check for the bopis store on the basis of isBOPIS flag from the UI, Bopis store cookie, bopis flag for chain store
		if (
			itemIsStorePickup !== undefined &&
			this.cookieService.check('setStoreCookie') &&
			this.chain.bopisFlag
		) {
			// Set the BOPIS store ID; cookie sample value 15120_HOMESTEAD_129
			fulfillmentStore = this.cookieService.get('setStoreCookie').split('_')[2];
		}

		const postATCEventList: PostATCEvent[] = [];

		let itemInCall: number;

		if (Array.isArray(atcItemList.atcObject)) {
			itemInCall = atcItemList.atcObject.length;
		}

		// single item
		if (itemInCall === 1) {
			const atcObject = atcItemList.atcObject[0];
			let response: any;

			// Make ATC call
			try {
				response = await this.cartDao
					.addSkuToCart$(
						atcObject.skuId,
						atcObject.quantity,
						fulfillmentStore,
						atcObject.personalizationData,
						atcObject.customizationData,
						atcItemList.retries,
						atcItemList.launchConfig,
						isBopl,
						atcObject.zipCode,
						atcObject.isSameDayDelivery
					)
					.toPromise();

				this.processAddSkuToCartSuccess(
					isNewCart,
					atcItemList,
					fulfillmentStore,
					response,
					postATCEventList
				);
			} catch (error) {
				this.processAddSkuToCartFailure(error, atcItemList, postATCEventList);
			}
			// multiple items
		} else if (itemInCall > 1) {
			this.cartDao
				.addSkuCollectionToCart$(
					atcItemList.atcObject,
					fulfillmentStore,
					atcItemList.retries
				)
				.subscribe(
					(response) => {
						this.processAddSkuToCartSuccess(
							isNewCart,
							atcItemList,
							fulfillmentStore,
							response,
							postATCEventList
						);
					},
					(error) => {
						this.processAddSkuToCartFailure(error, atcItemList, postATCEventList);
					}
				);
		}
	}

	public addSaveForLaterSkuToCart$(atcItem, retries = 1): Observable<any> {
		const launchConfig: LaunchConfig = {
			isLaunch: false,
			isSecureLaunch: false,
			launchCookies: null,
			cartHeader: null,
			handshakeParam: null,
			allowQueryParamOverride: false
		};

		return this.cartDao.addSkuToCart$(
			atcItem.sku,
			atcItem.qty,
			null,
			atcItem.personalizationData,
			atcItem.customizationData,
			retries,
			launchConfig
		);
	}

	/**
	 * Returns an AtcMessage for displaying
	 * @param result
	 * @param sku
	 * @param message
	 * @param qty
	 * @param isBopisSelected
	 * @param store
	 */
	public generateSuccessMessage(
		result: string,
		skuId: string,
		message: string,
		qty: number,
		isBopisSelected: boolean,
		store: string,
		productEcode: string,
		productTitle: string,
		callOrigin: string
	): AtcMessage {
		return {
			callOrigin,
			success: result,
			skuId,
			quantity: qty,
			chain: this.chainIdentifierAbbr,
			eCode: productEcode,
			errorMessage: message,
			bopisSelected: isBopisSelected,
			browser: this.window.navigator.userAgent,
			bopisStore: store,
			title: productTitle,
			dcsgNgxPageUrl: this.window.location,
			deviceInfo: this.deviceService.getDeviceInfo(),
			swimlane: this.window?._dsgTag?.customerLane,
			logType: 'ATC'
		};
	}

	/**
	 * Fires analytics Event for ATC error
	 */
	public fireATCErrorAnalyticEvent(error: any) {
		// Error message
		const errorMessage = `${this.ANALYTICS_ATC_ERROR_MESSAGE_PREFIX}${error.message}`;

		// Set error ID
		this.analyticsService.setErrorId(this.ANALYTICS_ATC_ERROR_ID);

		// Set error message
		this.analyticsService.setErrorMessage(errorMessage);

		// Trigger Analytics error
		this.analyticsService.triggerAnalyticsEvent(this.ANALYTICS_ATC_ERROR_ID, {
			ErrorMessage: errorMessage
		});
	}

	// TODO: Check the expires logic!
	/**
	 * @description
	 * Sets the cart cookies
	 */
	private setCartCookies(count: number): void {
		// Don't set a cookie value of 0 since it's the default value
		if (count > 0) {
			// Update the cookie
			this.cookieService.set('DCSG_NGX_CART_COUNT', `${count}`, 30, '/');

			// Check to see if DSG or GG and mobile
			if (
				(this.chain.serviceIdentifierAbbr === 'dsg' ||
					this.chain.serviceIdentifierAbbr === 'gg') &&
				!this.deviceService.isDesktop()
			) {
				// Check to see if legacy cartCount cookie is present for DSG mobile / tablet
				this.cookieService.set('cartCount', `${count}`, 30, '/');
				// Check to see if DSG or gg
			} else if (
				this.chain.serviceIdentifierAbbr === 'dsg' ||
				this.chain.serviceIdentifierAbbr === 'gg'
			) {
				// Check to see if legacy cartCount cookie is present for DSG desktop
				this.cookieService.set('DSG_CartQTY', `${count}`, 30, '/');
			} else {
				// Update localStorage for F&S
				this.localStorage.set('miniCartCount', `${count}`);
			}
		} else {
			// We need to remove the miniCartCount from local storage if the count is 0
			this.localStorage.remove('miniCartCount');
		}
	}

	/**
	 * @description
	 * fires error analytic, logs event, and emits event for a failed add to cart call
	 * @param error
	 * @param atcItemList
	 * @param postATCEventList
	 */
	private processAddSkuToCartFailure(
		error: any,
		atcItemList: ATCItem,
		postATCEventList: PostATCEvent[]
	): void {
		// Fire analytics
		this.fireATCErrorAnalyticEvent(error);

		for (let index = 0, length = atcItemList.atcObject.length; index < length; index += 1) {
			const atcObject = atcItemList.atcObject[index];

			const postATCFailEvent: PostATCEvent = {
				atcObject,
				launchConfig: atcItemList.launchConfig,
				serviceResponse: error.message,
				success: false,
				serviceStatus: error.status
			};

			postATCEventList.push(postATCFailEvent);

			this.logger.log(
				this.generateSuccessMessage(
					'false',
					atcObject.skuId,
					'none',
					atcObject.quantity,
					atcItemList.shipModeValue,
					this.storeId,
					atcObject.productEcode,
					atcObject.productTitle,
					atcObject.ATCOriginated
				)
			);
		}

		this.eventBusService.emit(new EmitEvent(Events.PostATCEvent, postATCEventList));
	}

	/**
	 * @description
	 * fires success analytic, logs event, and emits event for a successful add to cart call
	 * @param isNewCart
	 * @param atcItemList
	 * @param fulfillmentStore
	 * @param response
	 * @param postATCEventList
	 */
	private processAddSkuToCartSuccess(
		isNewCart: boolean,
		atcItemList: ATCItem,
		fulfillmentStore: string,
		response: any,
		postATCEventList: PostATCEvent[]
	): void {
		const atcProducts = [];

		/*
		 * Analytics Event
		 */
		this.analyticsService.setType('event', 'AddToCart');

		this.analyticsService.setNewCart(isNewCart);

		for (let i = 0, len = atcItemList.atcObject.length; i < len; ++i) {
			const atcObject = atcItemList.atcObject[i];

			// Convert the quantity added
			const quantityAdded = this.utilityService.convertToNumber(atcObject.quantity);

			// Default the fulfillment method to Ship to Customer
			let fulfillmentMethod = 'Ship To Customer';

			if (atcObject.isBopis) {
				fulfillmentMethod = 'In Store Pickup (BOPIS)';
			} else if (atcObject.isBopl) {
				fulfillmentMethod = 'In Store Pickup (BOPL)';
			} else if (atcObject.isSameDayDelivery) {
				fulfillmentMethod = 'SAMEDAY';
			}

			// Update the Cart count in app state
			this.store.set('CART_COUNT', this.store.value['CART_COUNT'] + quantityAdded);

			// Setting the React Header Cart Count
			if (
				typeof this.window.cartCountComponent !== 'undefined' &&
				typeof this.window.cartCountComponent.setCartCount === 'function'
			) {
				this.window.cartCountComponent.setCartCount(this.store.value['CART_COUNT']);
			}

			// Build the analytics object
			const atcProductObj = {
				ProductID: atcObject.productEcode,
				ProductName: atcObject.productTitle,
				ProductSKU: atcObject.skuId,
				ProductQty: `${quantityAdded}`,
				FulfillmentMethod: `${fulfillmentMethod}`,
				FulfillmentStore: `${
					atcObject.isBopis || atcObject.isBopl ? fulfillmentStore || '' : ''
				}`,
				CurrentPricePerUnit: `${atcObject.skuOfferPrice}`,
				ListPricePerUnit: `${atcObject.skuListPrice}`,
				CartAdditionSource: atcObject?.cartAdditionSource
					? atcObject.cartAdditionSource
					: 'PDP',
				LowStockMessageShown: atcItemList.lowStockMessageShown,
				AvailableQTY: atcItemList.lowStockInventory,
				IsMainItem: !atcObject.isProductAddOn
			};

			atcProducts.push(atcProductObj);

			// Log the ATC success message
			this.logger.log(
				this.generateSuccessMessage(
					'true',
					atcObject.skuId,
					'none',
					atcObject.quantity,
					atcObject.isBopis,
					this.storeId,
					atcObject.productEcode,
					atcObject.productTitle,
					atcObject.ATCOriginated
				)
			);

			const postATCSuccessEvent: PostATCEvent = {
				atcObject,
				launchConfig: atcItemList.launchConfig,
				serviceResponse: response,
				success: true
			};

			postATCEventList.push(postATCSuccessEvent);
		}

		// Set the Items added to the Cart
		this.analyticsService.setItemsAddedToCart(atcProducts);
		this.analyticsService.triggerAnalyticsEvent('reporting:update');
		this.eventBusService.emit(new EmitEvent(Events.PostATCEvent, postATCEventList));
	}
}
