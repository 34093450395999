export interface IEnvironment {
	api: any;
	apiAuthKeys?: {
		partnerKey: string,
		secretKey: string
	};
	apiTokens: any;
	baseDomain?: any;
	baseUrls?: any;
	captchaSiteKey?: any;
	catOvrGrpId: any;
	chk?: any;
	customizer: any;
	dataFiles: any;
	defaultActiveCloudProvider: string;
	environmentName?: string;
	esbEnvironmentHeader: any;
	feature: any;
	handshakesParams?: any;
	identityServiceKey?: {
		partnerKey: string,
		secretKey: string
	};
	imagePresets?: boolean;
	inventory: any;
	isLocal?: boolean;
	killSwitch?: any;
	launchConfig?: any;
	logging: any;
	loyalty: any;
	myAccount: any;
	offerDate?: {
		endDateString: string,
		startDateString: string
	};
	partyAuthKeys?: {
		partnerKey: string,
		secretKey: string
	};
	previewLinks?: boolean;
	pricing?: any;
	production: any;
	searchbyTermTypeParam: any;
	solePanel?: {
		dsg: string;
		gg: string;
		iframeParent: string;
		mj: string;
		pl: string;
	},
	tests?: any;
	thirdParty: any;
	trackOrders?: any;
	useRedis?: boolean;
}

export class Environment implements IEnvironment {
	public api;
	public apiAuthKeys?: {
		partnerKey: string,
		secretKey: string
	};
	public apiTokens;
	public baseUrls;
	public catOvrGrpId;
	public customizer;
	public dataFiles;
	public defaultActiveCloudProvider;
	public environmentName?;
	public esbEnvironmentHeader;
	public inventory;
	public chk?;
	public logging;
	public myAccount;
	public loyalty;
	public pricing;
	public production;
	public searchbyTermTypeParam;
	public thirdParty;
	public feature;
	public killSwitch: any;
	public imagePresets;
	public useRedis;
	public previewLinks;
	public tests: any;
	public launchConfig?: any;
	public handshakesParams?: any;
	public baseDomain?: any;
	public trackOrders?: any;
	identityServiceKey?: {
		partnerKey: string,
		secretKey: string
	};
	creditServiceKey?: {
		partnerKey: string,
		secretKey: string
	};
	offerDate?: {
		endDateString: string,
		startDateString: string
	};
	public partyAuthKeys?: {
		partnerKey: string;
		secretKey: string;
	};

	public isLocal = false;
	public solePanel?: {
		dsg: string;
		gg: string;
		iframeParent: string;
		mj: string;
		pl: string;
	};
}
