/** @format */

// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../../../types/typings.d.ts" />

import { ModuleWithProviders, NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { LocalStorageConfig } from '@shared/data-access/interfaces';
import { LocalStorageService } from './service/local-storage.service';
import { Store } from './persistence/app.store';
import { WINDOW_PROVIDERS } from './service/window.service';

@NgModule({
	imports: [HttpClientModule]
})
export class DcsgNgxEcommerceCoreModule {
	static forRoot(
		localStorageConfig: LocalStorageConfig | undefined
	): ModuleWithProviders<DcsgNgxEcommerceCoreModule> {
		return {
			ngModule: DcsgNgxEcommerceCoreModule,
			providers: [
				{ provide: LocalStorageConfig, useValue: localStorageConfig || undefined },
				{
					provide: LocalStorageService,
					useFactory: localStorageServiceFactory,
					deps: [LocalStorageConfig]
				}, // eslint-disable-line @typescript-eslint/no-use-before-define
				WINDOW_PROVIDERS,
				Store
			]
		};
	}
}

export function localStorageServiceFactory(localStorageConfig: LocalStorageConfig) {
	return new LocalStorageService(localStorageConfig);
}
