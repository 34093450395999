export interface IChain {
	aemData: {
		subText: string,
		title: string
	},
	apiVersion: number;
	auth0: {
		enabled: boolean;
	};
	authStrategy: string;
	bopisFlag: boolean;
	bopisLocationDefault?: number,
	cartTitle: string;
	cartUrlParameters: string;
	catalogIdCollections: string;
	catalogIdContent: string;
	catalogIdSales: string;
	chainBopisIcon: string;
	chainCartImage: any;
	chainCartUrl: string;
	chainFooterUrls: any;
	chainIdentifier: string;
	chainIdentifierAbbr: string;
	chainIdentifierCookie?: string;
	chainIdentifierCookieAOS?: string;
	chainInitial: string;
	chainMarketingIdentifierAbbr: string;
	chainName: string;
	chatUrl: string;
	checkoutConfirmationUrlParameters?: string;
	checkoutUrlBase: string;
	checkoutUrlParameters: string;
	crossChainBopisPartners?: string[];
	crossChainLogoSVG?: any;
	crossChainLogoSVGV2?: string;
	feedbackSurveyId: string;
	footerData: IFooterData;
	holidayOfferCode: string;
	isaFlag: boolean;
	logoSVG?: string;
	logoSVGV2?: string;
	masterCatalogId: string;
	metaCanonical: string;
	metaCanonicalHost: string;
	metaDescription: string;
	metaTitle: string;
	myAccountUrlLoggedIn: string;
	myAccountUrlLoggedOut: string;
	nestjsProductAPIVersion: string;
	priceStringIndicator: string;
	proTipsFlag: boolean;
	productAPIVersion: string;
	qAndAFlag: boolean;
	rewards: {
		image: string;
		programName: string;
	};
	rewardsAccountImage: string;
	rewardsAccountName: string;
	searchDex: ISearchDex;
	seoIdentifier: string,
	serviceIdentifier: string;
	serviceIdentifierAbbr: string;
	sessionCamIdentifier: string;
	shippingIcon?: string;
	shippingInfoIcon?: string;
	simpleHeaderLogoUrl: string;
	sizeChartSurveyId: string;
	storeId: string;
	supportNumber: string;
	userRegistrationFormUrl: string;
	vhHeader: string;
	xChannel: string;
}

export interface IIncludeType {
	body: string;
	footer: string;
	meta: string;
}

export interface IPageType {
	category?: string;
	product?: string;
}

export interface ISearchDex {
	includeType: IIncludeType;
	pageType: IPageType;
}

export interface IFooterData {
	copyright: string;
	oracleChat: string;
	social: any;
}

export interface ISocial {
	facebook: string;
	instagram: string;
	pinterest: string;
	tiktok: string;
	twitter: string;
	youtube: string;
}

export class Chain implements IChain {
	public aemData: {
		subText: string;
		title: string;
	};
	public authStrategy;
	public bopisLocationDefault;
	public catalogIdCollections;
	public catalogIdContent;
	public catalogIdSales;
	public chainIdentifier;
	public chainIdentifierAbbr;
	public chainMarketingIdentifierAbbr;
	public chainIdentifierCookie: string;
	public chainIdentifierCookieAOS;
	public chainInitial;
	public chainName;
	public chainCartImage;
	public chainCartUrl;
	public chainBopisIcon;
	public chatUrl;
	public holidayOfferCode;
	public masterCatalogId;
	public metaCanonical;
	public metaCanonicalHost;
	public metaDescription;
	public metaTitle;
	public myAccountUrlLoggedIn;
	public myAccountUrlLoggedOut;
	public nestjsProductAPIVersion;
	public priceStringIndicator;
	public serviceIdentifier;
	public serviceIdentifierAbbr;
	public shippingIcon: string;
	public shippingInfoIcon: string;
	public storeId;
	public bopisFlag;
	public isaFlag;
	public qAndAFlag;
	public proTipsFlag;
	public productAPIVersion;
	public rewardsAccountName: string;
	public rewardsAccountImage: string;
	public searchDex;
	public cartUrlParameters;
	public checkoutUrlBase: string;
	public checkoutUrlParameters: string;

	public checkoutConfirmationUrlParameters: string;
	public userRegistrationFormUrl;
	public sessionCamIdentifier;
	public footerData;
	public feedbackSurveyId;
	public sizeChartSurveyId;
	public cartTitle: string;
	public crossChainBopisPartners?: string[];
	public crossChainLogoSVG?: string;
	public logoSVG?: string;
	public vhHeader: string;
	public chainFooterUrls: any;
	public apiVersion: number;
	public auth0: {
		enabled: boolean;
	};
	public seoIdentifier: string;
	public rewards: {
		image: string;
		programName: string;
	};
	public xChannel: string;
	public supportNumber: string;
	public simpleHeaderLogoUrl: string;
}
