/** @format */

import { Component, Input, OnInit } from '@angular/core';

import { Chain } from '@dcsg-ngx-ecommerce/core';
import { HOME_CONSTANTS } from '../../app.constants';

@Component({
	selector: 'home-no-content',
	templateUrl: './no-content.component.html',
	styleUrls: ['./no-content.component.scss']
})
export class NoContentComponent implements OnInit {
	@Input() chain: Chain;
	errorMessageConfig: any;
	ERROR_MESSAGES = HOME_CONSTANTS.noContentErrorMessages;
	ngOnInit() {
		this.errorMessageConfig =
			this.ERROR_MESSAGES[this.chain.chainIdentifierAbbr] || this.ERROR_MESSAGES['dsg']; // default to dickssportinggoods if chainValue is not matched
	}
}
