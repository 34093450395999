/** @format */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Observable } from 'rxjs';
import { map, retry, tap } from 'rxjs/operators';

import { DOCUMENT } from '@angular/common';

import { MetaService } from './meta.service';
import { Chain } from '@dcsg-ngx-ecommerce/core/model/chain.model';
import { Environment } from '@dcsg-ngx-ecommerce/core/model/environment.model';
import { Store } from '@dcsg-ngx-ecommerce/core/persistence/app.store';
import { Page } from '../interfaces/page.interface';
import { SeoDao } from '../dao/seo.dao';


@Injectable({
	providedIn: 'root'
})
export class SeoService {
	readonly DEFAULT_SDX_FILE_NAME = 'sd-default';
	private SEO_URL: string;

	constructor(
		private http: HttpClient,
		private chain: Chain,
		private environment: Environment,
		private seoDao: SeoDao,
		private store: Store,
		@Inject(DOCUMENT) private document: HTMLDocument,
		private metaService: MetaService,
		private title: Title
	) {
		this.SEO_URL = this.environment.api.seo;
	}

	/**
	 * @description
	 * Builds and returns a default page meta description for a given product title and chain abbreviation
	 */
	public getDefaultPageMetaDescription(
		productTitle: string,
		chainIdentifierAbbr: string
	): string {
		// Return meta description
		let metaDescription = '';

		// Switch on the chain abbreviation to return a store specific meta description
		switch (chainIdentifierAbbr) {
			case 'calia':
				// Set the meta description
				metaDescription = `Enjoy free shipping on ${productTitle} from CALIA. Read detailed descriptions and reviews on ${productTitle} and shop similar products at great prices.`;

				// Break
				break;
			case 'gg':
				// Set the meta description
				metaDescription = `Buy the ${productTitle} today and improve your game. Find product reviews and specs or compare products today at Golf Galaxy.`;

				// Break
				break;

			case 'pl':
				// Set the meta description
				metaDescription = `Shop ${productTitle} at Public Lands. Explore key product details to make sure you get the best fit for your needs.`;

				// Break
				break;
			default:
				/*
				 * DEFAULT TO DSG
				 */
				// Set the meta description
				metaDescription = `Shop a wide selection of ${productTitle} at DICK’S Sporting Goods and order online for the finest quality products from the top brands you trust.`;

				// Break
				break;
		}

		// Return the meta description
		return metaDescription;
	}

	/**
	 * @description
	 * Builds and returns a default page title for a given product title and chain name
	 */
	public getDefaultPageTitle(productTitle: string, chainName: string): string {
		// Return the default page title
		return `${productTitle} | ${chainName}`;
	}

	/**
	 * @description
	 * Trims the pipe and chain name off a page title if present
	 */
	public getTrimmedPageTitle(pageTitle: string): string {
		// Return string
		let returnString = '';

		// Check to see if the argument is a string
		if (typeof pageTitle === 'string' && pageTitle.trim() !== '') {
			// Check to see if the title has the pipe
			returnString =
				pageTitle.indexOf('|') > -1
					? pageTitle.substring(0, pageTitle.indexOf('|')).trim()
					: pageTitle;
		}

		// Return the string
		return returnString;
	}

	/**
	 * @description
	 * Gets the Page object by an ID and type.  Types: `category`, `product`, `name`.  The returned Page object will
	 * contain SEO related information.
	 */
	public getPageSeobyUrl$(url?: string, retries?: number): Observable<Page> {
		return this.seoDao.getPageSeobyUrl$(url, retries);
	}

	// ******************** FUNCTION DECLARATIONS ********************//
	/**
	 * @desc Callback function which gets called every time the SearchDex dynamic properties are updated
	 * so that a new Managed Include can be fetched
	 *
	 * @type {Function}
	 */
	public getSearchDexInclude$(inSearchDexDynamicData, retries?) {
		let filePattern;
		// Ensure we have all of our required parameters
		if (inSearchDexDynamicData) {
			filePattern = `sd-${this.chain.searchDex.pageType[inSearchDexDynamicData.pageType]}${
				inSearchDexDynamicData.pageId
			}`;
		} else {
			filePattern = 'sd-default';
		}

		// Get the Managed Include Content
		return this.seoDao.getSearchDexInclude$(filePattern, retries).pipe(
			map((response: any) => {
				if (response) {
					// Set the categoryID
					this.store.set('SEARCHDEX_RESPONSE', response);
					return response;
				}
			})
		);
	}

	getSEOData$(seoToken: string, retries = 0): Observable<any> {
		return this.seoDao.getSEOData$(seoToken, retries);
	}

	getsubCatSEOData$(seoToken: string, retries = 0): Observable<any> {
		const requestUrl = `${this.SEO_URL}seo-category/v1/categories?seoUrl=${seoToken}&storeId=${this.chain.storeId}&children=true&published=true`;
		const httpHeaders = {
			'X-TIMEOUT': '10000',
			'Content-Type': 'application/json'
		};

		const httpOptions = {
			headers: new HttpHeaders(httpHeaders)
		};

		return this.http.get(requestUrl, httpOptions).pipe(
			map((response: any) => {
				return response;
			}),
			retry(retries)
		);
	}

	initialize(seoToken: string, pageType: string, path: string, retries = 0) {
		return this.getSEOData$(seoToken, retries).pipe(
			tap((result) => {
				this.initializePageSEO(result, path, pageType);
			})
		);
	}

	initializePageSEO(data, defaultSeoPath, pageType): void {
		const defaultCanonical = `${this.chain.metaCanonical}${defaultSeoPath}`;
		if (data.categories && data.categories[0]) {
			const seoData = data.categories[0];

			if (seoData.attr?.SEO_CANONICAL?.value) {
				this.addCanonicalTag(seoData.attr.SEO_CANONICAL.value, 'SEO Service');
			} else {
				this.addCanonicalTag(defaultCanonical);
			}

			this.createRobotsTag(seoData.attr.SEO_ROBOTS_NOINDEX, seoData.attr.SEO_ROBOTS_NOFOLLOW, seoData.attr.SEO_ROBOTS_FOLLOW);

			this.setPageMetaInformation(seoData, pageType);
		} else {
			this.addCanonicalTag(defaultCanonical);
			this.metaService.addTag(
				{
					property: 'og:url',
					content: defaultCanonical
				},
				true
			);
		}
	}

	createRobotsTag(noindex: { value?: string }, nofollow: { value?: string }, follow: { value?: string} ) {
		const robotsParams = [];

		if (noindex?.value?.toUpperCase() === 'Y') {
            robotsParams.push('noindex');
        }

        if (nofollow?.value?.toUpperCase() === 'Y') {
            robotsParams.push('nofollow');
        }

		if (follow?.value?.toUpperCase() === 'Y') {
			robotsParams.push('follow');
		}

		if (robotsParams.length > 0) {
			const content = robotsParams.join(',');
			this.metaService.addTag({ name: 'robots', content }, true);
		}
	}

	setPageMetaInformation(data, pageType?) {
		const title = data.title || this.getDefaultPageTitle(data.name, this.chain.chainName);
		const description =
			data.metaDesc ||
			this.getDefaultPageMetaDescription(data.name, this.chain.chainIdentifierAbbr);
		const keywords = data.metaKeywords
			? [{ name: 'keywords', content: data.metaKeywords }]
			: [];

		this.title.setTitle(title);
		this.metaService.addTags(
			[
				{
					name: 'apple-mobile-web-app-title',
					content: title
				},
				{
					name: 'description',
					content: description
				},
				...keywords,
				{
					property: 'og:type',
					content: 'category'
				},
				{
					property: 'og:site_name',
					content: this.chain.chainName
				},
				{
					property: 'og:title',
					content:
						title.indexOf('|') > -1
							? title.substring(0, title.indexOf('|')).trim()
							: title
				},
				{
					property: 'og:description',
					content: description
				}
			],
			true
		);
	}

	setSearchAction(storeURL: string) {
		const scriptTag = document.createElement('script');

		scriptTag.type = 'application/ld+json';
		scriptTag.text = `{
			"@context": "http://schema.org",
			"@type": "WebSite",
			"url": "${storeURL}",
			"potentialAction": {
				"@type": "SearchAction",
				"target":  "${storeURL}/search/SearchDisplay?searchTerm={search_term_string}",
				"query-input": {
					"@type": "PropertyValueSpecification",
					"valueRequired": true,
					"valueMaxlength": 100,
					"valueName": "search_term_string"
				}
			}
		}`;

		document.head.appendChild(scriptTag);
	}

	addCanonicalTag(url, comment = 'Default') {
		if (this.document.head.querySelector('[rel="canonical"]')) {
			const canonicalLink: HTMLLinkElement =
				this.document.head.querySelector('[rel="canonical"]');

			canonicalLink.setAttribute('href', url);
			if (canonicalLink.dataset) {
				canonicalLink.dataset.comment = `${comment} Canonical`;
			}
		} else {
			// Create a link element
			const canonicalLink = this.document.createElement('link');

			// Set the link relationship attribute
			canonicalLink.setAttribute('rel', 'canonical');

			// Set the link href attribute
			canonicalLink.setAttribute('href', url);

			if (canonicalLink.dataset) {
				canonicalLink.dataset.comment = `${comment} Canonical`;
			}

			// Append the link element to the head element
			this.document.head.appendChild(canonicalLink);
		}
	}

	/**
	 * @description
	 * Accepts fullImage and returns updated imagePath
	 */
	getimagePath(category) {
		let imgPath = '';
		if (category.thumbnail) {
			if (category.thumbnail.includes('Attachment/')) {
				imgPath = `${this.chain.metaCanonical}/wcsstore/${this.chain.chainIdentifier}/${category.thumbnail}`;
			} else {
				// eslint-disable-next-line no-useless-escape
				const thumbnailName = category.thumbnail.replace(/[\/ -]/g, '_');
				imgPath = `${this.environment.thirdParty.scene7.url}${thumbnailName}/?${this.environment.thirdParty.scene7.suffix}`;
			}
		} else if (category.attr && category.attr['TOP_RESULT_THUMBNAIL']) {
			// eslint-disable-next-line no-useless-escape
			const ecode = category.attr['TOP_RESULT_THUMBNAIL'].value.replace(/[\/ -]/g, '_');
			imgPath = `${this.environment.thirdParty.scene7.url}${ecode}/?${this.environment.thirdParty.scene7.suffix}`;
		} else {
			imgPath = '';
		}
		return imgPath;
	}

	/**
	 * @description
	 * Gets breadcrumbs for a given category ID
	 */
	getBreadCrumbsData$(identifier: string, retries = 1) {
		const requestUrl = `${this.environment.api.seoByChain}seo-category/v1/categories/breadcrumb?storeId=${this.chain.storeId}&identifier=${identifier}`;
		const httpHeaders = {
			'X-TIMEOUT': '10000',
			'Content-Type': 'application/json'
		};

		const httpOptions = {
			headers: new HttpHeaders(httpHeaders)
		};

		return this.http.get(requestUrl, httpOptions).pipe(
			map((response: any) => {
				if (response && response.breadcrumbList) {
					return response.breadcrumbList;
				}

				return null;
			}),
			retry(retries)
		);
	}
}
