export enum MediaType {
	APPLICATION_JSON = 'application/json',
	APPLICATION_WWW_FORM_URLENCODED = 'application/x-www-form-urlencoded',
	TEXT = 'text',
	TEXT_CSV = 'text/csv',
	TEXT_HTML = 'text/html; charset=utf-8',
	TEXT_PLAIN = 'text/plain',
	TEXT_XML = 'text/xml',
	WILDCARD = '*/*'
}
