/** @format */
import { Component, Input, OnInit } from '@angular/core';
import { Chain } from '@dcsg-ngx-ecommerce/core';

@Component({
	selector: 'home-page-header',
	templateUrl: './page-header.component.html',
	styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
	@Input() pageTitle: any;
	public breadcrumbs: any = [];
	public chainAbbr: string;

	constructor(private chain: Chain) {
		this.chainAbbr = this.chain.chainIdentifierAbbr;
	}

	ngOnInit() {
		this.breadcrumbs = this.pageTitle
			.split(' ')
			.map((char) => {
				return char.substr(0, 1).toUpperCase() + char.substring(1).toLowerCase();
			})
			.join(' ');
	}
}
