const imagesRoot = 'https://images.dickssportinggoods.com';
const reactMarginals_header = 'https://perf-ecom-responsive-header-page.iso.an01.pcf.dcsg.com';
const reactMarginals_footer = 'https://perf-ecom-footer-page.iso.an01.pcf.dcsg.com';
const reactMarginals_calia = 'https://perf-calia-navigation.iso.an01.pcf.dcsg.com';
const spring_microService_breadcrumb = 'https://breadcrumbservice.iso.an01.pcf.dcsg.com/';
const spring_microService_category = 'https://categoryservice.iso.an01.pcf.dcsg.com/';
const spring_microService_recommendations = 'https://staging-ds-recs-details.iso.an01.pcf.dcsg.com';

const ceops_paths = {
	local: 'http://localhost:6001',
	perf: 'https://ceops.dickssportinggoods.com/perf'
};

export const devEnvironmentDefaults: any = {
	api: {
		homr: {
			proxy: {
				path: `/home/homr/v2/api/`,
				timeout: 15000
			},
			url: 'https://www.dickssportinggoods.com/dcsg-ngx-home/homr/v2/api/',
			scene7Url: 'https://dks.scene7.com/is/image/dksfed/',
			scene7UrlCDN: 'https://dks.scene7.com/is/image/dkscdn/'
		},
		aemFoundationDetails: { // to be updated as part of https://dcsgcloud.atlassian.net/browse/SPPJ-87
			proxy: {
				path: `/graphql/execute.json/ContentFragments/getFoundationDetailsList`,
				timeout: '15000'
			},
			url: 'https://publish-p42408-e166538.adobeaemcloud.com',
		},
		reactMarginals: {
			header: {
				AZ: reactMarginals_header,
				AZ2: reactMarginals_header,
				AZ3: reactMarginals_header,
				VS: reactMarginals_header
			},
			footer: {
				AZ: reactMarginals_footer,
				AZ2: reactMarginals_footer,
				AZ3: reactMarginals_footer,
				VS: reactMarginals_footer
			},
			calia: {
				AZ: reactMarginals_calia,
				AZ2: reactMarginals_calia,
				AZ3: reactMarginals_calia,
				VS: reactMarginals_calia
			},
			caliaHeaderUrl: 'https://static-search.dickssportinggoods.com/calia-navigation/perf',
			caliaFooterUrl: 'https://static-search.dickssportinggoods.com/calia-navigation/perf',
			headerUrl: 'https://static-search.dickssportinggoods.com/perf/responsive-header',
			footerUrl: 'https://static-search.dickssportinggoods.com/perf/footer',
			proxy: {
				path: `/marginals/`,
				timeout: 15000
			}
		},
		spring: {
			microService: {
				breadcrumb: {
					GCP: spring_microService_breadcrumb,
					AZ: spring_microService_breadcrumb,
					AZ2: spring_microService_breadcrumb,
					AZ3: spring_microService_breadcrumb,
					VS: spring_microService_breadcrumb
				},
				category: {
					GCP: spring_microService_category,
					AZ: spring_microService_category,
					AZ2: spring_microService_category,
					AZ3: spring_microService_category,
					VS: spring_microService_category
				},
				recommendations: {
					GCP: spring_microService_recommendations,
					AZ: spring_microService_recommendations,
					AZ2: spring_microService_recommendations,
					AZ3: spring_microService_recommendations,
					VS: spring_microService_recommendations
				},
				seoservice: {
					GCP: 'https://active-seoservice.iso.gp01.pcf.dcsg.com/',
					AZ: 'https://active-seoservice.iso.ap01.pcf.dcsg.com/',
					AZ2: 'https://active-seoservice.iso.ap02.pcf.dcsg.com/',
					AZ3: 'https://active-seoservice.iso.ap02.pcf.dcsg.com/',
					VS: 'https://active-seoservice.iso.vp01.pcf.dcsg.com/'
				},
				taxoservice: 'https://browse.dickssportinggoods.com/dcsg-ngx-category/taxo/api/'
			},
			microServiceProxy: {
				recommendations: {
					path: '/api/spring/msvc/ds/v1/',
					timeout: '15000'
				}
			}
		},
		dataScience: {
			basePath: '/api/spring/msvc/ds/v1/',
			target: spring_microService_recommendations
		},
		esb: {
			gatewayUrl: 'https://gateway.dksxchange.com/',
			gatewayUrlProxy: {
				browser: '/dcsg-ngx-home/esb/api/',
				server: 'http://localhost/dcsg-ngx-home/esb/api/'
			}
		},
		ceops: ceops_paths.perf,
		seo: 'https://api-search.dickssportinggoods.com/',
		promoteIQ: 'https://ad-dsg-sandbox.tagdelivery.com/request?',
		brandify: {
			brandifyBaseUrl: 'https://hosted.where2getit.com/',
			proxy: {
				path: `/home/store/locator/`
			}
		}
	},
	images: {
		netstorage: {
			marketing: `${imagesRoot}/marketing/`,
			product: `${imagesRoot}/product/`
		},
		scene7proxy: {
			marketing: `https://dks.scene7.com/is/image/dksfed/`,
			marketingContent: `https://dks.scene7.com/is/content/dksfed/`,
			product: `https://dks.scene7.com/is/image/dkscdn/`
		},
		imageCollections: {
			url: 'https://dev-iapi.iso.an01.pcf.dcsg.com/image-collections/'
		}
	},
	thirdParty: {
		auth0: {
			dsg: {
				enabled: true
			},
			gg: {
				enabled: true
			},
			pl: {
				enabled: true
			},
			calia: {
				enabled: false
			},
			vrst: {
				enabled: false
			},
			g3: {
				enabled: false
			},
			sm: {
				enabled: false
			},
			plf: {
				enabled: false
			}
		}
	},
	baseDomain: {
		dsg: 'https://meta.dickssportinggoods.com',
		gg: 'https://meta.golfgalaxy.com',
		vrst: 'https://beta.vrst.com',
		calia: 'https://meta.dickssportinggoods.com',
		pl: 'https://meta.dickssportinggoods.com',
		mj: 'https://meta.dickssportinggoods.com',
		g3: 'https://meta.goinggoing.com',
		sm: 'https://beta.sportsmatter.org',
		plf: 'https://publiclandsfund.org'
	},
	identityServiceKey: {
		partnerKey: 'MyAccount',
		secretKey: '8uBj3x2Zi3usl4PTiTyi'
	},
	feature: {
		homRAudiences: {
			dsg: true,
			gg: true,
			calia: true,
			vrst: true,
			pl: true,
			mj: true,
			g3: true,
			sm: false,
			plf: false
		}
	},
	solePanel: {
		iframeParent: '*',
		dsg: 'https://beta-solepanel-dks.dickssportinggoods.com',
		gg:  'https://gg-qa1.dksxchange.com',
		pl:  'https://beta-solepanel-pl.dickssportinggoods.com',
		mj:  'https://solepanel-mj.dksxchange.com',
	}
};
