/** @format */

import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Chain } from '@dcsg-ngx-ecommerce/core/model/chain.model';
import { filter, map, switchMap } from 'rxjs/operators';
import { CeopsService } from '../../service/ceops.service';

@Component({
	selector: 'engage-ceops',
	templateUrl: './ceops.component.html',
	styleUrls: ['./ceops.component.scss']
})
export class CeopsComponent implements OnInit {
	constructor(
		private ceopsService: CeopsService,
		public chain: Chain,
		private router: Router,
		@Inject(DOCUMENT) private document: Document
	) {}

	ngOnInit(): void {
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map((route: any) => route.url.split('?')[0]),
				switchMap((url) =>
					this.ceopsService.getScripts$(
						url,
						this.chain.chainMarketingIdentifierAbbr.toUpperCase(),
						3
					)
				)
			)
			.subscribe((response) => {
				this.handleCEOPSContent(response);
			});
	}

	public handleCEOPSContent(response) {
		if (response.scripts.length > 0) {
			const fragment = this.document.createDocumentFragment();

			response.scripts.forEach((script) => {
				if (script.path) {
					const id = `ceopsScript_${script.path.replace(/\//g, '__')}`;

					if (!this.document.getElementById(id)) {
						const newEl = this.document.createElement('script');
						newEl.src = script.src;
						newEl.type = 'application/javascript';
						newEl.id = id;

						if (!script.loadSynchronously) {
							newEl.async = true;
						}

						fragment.appendChild(newEl);
					}
				}
			});
			this.document.head.appendChild(fragment);
		}
	}
}
