import { IChain } from '@dcsg-ngx-ecommerce/core/model/chain.model';

export const chain: IChain = {
	aemData: {
		title: 'Read More',
		subText: 'Latest From Field Guide'
	},
	authStrategy: 'cookie',
	bopisFlag: true,
	bopisLocationDefault: -1,
	cartUrlParameters: '?calculationUsageId=-1&calculationUsageId=-2&calculationUsageId=-3&calculationUsageId=-7&updatePrices=1&doConfigurationValidation=Y&catalogId=12301&orderId=.&langId=-1&storeId=15108',
	checkoutUrlBase: 'SinglePageCheckout',
	checkoutUrlParameters: '?calculationUsageId=-1&calculationUsageId=-2&calculationUsageId=-3&calculationUsageId=-7&updatePrices=1&doConfigurationValidation=Y&catalogId=12301&orderId=.&langId=-1&storeId=15108',
	checkoutConfirmationUrlParameters: 'catalogId=12301&langId=-1&storeId=15108',
	catalogIdCollections: '12301',
	catalogIdContent: '12301',
	catalogIdSales: '12301',
	chainIdentifier: 'DicksSportingGoods',
	chainIdentifierAbbr: 'sm',
	chainMarketingIdentifierAbbr: 'sm',
	chainIdentifierCookie: undefined,
	chainIdentifierCookieAOS: undefined,
	chainInitial: 'D',
	chainName: 'Sports Matter',
	chainCartImage: false,
	chainCartUrl: '/OrderItemDisplay',
	chainBopisIcon: 'DSGBopis.svg',
	chatUrl: 'https://dsgcep.custhelp.com/app/chat/chat_launch',
	holidayOfferCode: 'EM1219DBP3X',
	isaFlag: true,
	masterCatalogId: '10001',
	metaCanonical: 'https://www.sportsmatter.com',
	metaCanonicalHost: 'sportsmatter',
	metaDescription: 'Help save youth sports with Sports Matter by DICK\'S Sporting Goods. Learn how to apply for or receive youth sports sponsorships for local teams.',
	metaTitle: 'Youth Sports Sponsorships | Sports Matter',
	myAccountUrlLoggedIn: '/MyAccount/AccountSummary',
	myAccountUrlLoggedOut: '/LogonForm',
	nestjsProductAPIVersion: 'v6',
	priceStringIndicator: 'dickssportinggoods',
	productAPIVersion: 'v5',
	proTipsFlag: true,
	qAndAFlag: false,
	rewardsAccountName: 'ScoreCard',
	rewardsAccountImage: 'scoreCard.svg',
	searchDex: {
		includeType: {
			meta: 'm',
			body: 'b',
			footer: 'f'
		},
		pageType: {
			category: 'c',
			product: 'p'
		}
	},
	serviceIdentifier: 'dickssportinggoods',
	serviceIdentifierAbbr: 'dsg',
	sessionCamIdentifier: 'RES-PDP-DSG',
	shippingIcon: 'dsgTruck.svg',
	shippingInfoIcon: 'info',
	storeId: '15108',
	userRegistrationFormUrl: '/UserRegistrationForm?new=Y&catalogId=12301&myAcctMain=1&langId=-1&storeId=15108',
	footerData: {
		social: {
			youtube: 'http://www.youtube.com/user/DicksSportingGoods',
			twitter: 'http://twitter.com/dicks',
			facebook: 'http://www.facebook.com/dickssportinggoods',
			pinterest: 'http://pinterest.com/dkssports',
			instagram: 'https://www.instagram.com/dickssportinggoods/'
		},
		oracleChat: 'https://dsgcep.custhelp.com/app/chat/chat_launch',
		copyright: `&copy; ${new Date().getFullYear()} DICK'S Sporting Goods`
	},
	feedbackSurveyId : '5444',
	sizeChartSurveyId: '5451',
	crossChainBopisPartners: ['gg'],
	crossChainLogoSVG: 'GG_pickup_logo.svg',
	logoSVG: 'store_pickup.svg',
	cartTitle: 'Cart',
	vhHeader: '500',
	auth0: {
		enabled: true
	},
	chainFooterUrls: {
		returns: {
			text: 'Return Policy',
			link: '/s/return-policy'
		},
		shippingRates: {
			text: 'Shipping Rates',
			link: '/s/shipping-information'
		},
		productAvailability: {
			text: 'Product Availability & Price',
			link: '/s/product-availability-price'
		},
		promoExclusions: {
			text: 'Promo Exclusions',
			link: '/s/promo-exclusions'
		},
		contactCustomerService: {
			text: 'Contact Customer Service',
			link: '/s/help-desk'
		},
		privacyPolicy: {
			text: 'Privacy Policy',
			link: '/s/privacy-policy'
		},
		termsOfUse: {
			text: 'Terms of Use',
			link: '/s/terms-of-use'
		},
		californiaDisclosures: {
			text: 'California Disclosures',
			link: '/s/california-disclosures'
		},
		doNotSellMyInfoCAResidentsOnly: {
			text: 'Your Privacy Choices',
			link: '/s/donotsell'
		},
		accessibilityPolicy: {
			text: 'Accessibility Policy',
			link: '/s/web-content-accessibility-policy'
		}
	},
	apiVersion: 2,
	seoIdentifier: 'dickssportinggoods',
	rewards: {
		image: 'scoreCard.svg',
		programName: 'ScoreCard'
	},
	xChannel: '',
	supportNumber: '',
	simpleHeaderLogoUrl: ''
};
