/** @format */
import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Logger } from '@dcsg-ngx-ecommerce/log/service/logger.service';
import { DCSG_CONSTANTS } from '../properties/dcsg-constants';
import { AnalyticsService } from './analytics.service';
import { HttpService } from './http.service';
import { WINDOW } from './window.service';
import { DCSG_APP_NAME } from '@logger';

@Injectable({
	providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
	public readonly ANALYTICS_ERROR_MESSAGE_PREFIX = 'DCSG_NGX_GLOBAL_ERROR:';

	constructor(
		private analyticsService: AnalyticsService,
		@Inject(DCSG_APP_NAME) protected dcsgAppName: string,
		private httpService: HttpService,
		private log: Logger,
		@Inject(PLATFORM_ID) protected platformId: any,
		@Inject(WINDOW) private window: Window
	) {}

	handleError(error: Error | HttpErrorResponse) {
		// Error message
		let errorMessage: string;

		// Error details
		let errorDetails: object = {};

		const isBrowser = isPlatformBrowser(this.platformId);

		if (error instanceof HttpErrorResponse) {
			// Server or connection error happened
			if (isBrowser && !this.window.navigator.onLine) {
				/*
				 * Handle offline error
				 */
				// Set the error message
				errorMessage = `${DCSG_CONSTANTS.errorHandling.messagePrefixes.global.offline}${error.message}`;
			} else {
				/*
				 * Handle Http Error (error.status === 403, 404...)
				 */
				// Set the error message
				errorMessage = `${DCSG_CONSTANTS.errorHandling.messagePrefixes.global.http}${error.message}`;
			}

			try {
				// Set the error details
				errorDetails = {
					'X-ERROR-STATUS': `${error.status}`,
					'X-ERROR-ERROR':
						typeof error.error === 'object'
							? JSON.stringify(error.error)
							: `${error.error}`,
					'X-ERROR-HEADERS':
						typeof error.headers === 'object'
							? JSON.stringify(error.headers)
							: `${error.headers}`,
					'X-ERROR-MESSAGE': `${error.message}`,
					'X-ERROR-TYPE': `${error.type}`,
					'X-ERROR-NAME': `${error.name}`
				};
			} catch (error) {
				// Swallow the JSON stringify error
			}
		} else {
			/*
			 * Handle Client Error (Angular Error, ReferenceError...)
			 */
			// Set the generic error message
			errorMessage = `${DCSG_CONSTANTS.errorHandling.messagePrefixes.global.client}${error.message}`;

			// Set the error details
			errorDetails = {
				'X-ERROR-MESSAGE': `${error.message}`,
				'X-ERROR-NAME': `${error.name}`,
				'X-ERROR-STACK': `${error.stack}`
			};
		}

		// Set the URL
		errorDetails[
			'X-HREF'
		] = `${this.httpService.getUrlProtocol()}://${this.httpService.getHostName()}${this.httpService.getUrlPath()}`;

		// Log the error
		this.log.error(`${errorMessage}`, errorDetails);

		// Check to see if context is browser
		if (isBrowser) {
			// Set and trigger analytics
			this.analyticsService.setErrorId(`${this.dcsgAppName}_GLOBAL_ERROR`);
			this.analyticsService.setErrorMessage(this.buildAnalyticsErrorMessage(errorDetails));
			this.analyticsService.triggerAnalyticsEvent('reporting:update');
		}
	}

	private buildAnalyticsErrorMessage(errorDetails: object): string {
		return `${this.ANALYTICS_ERROR_MESSAGE_PREFIX}${JSON.stringify(errorDetails)}`;
	}
}
