/** @format */
import { Injectable, Optional } from '@angular/core';
import { LocalStorageConfig } from '@shared/data-access/interfaces';

export const DEFAULT_LOCAL_STORAGE_CONFIG: LocalStorageConfig = {
	allowNull: true,
	prefix: 'DCSG_NGX_'
};

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {
	public readonly ERROR_MESSAGE_PREFIX = 'LOCAL_STORAGE_ERROR:';

	private _prefix: string;
	private _allowNull: boolean;

	constructor(@Optional() config?: LocalStorageConfig) {
		if (config) {
			this._prefix =
				typeof config.prefix !== 'undefined' && config.prefix !== null
					? config.prefix
					: DEFAULT_LOCAL_STORAGE_CONFIG.prefix;
			this._allowNull =
				typeof config.allowNull !== 'undefined' && config.allowNull !== null
					? config.allowNull
					: DEFAULT_LOCAL_STORAGE_CONFIG.allowNull;
		} else {
			this._prefix = DEFAULT_LOCAL_STORAGE_CONFIG.prefix;
			this._allowNull = DEFAULT_LOCAL_STORAGE_CONFIG.allowNull;
		}
	}

	/**
	 * @description
	 * Gets the entry specified by the given key or null
	 *
	 * @param key - Key identifying the wanted entry
	 * @param prefix - Optional prefix to overwrite the configured one
	 * @returns The value for a given key/prefix
	 */
	public get(key: string, prefix?: string): string | null | undefined {
		prefix = typeof prefix !== 'undefined' && prefix !== null ? prefix : this._prefix;

		try {
			let returnValue = localStorage.getItem(`${prefix}${key}`);

			// Check for string "null"
			if (returnValue === 'null') {
				returnValue = null;
			}

			// Check for string "undefined"
			if (returnValue === 'undefined') {
				returnValue = undefined;
			}

			return returnValue;
		} catch (error) {
			console.error(`${this.ERROR_MESSAGE_PREFIX}${error.message}`);
		}
	}

	/**
	 * @description
	 * Removes the entry specified by the given key
	 *
	 * @param key - Key identifying the entry to remove
	 * @param prefix - Optional prefix to overwrite the configured one
	 */
	public remove(key: string, prefix?: string): void {
		prefix = typeof prefix !== 'undefined' && prefix !== null ? prefix : this._prefix;

		try {
			localStorage.removeItem(`${prefix}${key}`);
		} catch (error) {
			console.error(`${this.ERROR_MESSAGE_PREFIX}${error.message}`);
		}
	}

	/**
	 * @description
	 * Clears all entries of the applications local storage
	 */
	public removeAll(): void {
		try {
			localStorage.clear();
		} catch (error) {
			console.error(`${this.ERROR_MESSAGE_PREFIX}${error.message}`);
		}
	}

	/**
	 * @description
	 * Adds tha value with the given key or updates an existing entry
	 *
	 * @param key - Key to store
	 * @param value - Value to store
	 * @param prefix - Optional prefix to overwrite the configured one
	 */
	public set(key: string, value: string, prefix?: string): void {
		prefix = typeof prefix !== 'undefined' && prefix !== null ? prefix : this._prefix;

		if (
			this._allowNull ||
			(!this._allowNull && value !== 'null' && value !== null && value !== undefined)
		) {
			try {
				localStorage.setItem(`${prefix}${key}`, value);
			} catch (error) {
				console.error(`${this.ERROR_MESSAGE_PREFIX}${error.message}`);
			}
		} else {
			this.remove(key, prefix);
		}
	}
}
