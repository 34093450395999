import { CookieService } from '@dcsg-ngx-ecommerce/core/service/cookie.service';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { WINDOW } from '@dcsg-ngx-ecommerce/core/service/window.service';
import { throwError } from 'rxjs';

import { LoggerConfig, LoggerLevel } from '@logger';


const LOGGER_CONFIG_DEFAULTS: LoggerConfig = {
	clientLoggerLevel: LoggerLevel.OFF,
	xhrLoggerLevel: LoggerLevel.OFF,
	xhrLoggerUrl: undefined
};

declare const BUILD_VERSION: string;

@Injectable({
	providedIn: 'root'
})
export class Logger {
	private _options: LoggerConfig;
	private _isIE: boolean;
	private _levels = ['TRACE', 'DEBUG', 'INFO', 'LOG', 'WARN', 'ERROR', 'OFF'];

	/**
	 * @description
	 * Accepts a logger level and returns the associated color for it
	 */
	public static getColor(level: LoggerLevel): string {
		switch (level) {
			case LoggerLevel.TRACE:
				return 'blue';
			case LoggerLevel.DEBUG:
				return 'teal';
			case LoggerLevel.INFO:
			case LoggerLevel.LOG:
				return 'gray';
			case LoggerLevel.WARN:
				return 'orange';
			case LoggerLevel.ERROR:
				return 'red';
			case LoggerLevel.OFF:
			default:
				return;
		}
	}

	/**
	 * @description
	 * Creates a new JavaScript Date and returns it as an ISO string
	 */
	public static getTimestamp(): string {
		return new Date().toISOString();
	}

	constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId, @Inject(WINDOW) window: Window, private options: LoggerConfig = LOGGER_CONFIG_DEFAULTS, private cookieService: CookieService) {
		// Check to see if any options were supplied
		this._options = this.options;

		// Set other private properties
		this._isIE = isPlatformBrowser(this.platformId) && !!(navigator.userAgent.indexOf('MSIE') !== -1 || navigator.userAgent.match(/Trident\//) || navigator.userAgent.match(/Edge\//));

		/// excluding this from testing as it is not a load-bearing bit of code and the effort required to test it is far more than it is worth.
		/* istanbul ignore next */
		if (isPlatformBrowser(this.platformId) && typeof BUILD_VERSION === 'string' && BUILD_VERSION.trim() !== '' && typeof window !== 'undefined') {
			//TODO: Find a more viable fix for this. Right now this is just to get rid of the BUILD_VERSION does not exist on type 'Window' error during testing

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.BUILD_VERSION = BUILD_VERSION;
		}
	}

	/**
	 * @description
	 * Logs a TRACE level message
	 */
	public trace(message: string | any, ...additional: any[]) {
		this._log(LoggerLevel.TRACE, true, message, additional);
	}

	/**
	 * @description
	 * Logs a DEBUG level message
	 */
	public debug(message: string | any, ...additional: any[]) {
		this._log(LoggerLevel.DEBUG, true, message, additional);
	}

	/**
	 * @description
	 * Logs a INFO level message
	 */
	public info(message: string | any, ...additional: any[]) {
		this._log(LoggerLevel.INFO, true, message, additional);
	}

	/**
	 * @description
	 * Logs a LOG level message
	 */
	public log(message: string | any, ...additional: any[]) {
		this._log(LoggerLevel.LOG, true, message, additional);
	}

	/**
	 * @description
	 * Logs a WARN level message
	 */
	public warn(message: string | any, ...additional: any[]) {
		this._log(LoggerLevel.WARN, true, message, additional);
	}

	/**
	 * @description
	 * Logs a ERROR level message
	 */
	public error(message: string | any, ...additional: any[]) {
		this._log(LoggerLevel.ERROR, true, message, additional);
	}

	dynamicallyLog(requestKey: string, productId?: string, error?: HttpErrorResponse | any[], url?: string): void {
		const getURL = url ? `with URL ${productId} `: '';
		const getProductID = productId !== '' ? `for product ${productId} `: '';
		const errors = error? Object.keys(error).map((key) => [key, error[key]]):[];

		if (error) {
			this._log(LoggerLevel.ERROR, true, `[${requestKey}] API endpoint call ${getProductID} failed ${getURL}.`, errors);
		} else  {
			this._log(LoggerLevel.LOG, true, `[${requestKey}] API endpoint call successfully returned ${getProductID}.`);
		}
	}

	/**
	 * @description
	 * Logs a message to server
	 */
	private _logToServer(level: LoggerLevel, message: string | any, additional: any[]) {
		// Check to see if the message should be sent server-side
		if (level < this._options.xhrLoggerLevel || typeof this._options.xhrLoggerUrl === 'undefined') {
			return;
		}

		// Define HTTP Headers
		let httpHeaders = new HttpHeaders({
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'X-LOG-REQUEST': 'true',
			'X-PROXY-REQUEST': 'true'
		});

		// Check platform
		if (!isPlatformBrowser(this.platformId)) {
			// Set a timeout on the server to prevent the response from taking too long
			httpHeaders = httpHeaders.set('X-TIMEOUT', '500');
		}

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Data to post
		let data: any = {
			level: this._levels[level],
			message,
			applicationId: 'dcsg-ngx-ecommerce',
			date: Logger.getTimestamp(),
			swimLane: this.cookieService.getSwimlaneValue().toString(),
			quantumMetricSessionID: this.cookieService.get('QuantumMetricSessionID'),
			optionalParameters: additional
		};

		if (typeof message === 'object' && message.logType && message.logType === 'ATC') {
			data = message;
		}

		// Check to see if this has been defined
		if (typeof BUILD_VERSION === 'string' && BUILD_VERSION.trim() !== '') {
			// Set the build version on the global window
			data.BUILD_VERSION = BUILD_VERSION;
		}

		// Wrap in try / catch to prevent app from fatally failing for a log message
		try {
			// Make the XHR request
			this.http.post(this._options.xhrLoggerUrl, data, httpOptions).subscribe(
				() => {
					return null;
				},
				() => {
					return throwError(`Logger._logToServer -- XHR Log Request failed`);
				}
			);
		} catch (exception) {
			this._log(LoggerLevel.ERROR, false, `Logger._logToServer -- Exception occurred during XHR Log Request: ${JSON.parse(exception)}`);
		}
	}

	/**
	 * @description
	 * Logs message to console for IE only
	 */
	private _logToConsoleIE(level: LoggerLevel, message: string, additional: any[]) {
		switch (level) {
			case LoggerLevel.WARN:
				console.warn(JSON.stringify({ startTime: `${Logger.getTimestamp()}`, message: [message, ...additional], level: this._levels[level] }));
				break;
			case LoggerLevel.ERROR:
				console.error(JSON.stringify({ startTime: `${Logger.getTimestamp()}`, message: [message, ...additional], level: this._levels[level] }));
				break;
			case LoggerLevel.INFO:
				// eslint-disable-next-line no-console
				console.info(JSON.stringify({ startTime: `${Logger.getTimestamp()}`, message: [message, ...additional], level: this._levels[level] }));
				break;
			default:
				console.log(JSON.stringify({ startTime: `${Logger.getTimestamp()}`, message: [message, ...additional], level: this._levels[level] }));
		}
	}

	/**
	 * @description
	 * Logs message to console
	 */
	private _logToConsole(level: LoggerLevel, message: string, additional: any[]) {
		// Output the message / optional params to the console if not IE
		console.log(JSON.stringify({ startTime: `${Logger.getTimestamp()}`, message: [message, ...additional], level: this._levels[level] }));
	}

	/**
	 * @description
	 * Main log function that delegates to additional private methods as necessary
	 */
	private _log(level: LoggerLevel, logToServer: boolean, message: string | any, additional: any[] = []): undefined {
		// Check to see if a message has been supplied
		if (!message) {
			return;
		}

		// Allow logging on server even if client log level is off
		if (logToServer) {
			this._logToServer(level, message, additional);
		}

		// Check the log level of the client logger
		if (level < this._options.clientLoggerLevel) {
			return;
		}

		// Check the type of message to see whether it needs stringified
		if (typeof message === 'object') {
			message = JSON.stringify(message, null, 2);
		}

		// Coloring doesn't work in IE
		if (this._isIE) {
			this._logToConsoleIE(level, `${message}`, additional);
			return;
		}

		this._logToConsole(level, `${message}`, additional);
	}
}
