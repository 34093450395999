/** @format */

import { CommonModule } from '@angular/common';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { HomeComponent } from './home-component/home.component';

import { DynamicTemplateLoader, WINDOW_PROVIDERS } from '@dcsg-ngx-ecommerce/core';

@NgModule({
	imports: [CommonModule],
	declarations: [HomeComponent],
	providers: [
		// TODO: HomrDataServiceMock
		DynamicTemplateLoader,
		WINDOW_PROVIDERS
	],
	exports: [HomeComponent],
	schemas: [NO_ERRORS_SCHEMA]
})
export class HomeModule {}
