/** @format */

// eslint-disable-next-line no-shadow
export enum LoggerLevel {
	TRACE = 0,
	DEBUG,
	INFO,
	LOG,
	WARN,
	ERROR,
	OFF
}
