/** @format */

import { AppBrowserModule } from './app/app.module';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

if (environment.production) {
	enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
	platformBrowserDynamic()
		.bootstrapModule(AppBrowserModule)
		.catch((error) => {
			return console.error(
				`APPLICATION_ERROR:BOOTSTRAP_ERROR: ${JSON.stringify(error, [
					'message',
					'arguments',
					'type',
					'name'
				])}`
			);
		});
});
