import { Injectable } from '@angular/core';
import { isVHHeaderAnAOSHeader } from '@dcsg-ngx-ecommerce/common/server/aos-check';
import { CookieService } from './cookie.service';
import { HttpService } from './http.service';
import { Chain } from '../model/chain.model';

@Injectable({
	providedIn: 'root'
})
export class VirtualHostCookieService {

	constructor(private chain: Chain, private httpService: HttpService, private cookieService: CookieService) {}

	public setVirtualHostCookie() {
		if (typeof this.chain.chainIdentifierCookie !== 'undefined' && !this.cookieService.check('DSG_VH')) {
			const isAOS = isVHHeaderAnAOSHeader(this.httpService.getRequestHeaderValueAndSetStateKey('DSG_VH'));
			const chainValue = isAOS ? this.chain.chainIdentifierCookieAOS : this.chain.chainIdentifierCookie;
			this.cookieService.set('DSG_VH', chainValue, '', '/', '', '');
		}
	}
}
