/** @format */

import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, makeStateKey, TransferState } from '@angular/core';
import { Chain } from '@dcsg-ngx-ecommerce/core/model/chain.model';

import { Environment } from '@dcsg-ngx-ecommerce/core/model/environment.model';
import { DCSG_CONSTANTS } from '@dcsg-ngx-ecommerce/core/properties/dcsg-constants';
import { AnalyticsService } from '@dcsg-ngx-ecommerce/core/service/analytics.service';
import { HttpService } from '@dcsg-ngx-ecommerce/core/service/http.service';
import { WINDOW } from '@dcsg-ngx-ecommerce/core/service/window.service';
import { CustomHttpHeaders } from '@shared/data-access/enums';
import { HomrPage } from '@shared/homr/data-access';
import { Observable } from 'rxjs';
import { map, retry, catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class HomrDataService {
	public homRFilterKey = makeStateKey('homR_SEOToken');

	// List of items that the stateKey should not be set for, to force loading in the browser
	private exceptionList = DCSG_CONSTANTS.homr.stateKeyExceptions;
	private aosList = DCSG_CONSTANTS.homr.aosPageTypes;
	private isSinglePage = (variableToCheck: any): variableToCheck is HomrPage =>
		(variableToCheck as HomrPage).audience !== undefined;

	constructor(
		@Inject(DOCUMENT) private document: any,
		@Inject(PLATFORM_ID) protected platformId: any,
		@Inject(WINDOW) protected window: Window,
		private http: HttpClient,
		private state: TransferState,
		private httpService: HttpService,
		private analyticsService: AnalyticsService,
		public environment: Environment,
		public chain: Chain
	) {}

	/**
	 * @description
	 * The main call to retrieve page data from homR
	 *
	 * @param pageType
	 * @param retries
	 * @param pageName
	 * @param normalized
	 * @param noSelection
	 * @returns - {homR page object}
	 */
	public getData$(
		pageType: string,
		retries = 2,
		pageName?: string,
		normalized = false,
		noSelection = false
	) {
		pageName =
			this.document.body.classList.contains('aos') && this.aosList.includes(pageType)
				? 'aos'
				: pageName
				? pageName
				: '';
		return this.getTemplateData$(pageType, retries, normalized, noSelection, pageName);
	}

	public getAemData$(url: string) {
		return this.http.get(
			`${window.location.href.includes('localhost') ? this.chain.metaCanonical : ''}${
				this.environment.api.aem.proxy.path
			}${url}`,
			{ responseType: 'text' }
		);
	}

	public getAemDataObject$() {
		return this.http.get(
			`${window.location.href.includes('localhost') ? this.chain.metaCanonical : ''}${
				this.environment.api.aemFoundationDetails.proxy.path
			}`
		);
	}

	/**
	 * @description
	 * The fallback call to get the default content if nothing was returned in the element call
	 *
	 * @param pageType
	 * @returns - {homR page object}
	 */
	public getDefault$(pageType: string, retries = 0) {
		const pageName = this.document.body.classList.contains('aos') ? 'aos' : 'default';
		return this.getTemplateData$(pageType, retries, false, false, pageName);
	}

	/**
	 * @description
	 * DO NOT CALL THIS ONE DIRECTLY, CALL getData$
	 * This is the actual API calls out to dAPI
	 *
	 * @param pageType
	 * @param retries
	 * @param normalized
	 * @param noSelection
	 * @param pageName
	 * @returns - {homR page object}
	 */
	private getTemplateData$(
		pageType: string,
		retries: number,
		normalized: boolean,
		noSelection: boolean,
		pageName?: string
	): any {
		const ELEMENT_KEY = makeStateKey(this.createStateKey(pageType));
		const element: any = this.state.get(ELEMENT_KEY, null);

		// Check to see if state exists
		if (element) {
			// Return the existing state
			return new Observable((observer) => {
				observer.next(element);
				observer.complete();
			});
		}

		// Set http parameters
		let httpParams = new HttpParams()
			.set('pageType', pageType)
			.set('name', pageName ? pageName : this.getPageName())
			.set(
				'chain',
				pageType !== 'size-chart' ? this.chain.chainMarketingIdentifierAbbr : 'dsg'
			);

		// If platform browser
		if (!isPlatformBrowser(this.platformId)) {
			httpParams = httpParams.append('seo', 'true');
		}

		// If normalized
		if (normalized) {
			httpParams = httpParams.append('normalize', `${normalized}`);
		}

		// If noSelection
		if (noSelection) {
			httpParams = httpParams.append('noSelection', `${noSelection}`);
		}

		let httpHeaders = new HttpHeaders(this.getHeaders());

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true');

		// Check to see if there is a timeout specified
		if (!isPlatformBrowser(this.platformId)) {
			// Set the timeout header
			httpHeaders = httpHeaders.set(
				CustomHttpHeaders.X_TIMEOUT,
				`${this.environment.api.homr.proxy.timeout}`
			);
		}

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders,
			params: httpParams
		};

		return this.http.get(`${this.environment.api.homr.proxy.path}v4/content`, httpOptions).pipe(
			map((response: HomrPage | HomrPage[]) => {
				if (noSelection && Array.isArray(response) && !response.length) {
					response = null;
				} else if (
					response &&
					!Array.isArray(response) &&
					typeof response.content === 'undefined'
				) {
					response = null;
				}

				if (!this.exceptionList.includes(pageType)) {
					this.state.set(ELEMENT_KEY, response as any);
				}

				if (this.environment.feature.homRAudiences[this.chain.chainIdentifierAbbr]) {
					this.analyticsService.setHomrAudiences(this.buildAudienceObject(response));
				}

				return response;
			}),
			retry(retries),
			catchError((err) => {
				return err;
			})
		);
	}

	public setPageName(seoToken: string) {
		this.state.set(this.homRFilterKey, seoToken as any);
	}

	private getPageName(isHome = false) {
		const nameState: any = this.state.get(this.homRFilterKey, null);
		let pageName = '';

		if (isHome) {
			pageName = 'homepage';
		} else if (nameState) {
			pageName = nameState;
		}

		return pageName;
	}

	private createStateKey(pageType: string): string {
		const previewDate = this.getHeaders()['previewdate'];
		return !previewDate ? pageType : `${pageType}-${previewDate}`;
	}

	private getHeaders() {
		const httpHeaders = {
			'X-TIMEOUT': '3000',
			'Content-Type': 'application/json'
		};

		const acceptedHeaders = DCSG_CONSTANTS.homr.acceptedHeaders;

		const headerObj = this.findHeaders(acceptedHeaders);

		return { ...httpHeaders, ...headerObj };
	}

	private findHeaders(headerList: any) {
		const headerObj = {};

		for (const header of headerList) {
			const headerVal = this.httpService.getRequestHeader(header);
			if (headerVal) {
				headerObj[header] = headerVal;
			}
		}

		return headerObj;
	}

	buildAudienceObject(response: HomrPage | HomrPage[]) {
		const audienceAnalytics = [];
		const defaultAudience = 'default';
		const setAudience = (pageType: string, audience: string, content?: string) => {
			return {
				pageType,
				audience,
				...(content && { content })
			};
		};

		if (response !== null && this.isSinglePage(response)) {
			if (response.audience !== undefined && response.audience !== defaultAudience) {
				// Push template audience
				audienceAnalytics.push(
					setAudience(response.requestContext.pageType, response.audience)
				);
			}

			for (const content in response.contentScheduleMeta) {
				// Find the content audiences
				if (
					response.contentScheduleMeta[content].audience !== undefined &&
					response.contentScheduleMeta[content].audience !== defaultAudience
				) {
					audienceAnalytics.push(
						setAudience(
							response.requestContext.pageType,
							response.contentScheduleMeta[content].audience,
							content
						)
					);
				}
			}
		}
		return audienceAnalytics;
	}
}
