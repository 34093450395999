/** @format */

export const CANCEL_ICON = 'cancel-icon';
export const WARNING_ICON = 'warning-icon';
export const CONFIRM_ICON = 'confirm-icon';
export const IN_STORE_PURCHASE_ONLY = 'In-Store Purchase Only';
export const STORE_TEMPORARILY_CLOSED = 'Store Is Temporarily Closed.';
export const NOT_AVAILABLE_AT = 'Not Available at';
export const IN_STOCK_AT = 'In Stock at ';
export const SHIP_TO_STORE = 'Ship to ';
export const SVG_CHECKMARK = 'Checkmark.svg';

export const DCSG_CONSTANTS: any = {
	analyticsReporting: {
		boolean: {
			true: 'true',
			false: 'false'
		},
		dym: {
			auto: 'DYM Auto',
			clicked: 'DYM Clicked'
		},
		pageType: {
			home: 'Home',
			category: 'Category',
			family: 'Family',
			search: 'Search',
			product: 'Product Details',
			cart: 'Cart',
			checkout: 'Checkout',
			'order.confirmation': 'OrderConfirmation'
		},
		replacementType: {
			addition: 'Addition',
			replacement: 'Replacement'
		},
		searchEmptyResults: {
			recordSetTotal: '',
			catalogEntryView: [],
			searchDetails: {
				DYMTerm: '',
				DYMType: '',
				searchTerm: '',
				replacementTerm: '',
				searchType: ''
			}
		},
		searchType: {
			keyword: 'Keyword',
			suggestedKeyword: 'Suggested Keyword',
			suggestedProduct: 'Suggested Product',
			suggestedBrand: 'Suggested Brand',
			pdpBrandClick: 'PDP Brand Click',
			suggestedResources: 'Suggested Resources',
			suggestedHistory: 'Suggested History',
			suggestedCategory: 'Suggested Category',
			searchByProductID: 'Search by ProductID',
			redirect: 'Redirect'
		},
		yesOrNo: {
			Y: 'Y',
			N: 'N'
		}
	},
	attributes: {
		identifiers: {
			badge: '4298',
			freeReturnsEligible: '4493'
		}
	},
	basePath: 'dcsg-ngx-ecommerce',
	cookies: {
		auth: {
			expiration: {
				value: 30,
				unit: 'days'
			}
		},
		cart: {
			contents: {
				cookieName: 'DCSG-CART'
			}
		},
		checkout: {
			contents: {
				cookieName: 'DCSG-CHECKOUT'
			},
			xContents: {
				cookieName: 'X-DCSG-CHECKOUT'
			}
		},
		loyalty: {
			scoreCardGoldStatus: {
				cookieName: 'lg',
				cookieValue: 'Gold'
			}
		}
	},
	defaultCurrency: 'USD',
	defaultStoreSearchRadius: 100,
	defaultNumberOfStores: 5,
	errorHandling: {
		messagePrefixes: {
			global: {
				client: 'APPLICATION_ERROR:CLIENT_ERROR: ',
				data: 'APPLICATION_ERROR:DATA_ERROR: ',
				generic: 'APPLICATION_ERROR:GENERIC_ERROR: ',
				http: 'APPLICATION_ERROR:HTTP_ERROR: ',
				offline: 'APPLICATION_ERROR:OFFLINE_ERROR: ',
				timeout: 'TIMEOUT_ERROR: '
			}
		}
	},
	guestUserId: '-1002',
	homr: {
		acceptedHeaders: [
			'dsg_range',
			'dih',
			'audience',
			'draft',
			'preview',
			'previewdate',
			'whereabouts',
			'segment'
		],
		stateKeyExceptions: [
			'disruptor',
			'ticker',
			'header',
			'footer',
			'category',
			'deal-drawer',
			'calendar',
			'home',
			'static'
		],
		aosPageTypes: ['disruptor', 'footer', 'header', 'home', 'modal', 'ticker', 'checkout'],
		previewKey: 'williamgoats'
	},
	localStorage: {
		auth: {
			expiration: {
				value: 30,
				unit: 'days'
			}
		}
	},
	maxAppWidth: 1600,
	maxApiRetries: 3,
	membersOnlyMessaging: {
		title: 'ScoreCard Member Early Access!',
		body: 'Please sign in to make a purchase. ',
		linkText: 'Sign In',
		msgLevel: 'info'
	},
	paginationSize: {
		familyAndSearch: 48
	},
	searchMaxLength: 75,
	storeNumberUrl:
		'https://ngstorelocationlb.dcsg.com/NGStoreLocationWS/StoreLocationLookup.svc/json/GetStoreInformation',
	timeouts: {
		default: 20000,
		xxshort: 2000,
		xshort: 5000,
		short: 10000,
		medium: 30000,
		long: 60000
	},
	xProperties: {
		groupingLogicEnable: 'GROUPINGLOGIC_ENABLED'
	},
	baseImagePath: 'https://images.dickssportinggoods.com/marketing/',
	baseProductImagePath: 'https://dks.scene7.com/is/image/',
	buttonText: {
		atc: {
			dsg: 'ADD TO CART',
			calia: 'ADD TO BAG',
			gg: 'ADD TO CART',
			vrst: 'ADD TO CART',
			pl: 'ADD TO CART',
			g3: 'ADD TO CART'
		},
		inStoreOnly: 'IN-STORE Only',
		comingSoon: 'Coming Soon',
		outOfStock: 'Out Of Stock',
		customize: 'Customize',
		notifyMe: 'Notify Me When Available',
		notAvailable: 'Option Not Available',
		membersOnly: 'Sign In To Purchase',
		preOrder: 'PRE-ORDER',
		launch: 'Score Your Pair'
	},
	buttonStyle: {
		primary: 'primary',
		secondary: 'secondary',
		tertiary: 'tertiary'
	},
	buttonAction: {
		atc: {
			add_to_cart: 'add to cart',
			customize: 'customize',
			notifyUser: 'notify user'
		}
	},
	shipToMe: {
		notAvailableToShip: {
			label: 'Not Available to Ship',
			tooltip:
				'This product has limited stock for shipping though some product options maybe available at your nearest store. Try selecting another product option to see further availability',
			tooltipLabel: 'Why?',
			displayToolTip: true,
			disabled: true,
			homefieldIcon: 'alert-error-filled',
			icon: 'error',
			altIcon: 'error',
			style: CANCEL_ICON
		},
		availableToShip: {
			label: 'Available to Ship',
			disabled: false,
			homefieldIcon: 'sign-circled-check',
			icon: 'check_circle',
			altSvgIcon: SVG_CHECKMARK,
			style: CONFIRM_ICON
		},
		shipToMe: {
			label: 'Ship to Me',
			disabled: false,
			homefieldIcon: 'sign-circled-check',
			icon: 'check_circle',
			altSvgIcon: SVG_CHECKMARK,
			style: CONFIRM_ICON
		},
		notAvailableToPurchaseOnline: {
			label: 'Not Available to Purchase Online',
			tooltip:
				'This product has purchasing restrictions.  Visit your nearest store for more information',
			tooltipLabel: 'Why?',
			displayToolTip: true,
			disabled: true,
			homefieldIcon: 'alert-error-filled',
			icon: 'error',
			altIcon: 'error',
			style: CANCEL_ICON
		},
		gtgtInStore: {
			homefieldIcon: 'sign-circled-check',
			icon: 'check_circle',
			altSvgIcon: SVG_CHECKMARK,
			style: CONFIRM_ICON,
			label: 'Available to Ship',
			startOfMessage: 'Guaranteed for delivery ',
			endOfMessage: ' if ordered within the next',
			disabled: false,
			displayTimer: true
		},
		noProductsInBundle: {
			label: 'Add Product to Set for Availability to Ship',
			disabled: true,
			icon: 'remove_circle',
			altIcon: 'error',
			style: WARNING_ICON
		}
	},
	bopis: {
		minimumAvailableAtStore: 1,
		cubsidePickupAvailable: {
			label: IN_STOCK_AT,
			additionalInfo:
				'Curbside &amp; In-Store Pickup Available. <a href="/s/in-store-pickup" target="_blank">Details</a>',
			disabled: false,
			icon: 'check_circle',
			altSvgIcon: SVG_CHECKMARK,
			style: CONFIRM_ICON,
			displayNumberLeftMessage: true
		},
		inStockClosedPickup: {
			label: IN_STOCK_AT,
			additionalInfo: 'In-Store Purchase Only',
			disabled: true,
			icon: 'store_mall_directory',
			altIcon: 'error',
			style: CONFIRM_ICON,
			displayNumberLeftMessage: true
		},
		noStoreSelected: {
			label: 'Some Product Options May Be Available. ',
			additionalInfo: 'Select A Store to See Availability.',
			disabled: true
		},
		noStoreSelectedNotSkuResolved: {
			label: 'Select Product Options for Availability at ',
			disabled: true
		},
		noStoreSelectedSkuUnresolved: {
			label: 'Some Product Options May Be Available. ',
			disabled: true
		},
		storeSelectedSkuResolvedNotAvailable: {
			label: NOT_AVAILABLE_AT,
			icon: 'error',
			altIcon: 'error',
			style: CANCEL_ICON,
			displayNumberLeftMessage: true,
			disabled: true
		},
		storeSelectedSkuResolvedNotAvailableButNearby: {
			label: 'Not Available at ',
			disabled: true,
			displayToolTip: true,
			icon: 'store_mall_directory',
			altIcon: 'error',
			style: WARNING_ICON,
			displayNumberLeftMessage: true
		},
		notAvailabeDisplayNearby: {
			label: 'Not Available at ',
			icon: 'store_mall_directory',
			altIcon: 'error',
			disabled: true,
			style: CANCEL_ICON,
			displayNearbyMessage: true,
			displayNumberLeftMessage: true
		},
		storeSelectedSkuResolvedAvailableInStoreOnly: {
			label: 'Available at ',
			doesStoreCloseLabelApply: true,
			additionalInfo: `${IN_STORE_PURCHASE_ONLY}`,
			disabled: true,
			icon: 'store_mall_directory',
			altIcon: 'error',
			style: CONFIRM_ICON,
			useLimitedStockCss: true,
			displayNumberLeftMessage: true
		},
		storeSelectedSkuResolvedAvailableInStoreOnlyLimitedQuantity: {
			additionalInfo: `${IN_STORE_PURCHASE_ONLY}. Limited inventory!`,
			label: IN_STOCK_AT,
			doesStoreCloseLabelApply: true,
			icon: 'store_mall_directory',
			altIcon: 'error',
			style: CONFIRM_ICON,
			disabled: true,
			useLimitedStockCss: true,
			displayNumberLeftMessage: true
		},
		notAvailable: {
			label: NOT_AVAILABLE_AT,
			disabled: true,
			icon: 'error',
			alticon: 'error',
			style: CANCEL_ICON,
			displayNumberLeftMessage: true
		},
		selectedInventoryExceed: {
			label: 'Quantity selected exceeds store inventory at',
			disabled: true,
			icon: 'error',
			altIcon: 'error',
			style: CANCEL_ICON,
			displayNumberLeftMessage: true
		},
		selectProductOptions: {
			label: 'Select Product Options for Availability at',
			disabled: true,
			additionalInfo: IN_STORE_PURCHASE_ONLY,
			displayNumberLeftMessage: true
		},
		storeSelectedNoProductsInBundle: {
			label: 'Add Product to Set for Availability at',
			disabled: true,
			icon: 'remove_circle',
			altIcon: 'error',
			style: WARNING_ICON,
			displayNumberLeftMessage: true
		},
		noStoreSelectedNoProductsInBundle: {
			label: 'Add Product to Set and Select Store',
			disabled: true,
			icon: 'remove_circle',
			altIcon: 'error',
			style: WARNING_ICON,
			displayNumberLeftMessage: true
		},
		BoplAvailable: {
			label: SHIP_TO_STORE,
			icon: 'check_circle',
			altSvgIcon: SVG_CHECKMARK,
			style: CONFIRM_ICON,
			displayNumberLeftMessage: true,
			isBopl: true,
			altButtonLabel: 'Free Ship to Store'
		}
	},
	sameDayDelivery: {
		deliveryAvailable: {
			label: 'Available For Same Day Delivery',
			disabled: false,
			icon: 'check_circle',
			altSvgIcon: SVG_CHECKMARK,
			style: CONFIRM_ICON,
			homefieldIcon: 'sign-circled-check'
		},
		deliveryUnavailable: {
			label: 'Not Available for Same Day Delivery',
			disabled: true,
			icon: 'error',
			homefieldIcon: 'alert-error-filled',
			altIcon: 'error',
			style: CANCEL_ICON
		},
		selectProductOptions: {
			label: 'Select Product Options for Availability for Same Day Delivery',
			disabled: true,
			additionalInfo: IN_STORE_PURCHASE_ONLY
		},
		selectStore: {
			label: 'Select Store above to see availability.',
			disabled: true
		}
	},

	closedStore: {
		noInventoryNearBy: {
			label: NOT_AVAILABLE_AT,
			disabled: true,
			additionalInfo: STORE_TEMPORARILY_CLOSED,
			icon: 'error',
			altIcon: 'error',
			style: CANCEL_ICON,
			displayNumberLeftMessage: false,
			closedStore: true
		},
		inventoryNearBy: {
			label: NOT_AVAILABLE_AT,
			disabled: true,
			additionalInfo: STORE_TEMPORARILY_CLOSED,
			icon: 'store_mall_directory',
			altIcon: 'error',
			style: WARNING_ICON,
			displayNumberLeftMessage: false,
			closedStore: true
		},
		inventoryHouseIconNearBy: {
			label: NOT_AVAILABLE_AT,
			disabled: true,
			additionalInfo: STORE_TEMPORARILY_CLOSED,
			icon: 'store_mall_directory',
			altIcon: 'error',
			style: CANCEL_ICON,
			displayNumberLeftMessage: false,
			closedStore: true
		}
	},
	imageUnavailable: 'https://images.dickssportinggoods.com/marketing/productImageUnavailable.jpg',
	listCardImageUnavailable:
		'https://images.dickssportinggoods.com/marketing/listCardImageUnavailable.svg',

	minQtyAlert: {
		// message: 'You have selected the quantity which is less than the minimum limit for this product. The quantity has been adjusted for you.'
		message: 'The minimum quantity for this product option is'
	},

	maxQtyAlert: {
		// message: 'You have exceeded the maximum limit on the quantity for this product. The quantity has been adjusted for you.'
		message: 'The maximum quantity for this product option is'
	},

	airballMessages: {
		standard:
			"Your product missed the cart. We're sorry, please refresh your page and Add to Cart again.  If The Problem persists, please let us know.",
		makeAnotherSelection:
			'The Product Options Selected Are not Available For Shipping Or Pickup.  Please Pick A Different Option Or Find An Available Store.',
		outOfStock: 'No sufficient inventory for the selected product.'
	},

	atcSuccessPanelHeaders: {
		msg: {
			dsg: 'ADDED TO CART',
			calia: 'ADDED TO BAG',
			gg: 'ADDED TO CART',
			vrst: 'ADDED TO CART',
			pl: 'ADDED TO CART',
			g3: 'ADDED TO CART'
		}
	},
	atcSuccessPanelButton: {
		msg: {
			dsg: 'GO TO CART',
			calia: 'GO TO BAG',
			gg: 'GO TO CART',
			vrst: 'GO TO CART',
			pl: 'GO TO CART',
			g3: 'GO TO CART'
		},
		checkout: {
			dsg: 'Checkout Now',
			calia: 'Checkout Now',
			gg: 'Checkout Now',
			vrst: 'Checkout Now',
			pl: 'Checkout Now',
			g3: 'Checkout Now'
		}
	}
};
