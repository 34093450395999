/** @format */
import { IEnvironment, prodEnvironmentDefaults } from '@dcsg-ngx-ecommerce/core';

import { HOME_CONSTANTS } from '../app/app.constants';
import { chain } from '@core/chain';

export const environment: IEnvironment = {
	api: {
		spring: {
			microService: {
				...prodEnvironmentDefaults.api.spring.microService,
				cart: 'https://www.dickssportinggoods.com/',
				product: {
					AZ: 'https://active-productservice.iso.ap01.pcf.dcsg.com/',
					AZ2: 'https://active-productservice.iso.ap02.pcf.dcsg.com/',
					AZ3: 'https://active-productservice.iso.ap03.pcf.dcsg.com/'
				}
			},
			microServiceProxy: {
				breadcrumb: {
					path: `/${HOME_CONSTANTS.appPathPrefix}/spring/msvc/breadcrumb/`,
					timeout: '15000'
				},
				cart: {
					path: `/api/v1/carts/contents`,
					timeout: '60000'
				},
				category: {
					path: `/${HOME_CONSTANTS.appPathPrefix}/spring/msvc/category/`,
					timeout: '15000'
				},
				logger: {
					path: `/${HOME_CONSTANTS.appPathPrefix}/spring/msvc/logger/`,
					timeout: '15000'
				},
				seoservice: {
					path: `/${HOME_CONSTANTS.appPathPrefix}/spring/msvc/seo/`,
					timeout: '15000'
				},
				product: {
					path: `/${HOME_CONSTANTS.appPathPrefix}/spring/msvc/product/`,
					timeout: '15000'
				}
			}
		},
		nestjs: {
			microService: {
				product: {
					AZ: 'https://active-productservice-nest.iso.ap01.pcf.dcsg.com/',
					AZ2: 'https://active-productservice-nest.iso.ap02.pcf.dcsg.com/',
					AZ3: 'https://active-productservice-nest.iso.ap03.pcf.dcsg.com/'
				}
			},
			microServiceProxy: {
				product: {
					path: `/${HOME_CONSTANTS.appPathPrefix}/nest/msvc/product/`,
					timeout: '15000'
				}
			}
		},
		esb: {
			gatewayUrl: 'https://gateway.dcsg.com/',
			gatewayUrlProxy: {
				path: `/${HOME_CONSTANTS.appPathPrefix}/esb/api/`,
				timeout: '30000'
			}
		},
		homr: {
			...prodEnvironmentDefaults.api.homr,
			proxy: {
				...prodEnvironmentDefaults.api.homr.proxy,
				path: `/${HOME_CONSTANTS.appPathPrefix}/homr/v2/api/`
			},
			dapiV5: true,
			url: {
				AZ: 'https://main-dapi.iso.ap01.pcf.dcsg.com/',
				AZ2: 'https://main-dapi.iso.ap02.pcf.dcsg.com/',
				AZ3: 'https://main-dapi.iso.ap03.pcf.dcsg.com/'
			},
			scene7UrlContent: '//dks.scene7.com/is/content/dksfed/'
		},
		elastic: {
			availability: 'https://availability.dickssportinggoods.com',
			autoSuggestUrl: 'https://prod-catalog-autosuggest-api.dickssportinggoods.com/',
			searchTermUrl: 'https://prod-catalog-product-api.dickssportinggoods.com/'
		},
		ceops: prodEnvironmentDefaults.api.ceops,
		footwearCalendar: {
			checkCacheUrl: `http://localhost:8080/footwear/check-cache-for-ecodes`
		},
		productDetails: `https://www.dickssportinggoods.com`,
		kraken: {
			gatewayUrl: `https://main-kraken.iso.ap01.pcf.dcsg.com`,
			gatewayUrlProxy: {
				path: `/${HOME_CONSTANTS.appPathPrefix}/kraken/api`
			}
		},
		bikeService: {
			gatewayUrl: `https://athlete-checklist-api.iso.ap01.pcf.dcsg.com/`,
			gatewayUrlProxy: `/${HOME_CONSTANTS.appPathPrefix}/bike-service/api`
		},
		brandify: {
			brandifyBaseUrl: 'https://hosted.where2getit.com/',
			proxy: {
				path: `/${HOME_CONSTANTS.appPathPrefix}/store/locator/`
			}
		},
		reactMarginals: prodEnvironmentDefaults.api.reactMarginals,
		promoteIQ: 'https://ad-dickssportinggoods-prod.tagdelivery.com/request?',
		seo: 'https://api-search.dickssportinggoods.com/',
		seoByChain: `https://api-search.${chain.seoIdentifier}.com/`,
		dataScience: prodEnvironmentDefaults.api.dataScience
	},
	apiTokens: {
		brandify: {
			storeLocator: {
				dsg: '8D669860-73FB-11E8-8752-FF2F0D70A832',
				gg: 'CE23B360-C828-11E4-B146-ED9AA38844B8',
				pl: 'THIS NEEDS TO BE ADDED',
				g3: 'THIS NEEDS TO BE ADDED',
				sm: 'THIS NEEDS TO BE ADDED',
				plf: 'THIS NEEDS TO BE ADDED'
			}
		},
		esb: {
			api: '38d00cb0-6e1b-11e6-8390-8c24708cfd68',
			api2: '9b59c9e0-6710-11e7-bdcc-80098dffd0fa',
			data: '1bfc85f0-ea22-11e5-9697-8d6fc3625af1',
			identity: 'af727360-d115-11e5-ab08-be8d24eccbe9',
			myaccount: 'd0b85d50-d115-11e5-ab08-b8fc4c91c2b3',
			inventory: '1e370cd0-90fd-11e8-88c8-b1d2f2dd8b93'
		}
	},
	partyAuthKeys: {
		partnerKey: 'tof',
		secretKey: 'DYBZfgOIt2liWVYrPiPw'
	},
	thirdParty: {
		auth0: {
			...prodEnvironmentDefaults.thirdParty.auth0
		},
		images: {
			...prodEnvironmentDefaults.images,
			flags: {
				useProxy: false,
				useNetstorage: false
			}
		},
		searchDex: {
			gatewayUrlProxy: {
				path: `/${HOME_CONSTANTS.appPathPrefix}/sdxincludes/api/`,
				timeout: '15000'
			},
			store: {
				dsg: {
					baseUrl: 'https://www.dickssportinggoods.com/products/includes/'
				},
				gg: {
					baseUrl: 'https://www.golfgalaxy.com/products/includes/'
				},
				calia: {
					baseUrl: 'https://www.dickssportinggoods.com/products/includes/'
				},
				pl: {
					baseUrl: 'https://www.publiclands.com/products/includes/'
				},
				g3: {
					baseUrl: 'https://www.goinggoing.com/products/includes/'
				},
				sm: {
					baseUrl: 'https://www.dickssportinggoods.com/products/includes/'
				},
				plf: {
					baseUrl: 'https://www.dickssportinggoods.com/products/includes/'
				}
			}
		},
		scene7: {
			baseConfig: {
				baseUrl: 'https://dks.scene7.com/',
				cdn: 'GolfGalaxy/',
				css: 'skins/Scene7SharedAssets/htmlviewerskins/css/html5_mixedmediaviewer_light.css',
				imageDirectory: 'is/image/',
				queryString: '?req=set,json,UTF-8',
				sdkUrl: 's7sdk/3.3/js/s7sdk',
				secureBaseUrl: 'https://dks.scene7.com/',
				videoDirectory: 'is/content/',
				viewerUrl: 's7viewers/html5/js/MixedMediaViewer.js'
			},
			url: 'https://dks.scene7.com/is/image/dkscdn/',
			suffix: '$DSG_ProductCard$',
			timeout: 10000
		},
		tealium: {
			enabled: true,
			store: {
				dsg: {
					baseUrl: 'https://tag.dickssportinggoods.com/utag.js'
				},
				gg: {
					baseUrl: 'https://tag.golfgalaxy.com/utag.js'
				},
				pl: {
					baseUrl: 'https://tag.publiclands.com/utag.js'
				},
				g3: {
					baseUrl: 'https://tag.goinggoinggoing.com/utag.js'
				},
				sm: {
					baseUrl: 'https://tag.dickssportinggoods.com/utag.js'
				},
				plf: {
					baseUrl: 'https://tag.dickssportinggoods.com/utag.js'
				}
			}
		}
	},
	feature: {
		...prodEnvironmentDefaults.feature,
		releaseCalendarReminders: true,
		useRedis: false,
		useMock: false,
		certonaUpdates: true,
		isBotCheckEnabled: {
			dsg: true,
			gg: true,
			pl: true,
			g3: true,
			plf: true,
			sm: true
		},
		// Determines if stylitics mix and match functionality is enabled
		styliticsMnMEnabled: {
			dsg: false,
			gg: false,
			pl: false,
			g3: false,
			plf: false,
			sm: false
		}
	},
	logging: {
		client: {
			level: 2
		},
		xhr: {
			level: 7,
			url: 'https://log-publisher.cfapps.io/publish'
		}
	},
	identityServiceKey: {
		...prodEnvironmentDefaults.identityServiceKey
	},
	baseDomain: {
		...prodEnvironmentDefaults.baseDomain
	},
	production: true,
	imagePresets: true,
	defaultActiveCloudProvider: 'AZ',
	catOvrGrpId: null,
	customizer: null,
	dataFiles: {
		csv: {
			proxy: {
				path: `assets/csv/`,
				timeout: '15000'
			}
		},
		json: {
			proxy: {
				path: `assets/json/`,
				timeout: '15000'
			}
		}
	},
	esbEnvironmentHeader: 'auxlive',
	inventory: null,
	myAccount: null,
	searchbyTermTypeParam: null,
	killSwitch: null,
	loyalty: null
};
