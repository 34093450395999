/** @format */

import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import {
	AnalyticsService,
	VirtualHostCookieService,
	CookieService,
	HttpService,
	Store,
	StoreService
} from '@dcsg-ngx-ecommerce/core';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { chain } from '@core/chain';
import { CartService } from '@dcsg-ngx-ecommerce/cart';
import { WINDOW } from '@dcsg-ngx-ecommerce/core/service/window.service';
import { AuthenticationService } from '@dcsg-ngx-ecommerce/myaccount/service/authentication.service';

import { Observable } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { Customer } from '@shared/data-access/interfaces';
import { SeoService } from '@seo/data-access';

@Component({
	selector: 'home-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	/**
	 * @description
	 * The chain identifier abbreviation
	 */
	public chainIdentifierAbbr = chain.chainIdentifierAbbr;

	/**
	 * @description
	 * The Customer object from application state
	 */
	private customer$ = this.store.select('CUSTOMER');

	constructor(
		private authService: AuthenticationService,
		@Inject(DOCUMENT) private document: Document,
		@Inject(PLATFORM_ID) private platformId,
		@Inject(WINDOW) private window: Window,
		private cartService: CartService,
		private storeService: StoreService,
		private cookieService: CookieService,
		private httpService: HttpService,
		private analyticsService: AnalyticsService,
		private store: Store,
		private router: Router,
		private route: ActivatedRoute,
		private seoService: SeoService,
		private virtualHostCookieService: VirtualHostCookieService
	) {
		// Check authentication status and set up authentication status stream
		this.authService.localAuthSetup();
	}

	ngOnInit(): void {
		// Reset the analytics
		if (!this.window.siteData) {
			this.analyticsService.resetAnalytics();
		}

		this.observeNavigation().subscribe();

		if (isPlatformBrowser(this.platformId)) {
			if (navigator.userAgent.indexOf('Mac OS X') !== -1) {
				this.document.body.classList.add('mac');
			}

			// Virtual Host chain identifier
			this.virtualHostCookieService.setVirtualHostCookie();

			/*
			 * Get the promoId query parameter from the URL
			 */
			const promoId = this.route.snapshot.queryParams['param'];
			if (promoId && !this.cookieService.check('promoId')) {
				this.cookieService.set(
					'promoId',
					this.httpService.getUrlParameter('param'),
					'',
					'/',
					'',
					''
				);
			}

			// Initialize the Cart Service
			this.cartService.initialize();

			// Check cookie and populate Store
			this.authService.checkIfReturningCustomer();

			// Set default store
			this.storeService.initialize();
		}
	}

	/* istanbul ignore next */
	public observeNavigation(): Observable<any> {
		return this.router.events.pipe(
			filter((event) => {
				return event instanceof NavigationEnd;
			}),
			map(() => {
				return this.route;
			}),
			map((route: any) => {
				while (route.firstChild) {
					route = route.firstChild;
				}
				return route;
			}),
			mergeMap((route: ActivatedRoute) => {
				return new Observable((observer) => {
					observer.next({
						data: route.data,
						params: route.params,
						queryParams: route.queryParams
					});
					observer.complete();
				});
			}),
			tap((route: any) => {
				/*
				 * Only execute on browser
				 */
				this.seoService
					.initialize(
						route.params.value.seoName || 'homepage',
						'home',
						this.router.url.split('?')[0]
					)
					.pipe(take(1))
					.subscribe();

				if (isPlatformBrowser(this.platformId)) {
					return this.initializeAnalyticsService(route.params);
				}
			})
		);
	}

	public async initializeAnalyticsService(params) {
		// Get the current query parameters from the route query params
		const CURRENT_QUERY_PARAMS = params;
		// Get the current Date/Time
		const CURRENT_DATETIME = new Date();

		// Set the chain
		this.analyticsService.setChain(chain.chainName);

		// Set the Error ID (if present)
		this.analyticsService.setErrorId();

		// Set the Error Message (if present)
		this.analyticsService.setErrorMessage();

		// Set the referring URL
		this.analyticsService.setReferringPageUrl(this.document.referrer);

		// Set the Page Type
		this.analyticsService.setType('page', 'Home Page');

		// Set the server date
		this.analyticsService.setServerDate(
			this.analyticsService.getFormattedDate(CURRENT_DATETIME)
		);

		// Set the server time
		this.analyticsService.setServerTime(
			this.analyticsService.getFormattedTime(CURRENT_DATETIME)
		);

		// Get the Customer from the Store and populate related analytics
		this.customer$.subscribe((customer: Customer) => {
			// Set the Loyalty ID
			this.analyticsService.setLoyaltyID(customer.x_acNumber || '');

			// Set the LoyaltyBalance
			this.analyticsService.setLoyaltyPointsBalance(customer.x_acBalancePoints || '');

			// Set the session ID
			this.analyticsService.setSessionID(customer.sessionId);

			// Set the user ID
			this.analyticsService.setUserID(customer.userId);

			// Set the visits count
			this.analyticsService.setVisitNumber(customer.visitCount);
		});

		// Set the base Search properties
		this.analyticsService.setSearchPageBaseProperties({
			searchDetails: {
				DYMTerm: CURRENT_QUERY_PARAMS.dymterm || CURRENT_QUERY_PARAMS.DYMTerm || '',
				DYMType: CURRENT_QUERY_PARAMS.didyoumean || CURRENT_QUERY_PARAMS.didYouMean || '',
				replacementTerm: CURRENT_QUERY_PARAMS.replacementTerm || '',
				searchTerm: CURRENT_QUERY_PARAMS.searchTerm || '',
				searchType: CURRENT_QUERY_PARAMS.searchType || ''
			}
		});

		// Validate authentication status
		const isAuthenticated: boolean = await this.authService
			.getIsAuthenticated$()
			.toPromise()
			.catch((err) => {
				this.analyticsService.setErrorId('DCSG_NGX_HOME_AUTHENTICATED_ERROR');
				this.analyticsService.setErrorMessage(`HOME:${err.message}`);

				// Return false because there was an error validating authentication status
				return false;
			});

		// Set the logged in flag
		this.analyticsService.setLoggedIn(`${isAuthenticated}`);

		// Set the LoggedInOccured property
		this.analyticsService.setLoggedInOccurred(`${isAuthenticated}`);
	}
}
