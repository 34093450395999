/** @format */

import { DynamicTemplateLoader } from '@dcsg-ngx-ecommerce/core';
import { ViewContainerRef } from '@angular/core';
import { environment } from '@environment/home';

/**
 * When adding a new dynamically loaded component there are a few steps to note.
 * These steps are all necessary to the functionality of the app. Please note them each time you create a new component.
 * 1. Add new binding function, matching the structure below. The list of ids should match the normalized name of the content type as seen in the homR response.
 * 2. Each component module should have a resolveComponentFactory function, see story.module.ts for an example
 * 3. Each component and it's module should be included in their respective lists in the components/index.ts file
 */

interface IBindingFunction {
	ids: string[];
	loader: (
		dtlService: DynamicTemplateLoader,
		data: any,
		elemRef: ViewContainerRef,
		addlData: any
	) => void;
}
/* istanbul ignore next */
const bindingFunctions: IBindingFunction[] = [
	{
		/// Accordion
		ids: [
			'accordionSection', // 1 - 93
			'accordionSectionAlpha', // 2 - 108
			'accordionSectionBravo', // 3 - 109
			'accordionSectionCharlie', // 4 - 110
			'accordionSectionDelta' // 5 - 111
		],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { AccordionComponent } = await import('./components/accordion');
			dtlService.loadComponent(data, elemRef, addlData, AccordionComponent);
		}
	},
	{
		/// Banner
		ids: [
			'bannerAlpha', // 1 - 86
			'bannerBravo', // 2 - 87
			'bannerCharlie', // 3 - 88
			'bannerDelta', // 4 - 89
			'bannerEcho', // 5 - 90
			'imageBanner', // 6 - 73
			'bannerFoxtrot', // 7 - 165
			'bannerGolf', // 8 - 166
			'bannerHotel', // 9 - 167
			'bannerIndia', // 10 - 168
			'bannerJuliet', // 11 - 169
			'bannerKilo' // 12 - 170
		],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { BannerComponent } = await import('./components/banner');
			dtlService.loadComponent(data, elemRef, addlData, BannerComponent);
		}
	},
	{
		/// Carousel
		ids: [
			'heroV2', // 1 - 29
			'fullStoryV2', // 2 - 30
			'fullStoryAlt', // 3 - 76
			'fullStoryAlt2', // 4 - 105
			'fullStoryAlt3' // 5 - 106
		],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { CarouselComponent } = await import('./components/story-carousel');
			dtlService.loadComponent(data, elemRef, addlData, CarouselComponent);
		}
	},
	{
		ids: [
			'categories', // 1 - 2
			'categoriesAlpha', // 2 - 191
			'categoriesBravo', // 3 - 192
			'categoriesCharlie', // 4 - 193
			'categoriesDelta', // 5 - 194
			'categoriesEcho' // 6 - 195
		],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { CategoriesComponent } = await import('./components/categories');
			dtlService.loadComponent(data, elemRef, addlData, CategoriesComponent);
		}
	},
	{
		ids: ['categoryList'],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { CategoryListComponent } = await import('./components/category-list');
			dtlService.loadComponent(data, elemRef, addlData, CategoryListComponent);
		}
	},
	{
		ids: [
			'scrollCategories',
			'scrollCategoriesAlpha',
			'scrollCategoriesBravo',
			'scrollCategoriesCharlie',
			'scrollCategoriesDelta'
		],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { ScrollCategoriesComponent } = await import(
				'./components/categories/scroll-categories'
			);
			dtlService.loadComponent(data, elemRef, addlData, ScrollCategoriesComponent);
		}
	},
	{
		ids: ['tiledCategories'],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { TiledCategoriesComponent } = await import(
				'./components/categories/tiled-categories'
			);
			dtlService.loadComponent(data, elemRef, addlData, TiledCategoriesComponent);
		}
	},
	{
		/// Certona
		ids: [
			'certona',
			'certonaAlpha',
			'certonaBravo',
			'certonaCharlie',
			'certonaDelta',
			'certonaEcho',
			'certonaFoxtrot',
			'certonaGolf',
			'certonaHotel',
			'certonaIndia',
			'certonaJuliet',
			'certonaKilo',
			'certonaLima',
			'certonaMike'
		],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { RecommendationContainerComponent } = await import(
				'./components/recommendation-container'
			);
			dtlService.loadComponent(data, elemRef, addlData, RecommendationContainerComponent);
		}
	},
	{
		/// LaunchCalendar
		ids: ['newLaunchCalendar'], // 1 - 242
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { LaunchCalendarComponent } = await import('./components/launch-calendar');
			dtlService.loadComponent(data, elemRef, addlData, LaunchCalendarComponent);
		}
	},
	{
		/// RebateList
		ids: ['rebateList'],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { RebateListComponent } = await import('./components/rebate-list');
			dtlService.loadComponent(data, elemRef, addlData, RebateListComponent);
		}
	},
	{
		/// Certona W/Background
		ids: [
			'certonaBackground',
			'certonaBackgroundAlpha',
			'certonaBackgroundBravo',
			'certonaBackgroundCharlie'
		],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { RecommendationWithBackgroundComponent } = await import(
				'./components/recommendation/recommendation-with-background'
			);
			dtlService.loadComponent(
				data,
				elemRef,
				addlData,
				RecommendationWithBackgroundComponent
			);
		}
	},
	{
		/// Hype Header
		ids: [
			'hypeStoreColumnsHeadline', // 1
			'hypeStoreColumnsHeadlineBravo' // 2
		],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { HypeHeaderComponent } = await import('./components/hype-header');
			dtlService.loadComponent(data, elemRef, addlData, HypeHeaderComponent);
		}
	},
	{
		/// Hype Hero
		ids: [
			'hypeHero', // 1
			'hypeHeroAlpha', // 2
			'hypeHeroBravo', // 3
			'hypeHeroCharlie', // 4
			'hypeHeroDelta', // 5
			'hypeHeroEcho' // 6
		],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { HypeHeroComponent } = await import('./components/hype-hero');
			dtlService.loadComponent(data, elemRef, addlData, HypeHeroComponent);
		}
	},
	{
		/// Anyroad
		ids: ['anyRoadPage'],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { AnyroadComponent } = await import('./components/anyroad');
			dtlService.loadComponent(data, elemRef, addlData, AnyroadComponent);
		}
	},
	{
		/// Anyroad (ID Page)
		ids: ['anyRoadIDPage'],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { AnyroadIdComponent } = await import('./components/anyroad-id');
			dtlService.loadComponent(data, elemRef, addlData, AnyroadIdComponent);
		}
	},
	{
		/// Curalate
		ids: ['curalate'],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { CuralateComponent } = await import('./components/curalate');
			dtlService.loadComponent(data, elemRef, addlData, CuralateComponent);
		}
	},
	{
		/// Zoovu
		ids: ['zoovuframe'],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { ZoovuComponent } = await import('./components/zoovu');
			dtlService.loadComponent(data, elemRef, addlData, ZoovuComponent);
		}
	},
	{
		/// iFrame
		ids: ['iFramePage'],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { IframeComponent } = await import('./components/iframe');
			dtlService.loadComponent(data, elemRef, addlData, IframeComponent);
		}
	},
	{
		ids: [
			'krakenAlpha', // 1 - 81
			'krakenBeta', // 2 - 80
			'krakenBravo', // 3 - 82
			'krakenCharlie', // 4 - 83
			'krakenDelta', // 5 - 84
			'krakenEcho', // 6 - 85
			'krakenPage' // 7 - 75
		],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { KrakenComponent } = await import('@home/components');
			dtlService.loadComponent(data, elemRef, addlData, KrakenComponent);
		}
	},
	{
		/// Google Ad Manager
		ids: ['googleAdManager'],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const googleAdManagerID = data[0].googleAdManagerID;
			const { GoogleAdManagerComponent } = await import(
				'@dcsg-ngx-ecommerce/common/component/google-ad-manager'
			);
			dtlService.loadComponent(
				googleAdManagerID,
				elemRef,
				addlData,
				GoogleAdManagerComponent
			);
		}
	},
	{
		ids: ['navigation'],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { PageNavComponent } = await import('./components/page-nav');
			dtlService.loadComponent(data, elemRef, addlData, PageNavComponent);
		}
	},
	{
		ids: [
			'productSpots',
			'productSpotsAlpha',
			'productSpotsBravo',
			'productSpotsCharlie',
			'productSpotsDelta',
			'productSpotsEcho'
		],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { ProductSpotsComponent } = await import('./components/product-spots');
			dtlService.loadComponent(data, elemRef, addlData, ProductSpotsComponent);
		}
	},
	{
		ids: ['promoteIQ'],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { PromoteIQComponent } = await import('./components/promote-iq');
			dtlService.loadComponent(data, elemRef, addlData, PromoteIQComponent);
		}
	},
	{
		/// Section Header
		ids: [
			'categoryV2HeaderOptional', // 1 - 47
			'categoriesHeaderOptional', // 2 - 45
			'trendingHeaderOptional', // 3 - 46
			'trendingHeader2Optional', // 4 - 70
			'featuredDealsHeaderOptional', // 5 - 48
			'featuredDealsHeader2Optional', // 6 - 69
			'newArrivalsHeaderOptional', // 7 - 49
			'sectionHeaderAlpha', // 8 - 156
			'sectionHeaderBravo', // 9 - 158
			'sectionHeaderCharlie', // 10 - 159
			'sectionHeaderDelta', // 11 - 160
			'sectionHeaderEcho', // 12 - NA
			'sectionHeaderFoxtrot', // 13 - NA
			'sectionHeaderGolf', // 14 - NA
			'sectionHeaderHotel', // 15 - NA
			'sectionHeaderIndia', // 16 - NA
			'sectionHeaderJuliet', // 17 - NA
			'sectionHeaderKilo', // 18 - NA
			'sectionHeaderLima', // 19 - NA
			'sectionHeaderMike' // 20 - NA
		],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { SectionHeaderComponent } = await import('./components/section-header');
			dtlService.loadComponent(data, elemRef, addlData, SectionHeaderComponent);
		}
	},
	{
		// Steps
		ids: [
			'step', // 1 - 107
			'stepAlpha', // 2 - 172
			'stepBravo', // 3 - 173
			'stepCharlie', // 4 - 174
			'stepDelta' // 5 - 175
		],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { StepsSectionComponent } = await import('./components/steps');
			dtlService.loadComponent(data, elemRef, addlData, StepsSectionComponent);
		}
	},
	{
		/// Story
		ids: [
			'trending', // 1 - 3
			'categoryV2', // 2 - 31
			'featuredDeals', // 3 - 32
			'featuredDeals2', // 4 - 68
			'trending2', // 5 - 67
			'trendingAlpha', // 6 - 205
			'trendingBeta', // 7 - NA
			'trendingCharlie', // 8 - NA
			'trendingDelta', // 9 - NA
			'trendingEcho', // 10 - NA
			'trendingFoxtrot', // 11 - NA
			'trendingHotel' // 12 - NA
		],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { StoryComponent } = await import('./components/story');
			dtlService.loadComponent(data, elemRef, addlData, StoryComponent);
		}
	},
	{
		///  Home Stylitics
		ids: [
			'styliticsGalleryAlpha',
			'styliticsGalleryBravo',
			'styliticsGalleryCharlie',
			'styliticsGalleryDelta',
			'styliticsGalleryEcho',
			'styliticsGalleryFoxtrot'
		],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { HomeStyliticsComponent } = await import('./components/home-stylitics');
			// passing feature flags
			if (environment.feature) {
				addlData = environment.feature;
			}
			dtlService.loadComponent(data, elemRef, addlData, HomeStyliticsComponent);
		}
	},
	{
		/// WYSIWYG
		ids: [
			'informationalsContent', // 1 - 64
			'informationals', // 2
			'hypeDetails' // 3
		],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { WysiwygComponent } = await import('./components/wysiwyg');
			dtlService.loadComponent(data, elemRef, addlData, WysiwygComponent);
		}
	},
	{
		/// WYSIWYG Columns
		ids: [
			'hypeStoreColumnsMedium', // 1 - 200
			'hypeStoreColumnsMediumAlpha' // 2 - 217
		],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { WysiwygColumnsMediumComponent } = await import(
				'./components/wysiwyg-columns-medium'
			);
			dtlService.loadComponent(data, elemRef, addlData, WysiwygColumnsMediumComponent);
		}
	},
	{
		/// WYSIWYG Columns
		ids: [
			'hypeStoreColumns', // 1 - 63
			'hypeStoreColumnsBravo', // 2 - 102
			'hypeStoreColumnsCharlie', // 3 - 176
			'hypeStoreColumnsDelta', // 4 - 177
			'hypeStoreColumnsEcho', // 5 - 178
			'hypeStoreColumnsFoxtrot', // 6 - 179
			'hypeStoreColumnsGolf', // 7 - 180
			'hypeStoreColumnsHotel', // 8 - 181
			'hypeStoreColumnsIndia', // 9 - 182
			'hypeStoreColumnsJuliet', // 10 - 183
			'hypeStoreColumnsKilo' // 11 - 184
		],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { WysiwygColumnsComponent } = await import('./components/wysiwyg-columns');
			dtlService.loadComponent(data, elemRef, addlData, WysiwygColumnsComponent);
		}
	},
	{
		/// WYSIWYG Details
		ids: [
			'informationalDetailsAlpha', // 1 - 95
			'informationalDetailsBravo', // 2 - 96
			'informationalDetailsCharlie', // 3 - 97
			'informationalDetailsDelta', // 4 - 98
			'informationalDetailsEcho', // 5 - 99
			'informationalDetailsFoxtrot', // 6 - NA
			'informationalDetailsGolf', // 7 - NA
			'informationalDetailsHotel', // 8 - NA
			'informationalDetailsIndia', // 9 - NA
			'informationalDetailsJuliet', // 10 - NA
			'informationalDetailsKilo', // 11 - NA
			'informationalDetailsLima' // 12 - NA
		],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { WysiwygDetailsComponent } = await import('./components/wysiwyg-details');
			dtlService.loadComponent(data, elemRef, addlData, WysiwygDetailsComponent);
		}
	},
	{
		/// WYSIWYG Details
		ids: ['videoHero'],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { VideoHeroComponent } = await import('./components/video-hero');
			dtlService.loadComponent(data, elemRef, addlData, VideoHeroComponent);
		}
	},
	{
		ids: ['looksWeLove'],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { LooksWeLoveComponent } = await import('./components/looks-we-love');
			dtlService.loadComponent(data, elemRef, addlData, LooksWeLoveComponent);
		}
	},
	{
		ids: ['tabbingSection'], // 1 - 207
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { TabbingComponent } = await import('./components/tabbing');
			dtlService.loadComponent(data, elemRef, addlData, TabbingComponent);
		}
	},
	{
		ids: [
			'fullWidthStory', // 1 - ?
			'fullWidthStoryAlpha', // 2 - 209
			'fullWidthStoryBravo', // 3 - 210
			'fullWidthStoryCharlie', // 4 - 211
			'fullWidthStoryDelta', // 5 - 219
			'fullWidthStoryEcho', // 6 - 234
			'fullWidthStoryFoxtrot', // 7 - 235
			'fullWidthStoryGolf', // 8 - NA
			'fullWidthStoryHotel', // 9 - NA
			'fullWidthStoryIndia' // 10 - NA
		],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { StoryV4Component } = await import('./components/story/story-v4');
			dtlService.loadComponent(data, elemRef, addlData, StoryV4Component);
		}
	},
	{
		ids: [
			'signInButton' // 1 - ?
		],
		loader: async (
			dtlService: DynamicTemplateLoader,
			data: any,
			elemRef: ViewContainerRef,
			addlData: any
		) => {
			const { SignInComponent } = await import('./components/sign-in');
			dtlService.loadComponent(data, elemRef, addlData, SignInComponent);
		}
	}
];

export const ComponentBindings = () => {
	const result = {};

	bindingFunctions.forEach((bf) => {
		bf.ids.forEach((component) => {
			result[component] = bf.loader;
		});
	});

	return result;
};
