<!-- @format -->

<div
	class="static-container"
	[ngStyle]="{ 'background-color': backgroundColor }">
	<div class="static-wrapper">
		<!-- No Content Display -->
		<div
			*ngIf="showNoContent"
			#templateOutlet>
			<home-no-content [chain]="chain"></home-no-content>
		</div>

		<!-- Standard Content Display -->
		<ng-container *ngIf="!showNoContent">
			<div *ngIf="shouldPageHeaderBeRemoved">
				<base target="_parent" />
			</div>
			<home-page-header
				*ngIf="!shouldPageHeaderBeRemoved"
				[pageTitle]="pageTitle"></home-page-header>
			<div
				#templateOutlet
				class="static-content"
				[isStaticPage]="true"></div>
		</ng-container>
	</div>
</div>
