/* eslint-disable no-useless-escape */
/** @format */

import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { sha256 } from 'js-sha256';
import { v4 as uuidv4 } from 'uuid';
import { Chain } from '../model/chain.model';
import { DCSG_CONSTANTS } from '../properties/dcsg-constants';
import { WINDOW } from './window.service';

/** @dynamic */
@Injectable({
	providedIn: 'root'
})
export class UtilityService {
	readonly ERROR_MESSAGES: any = {
		geolocation: {
			geolocationUnavailable: 'Geolocation API is not available in this browser'
		}
	};

	/**
	 * @description
	 * Functions which should have their execution delayed / deferred
	 */
	private deferredFunctions = [];

	constructor(
		private readonly chain: Chain,
		@Inject(DOCUMENT) private readonly document: Document,
		@Inject(PLATFORM_ID) protected platformId: string,
		@Inject(WINDOW) private readonly window: Window
	) {
		// Check to see if current context is the browser
		/* istanbul ignore else */
		if (isPlatformBrowser(this.platformId)) {
			// Set up an interval to poll the document.readyState value
			const interval = setInterval(() => {
				// Check to see if the document has completed loading
				if (this.document.readyState === 'complete') {
					// Document has completed loading, clear the polling interval
					clearInterval(interval);

					// Execute all of the deferred functions
					this.executeDeferredFunctions();
				}
			}, 100);
		}
	}

	/**
	 * @description
	 * Accepts a string to be run through regex and returned
	 */
	static cleanString(value, pattern?: string | RegExp, flags = 'gi'): string {
		if (typeof value === 'undefined') {
			return '';
		}

		// Use provided pattern
		if (typeof pattern !== 'undefined') {
			return value.replace(new RegExp(pattern, flags), '');
		}

		// Default to stripping all special characters
		return value.replace(/\s|\!|\@|\#|\$|\%|\^|\*|\(|\)|\{|\}|\[|\]|\;|\'|\"|\,|<|>|\.|\/|\\|\||\?|\_|\+|\=|\`|\~|\‘|\’|\“|\”|\…|\′|\-/g, '');
	}

	static matchObjectArrayParameter(arr1: any[], obj: any, property: string) {
		let result = false;
		for (const a in arr1) {
			if (arr1[a][property] === obj[property]) {
				result = true;
			}
		}
		return result;
	}

	static mapValuesFromOneArrayToAnother(master: any[], slave: any, comparator: string) {
		for (const a in master) {
			if (master[a][comparator]) {
				for (const b in slave) {
					if (slave[b][comparator] === master[a][comparator]) {
						for (const key in Object.keys(master[a])) {
							if (key) {
								const keyName = Object.keys(master[a])[key];
								slave[b][keyName] = master[a][keyName];
							}
						}
					}
				}
			}
		}
		return slave;
	}

	/**
	 * @description
	 * Accepts a string and returns it with diacritics replaced
	 * @param value string to be run through
	 * @param normalizationForm normalization form named by as specified in Unicode Standard Annex #15
	 * @return value string without diacritics, e.g. éèêëçñēčŭ => eeeecnecu
	 */
	removeStringDiacritics(
		value: string,
		normalizationForm: 'NFC' | 'NFD' | 'NFKC' | 'NKFD' = 'NFC'
	): string {
		if (typeof value === 'undefined') {
			return '';
		}

		return value.normalize(normalizationForm).replace(/[\u0300-\u036f]/g, '');
	}

	target(link: string) {
		if (link) {
			return link.indexOf('://') > 0 || link.indexOf('//') === 0 ? '_blank' : '_self';
		} else {
			return '_self';
		}
	}

	/**
	 * @param rating - The average star rating
	 *
	 * @description
	 * Creates array of star ratings
	 */
	getRatings(rating) {
		const ratings: string[] = ['star_border', 'star_border', 'star_border', 'star_border', 'star_border'];

		rating = rating.toFixed(1);

		const digits = `${rating}`.split('.');

		for (let i = 0; i < parseInt(digits[0], 10); i += 1) {
			ratings[i] = 'star';
		}

		if (digits[1] && parseInt(digits[1], 10) !== 0 && parseInt(digits[1], 10) >= 4) {
			const index = ratings.indexOf('star_border');
			if (index !== -1) {
				ratings[index] = 'star_half';
			}
		}

		return ratings;
	}

	/**
	 * @returns The new Date
	 *
	 * @description
	 * Adds a specified number of days to a given Date and returns a new Date
	 */
	addDaysToDate(date, days): Date {
		// Create a new Date based on the given Date
		const newDate = new Date(date);

		// Add the specified number od days
		newDate.setDate(newDate.getDate() + days);

		// Return the new Date
		return newDate;
	}

	/**
	 * Clears identical aria labels from sibling elements
	 * @param currentElement Target element
	 * @param selectedStringRegExp Regex for us to match sibling aria labels
	 * @param defaultSiblingLabel Label all sibling elements will be set to
	 */
	clearSiblingElementAriaLabel(currentElement: HTMLElement, selectedStringRegExp: RegExp | string, defaultSiblingLabel = ''): void {
		if (!currentElement.parentElement) {
			return;
		}
		const parentElement: HTMLElement = currentElement.parentElement;
		const childElementCount = parentElement.childElementCount || -1;
		// HTMLElement is not iterable via a forEach, need to use a for loop here
		for (let i = 0; i <= childElementCount; i++) {
			if (parentElement.children[i] !== currentElement && typeof parentElement.children[i] === 'object') {
				const currentLabel = parentElement.children[i].getAttribute('aria-label');

				if (selectedStringRegExp instanceof RegExp && selectedStringRegExp.test(currentLabel)) {
					parentElement.children[i].setAttribute('aria-label', defaultSiblingLabel);
				} else if (typeof selectedStringRegExp === 'string' && currentLabel === selectedStringRegExp) {
					parentElement.children[i].setAttribute('aria-label', defaultSiblingLabel);
				}
			}
		}
	}

	/**
	 * @description
	 * Converts an unformated time into a formted time
	 * input: 17:00
	 * output: 5:00 pm
	 * @param timeString
	 */
	convertTime(timeString: string | number | any) {
		if (timeString !== undefined) {
			let ampm = 'am';
			let formattedTime;
			const rawTime = this.convertToNumber(timeString);

			// if user provided 0 just return no formatting necessary
			if (rawTime === 0) {
				return;
			}
			if (rawTime > 12) {
				ampm = 'pm';
				formattedTime = rawTime - 12;
			} else {
				formattedTime = rawTime;
			}
			return `${formattedTime} ${ampm.toUpperCase()}`;
		}
	}

	/**
	 * @description
	 * Accepts an argument and attempts to convert its value to a number
	 */
	convertToNumber(number: string | number | boolean, radix = 10): number | undefined {
		// Return number
		let returnNumber;

		// Check to see if argument is a string
		if (typeof number === 'string' && number.trim() !== '') {
			// Attempt to parse the string
			try {
				let parsedNumber;
				if (number.split('.')[1] !== undefined) {
					parsedNumber = parseFloat(number);
				} else {
					parsedNumber = parseInt(number, radix);
				}

				// Ensure NaN did not occur
				if (!isNaN(parsedNumber)) {
					// Set the number
					returnNumber = parsedNumber;
				}
			// eslint-disable-next-line no-empty
			} catch (error) {}
		} else if (typeof number === 'number') {
			returnNumber = number;
		} else if (typeof number === 'boolean' && number) {
			returnNumber = 1;
		} else if (typeof number === 'boolean' && !number) {
			returnNumber = 0;
		} else {
			// Return NaN because we don't know what to do with it
			returnNumber = NaN;
		}

		// Return the number
		return returnNumber;
	}

	/**
	 * @description
	 * Checks to see if document is complete and will either queue the given function or execute immediately if document
	 * is already complete
	 *
	 * @param deferredFunction - The function which needs executed
	 */
	deferFunctionToDocumentReady(deferredFunction: any): void {
		// Check to see if current context is the browser
		/* istanbul ignore else */
		if (isPlatformBrowser(this.platformId) && typeof deferredFunction === 'function') {
			// Check to see if the document is already complete
			if (this.document.readyState === 'complete') {
				// Execute the function
				deferredFunction();
			} else {
				// Add the function to the Array
				this.deferredFunctions.push(deferredFunction);
			}
		}
	}

	/**
	 * @description
	 * Executes an array of deferred functions if they are queued
	 */
	executeDeferredFunctions(): void {
		// Check to see if there are any functions to execute
		if (Array.isArray(this.deferredFunctions) && this.deferredFunctions.length > 0) {
			this.deferredFunctions.forEach((deferredFunction) => {
				// Execute the function
				deferredFunction();
			});
		}
	}

	/**
	 * @description
	 * Returns a date with the timezone adjusted
	 * @param date
	 */
	fetchTimeZoneAdjustedDate(date: Date) {
		// move to eastern time zone
		const timeZoneOffset = 5;
		return new Date(date.setHours(date.getHours() + timeZoneOffset));
	}

	/**
	 * @returns A RFC4122 v4 UUID/GUID string
	 *
	 * @description
	 * Method that generates a high-fidelity UUID/GUID. It uses an NPM module `uuid` to generate a RFC4122 v4
	 * UUID/GUID string.
	 */
	generateUUID(): string {
		// Return UUID/GUID
		return uuidv4();
	}

	/**
	 * @returns true or false if the input string is a UUID
	 *
	 * @description
	 * Method that validates whether a string is in a valid UUID format. It uses a regex to validate the string.
	 */
	isValidUUID(value: string): boolean {
		if (value.length > 0 && !(/^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/).test(value)) {
			return false;
		}

		return true;
	}

	/**
	 * @description
	 * Attempts to get the current geolocation position
	 *
	 * @returns The current geolocation if available, otherwise undefined
	 */
	getCurrentGeolocationPosition(): Observable<GeolocationPosition> {
		// Return an Observable
		return new Observable((observer) => {
			// Check to ensure that gelocation API is available in the browser
			if (this.window.navigator.geolocation) {
				// Attempt to get the current location
				this.window.navigator.geolocation.getCurrentPosition(
					(currentPosition: GeolocationPosition) => {
						// Return the current location
						observer.next(currentPosition);
						observer.complete();
					},
					(error) => {
						console.error('UtilityService.getCurrentGeolocationPosition -- Unable to get current geolocation position: ', JSON.stringify(error, ['message']));

						// Throw error
						observer.error(new Error(JSON.stringify(error, ['message'])));
					}
				);
			} else {
				console.warn('UtilityService.getCurrentGeolocationPosition -- Geolocation API is not available in this browser!');

				// Throw error
				observer.error(new Error(this.ERROR_MESSAGES.geolocation.geolocationUnavailable));
			}
		});
	}

	/**
	 * @description
	 * Accepts an Element and gets its complete height
	 */
	getElementHeight(element: Element, includeTopMarginAndPadding = false, includeBottomMarginAndPadding = false): number {
		// Get the current computed styles
		const currentComputedStyles = this.window.getComputedStyle(element, null);

		// Set the initial height
		let height = parseInt(currentComputedStyles.getPropertyValue('height').replace('px', ''), 10);

		// Check to see if top margins should be included
		if (includeTopMarginAndPadding) {
			height += parseInt(currentComputedStyles.getPropertyValue('margin-top').replace('px', ''), 10);
			height += parseInt(currentComputedStyles.getPropertyValue('padding-top').replace('px', ''), 10);
		}

		// Check to see if top margins should be included
		if (includeBottomMarginAndPadding) {
			height += parseInt(currentComputedStyles.getPropertyValue('margin-bottom').replace('px', ''), 10);
			height += parseInt(currentComputedStyles.getPropertyValue('padding-bottom').replace('px', ''), 10);
		}

		// Return the element height
		return height;
	}

	/**
	 * @description
	 * Handle Http operation that failed. Let the app continue.
	 */
	handleError(error: HttpErrorResponse): Observable<never> {
		// return an error Observable with a user-facing error message
		return throwError(new Error(DCSG_CONSTANTS.errorHandling.messagePrefixes.global.generic));
	}

	/**
	 * @description
	 * Accepts a string and hashes it using SHA-256 algorithm
	 */
	hashString(stringToHash: string): string {
		// Ensure the argument is a valid string
		if (typeof stringToHash === 'string' && stringToHash.trim() !== '') {
			const hash = sha256.create();

			stringToHash = stringToHash.trim().toLowerCase();

			hash.update(stringToHash);

			return hash.hex().toUpperCase();
		}

		// Return empty string by default
		return '';
	}

	/**
	 * @description
	 * Accepts a CSS selector, CSS rules, and inserts them into the DOM
	 */
	insertNewStyleSheetRules(selector: string, rules: string): void {
		const styleSheet = this.document.createElement('style');
		// Set the `media` attribute
		styleSheet.setAttribute('media', 'all');
		// Add the <style> element to the page
		this.document.head.appendChild(styleSheet);
		// New stylesheet as CSSStyleSheet
		const sheet = styleSheet.sheet;
		// Check to see if insertRule or addRule is supported
		if ('insertRule' in sheet) {
			sheet.insertRule(`${selector} { ${rules} }`);
		}
	}

	/**
	 * @description
	 * Checks end date is valid
	 * @param endDate
	 */
	isEndDateValid(endDate: any) {
		if (endDate === undefined) {
			return false;
		}
		const now = new Date();
		const end = new Date(endDate);
		if (now.getTime() <= this.fetchTimeZoneAdjustedDate(end).getTime()) {
			return true;
		}
		return false;
	}

	/**
	 * @description
	 * Checks to see if the first date string is before the second date string
	 *
	 * @param stringDate1
	 * @param stringDate2
	 */
	isFirstDateBeforeSecond(stringDate1: string, stringDate2: string): boolean {
		const date1 = new Date(`${stringDate1}`);
		const date2 = new Date(`${stringDate2}`);

		if (date1 < date2) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * @description
	 * Accepts a string and an array of strings and checks to see if the string exists in the array
	 */
	isItemInList(itemToCheck: string, itemsToCheckAgainst: string[]): boolean {
		// Ensure there is enough to compare
		if (typeof itemToCheck === 'string' && itemToCheck.trim() !== '' && Array.isArray(itemsToCheckAgainst) && itemsToCheckAgainst.length > 0) {
			// Lowercase the string to check for comparison
			itemToCheck = itemToCheck.toLowerCase();

			// Iterate the items to check against
			for (let index = 0, length = itemsToCheckAgainst.length; index < length; index += 1) {
				if (typeof itemsToCheckAgainst[index] === 'string' && itemsToCheckAgainst[index].toLowerCase() === itemToCheck) {
					// Return match found
					return true;
				}
			}
		}

		// There was not a match, return false
		return false;
	}

	/**
	 * @description
	 * Checks if index is the last in an array
	 *
	 * @param itemIndex
	 * @param list
	 */
	isLastIndexInList(itemIndex: number, list: any[]): boolean {
		return itemIndex === list.length - 1;
	}

	/**
	 * @description
	 * Checks if start date is valid
	 * @param startDate
	 */
	isStartDateValid(startDate: Date | string) {
		if (startDate === undefined) {
			return false;
		}
		const now = new Date();
		const start = new Date(startDate);
		if (now.getTime() >= this.fetchTimeZoneAdjustedDate(start).getTime()) {
			return true;
		}
		return false;
	}

	/**
	 * @description
	 * Method that checks whether a given argument is 'truthy' which means it will check multiple types and values which
	 * would evaluate to `true`. It will return `false` by default.  NOTE: We use lodash to check for Integer instead of
	 * Number because `Number.MAX_VALUE` and `Infinity` would both return `true` for `lodash.isNumber()` when we are strictly
	 * checking for the argument to equal the Integer '1', so this helps avoid an unnecessary check.
	 */
	isTruthy(inArgument: any): boolean {
		// Default flag to false
		let returnBoolean = false;

		// Test for String
		if (typeof inArgument === 'string') {
			// Normalize the String
			inArgument = inArgument.toLowerCase().trim();

			// Check the value
			if (inArgument === 'true') {
				returnBoolean = true;
			} else if (inArgument === 'yes') {
				returnBoolean = true;
			} else if (inArgument === 'y') {
				returnBoolean = true;
			} else if (inArgument === '1') {
				returnBoolean = true;
			}
			// Test for Boolean
		} else if (typeof inArgument === 'boolean') {
			// Check the value
			returnBoolean = inArgument === true;
			// Test for Integer
		} else if (typeof inArgument === 'number') {
			// Check the value
			returnBoolean = inArgument === 1;
			// Test for Object; NOTE: "null" returns a typeof as "object" so we need to be careful here
		} else if (typeof inArgument === 'object' && inArgument !== null) {
			if (Array.isArray(inArgument)) {
				returnBoolean = inArgument.length > 0;
			} else {
				returnBoolean = Object.keys(inArgument).length > 0;
			}
		}

		// Return flag
		return returnBoolean;
	}

	/**
	 * @description
	 * Accepts a string and determines if it is of type string and not empty
	 */
	isValidString(stringToCheck): boolean {
		// Return value
		let returnValue = false;

		// Check the string
		if (typeof stringToCheck === 'string' && stringToCheck.trim().length > 0) {
			// Flip the flag
			returnValue = true;
		}

		// Return the return value
		return returnValue;
	}

	/**
	 * @description
	 * Loads a specified style sheet into the head of the DOM.  If a before argument is provided, it will insert before a
	 * specified node by ID otherwise will default to the first script element.  If a media argument is provided,
	 * it will add the media attribute of that type.
	 *
	 * @param href - The URL of the stylesheet
	 * @param id - The ID of the stylesheet
	 * @param [before] - The HTML Element to insert the stylesheet before
	 * @param [callback] - Callback function to execute once the stylesheet is defined
	 * @param [media] - The media type to apply the stylesheet for
	 */
	/* istanbul ignore next */
	loadStyleSheet(href: string, id: string, before?: HTMLElement, callback?: any, media?: string) {
		if (!isPlatformBrowser(this.platformId)) {
			return;
		}
		const DOMElement = this.document.getElementById(id);

		if (DOMElement) {
			return;
		}

		// Fallback
		callback =
			callback ||
			function() {
				// Empty callback
			};

		// Create a stylesheet link element
		const styleSheetLink = this.document.createElement('link');

		// Get the reference element to insert before
		const referenceElement = before || this.document.getElementsByTagName('script')[0];

		// Get all of the style sheets
		const sheets = this.window.document.styleSheets;

		styleSheetLink.id = `${id}`;

		// Set the `rel` attribute to 'stylesheet'
		styleSheetLink.rel = 'stylesheet';

		// Set the `href` attribute
		styleSheetLink.href = href;

		// temporarily, set the `media` attribute to something non-matching to ensure it'll fetch without blocking render
		styleSheetLink.media = 'only x';

		// Set up an interval to poll the document.readyState value
		const interval = setInterval(() => {
			// Check to see if the document has completed loading
			if (this.document.readyState === 'complete') {
				// Document has completed loading, clear the polling interval
				clearInterval(interval);

				// Check to see if there is a valid reference element
				if (typeof referenceElement !== 'undefined' && referenceElement !== null && typeof referenceElement.parentNode !== 'undefined' && referenceElement.parentNode !== null) {
					// Insert the style sheet before the reference
					referenceElement.parentNode.insertBefore(styleSheetLink, referenceElement);
				} else {
					this.document.head.appendChild(styleSheetLink);
				}
			}
		}, 100);

		/*
		 * This function sets the link's media back to `all` so that the stylesheet applies once it loads.
		 * It is designed to poll until document.styleSheets includes the new sheet.
		 */
		styleSheetLink.onloadcssdefined = (cb) => {
			// Flag to determine if the stylesheet is defined in the DOM
			let defined;

			// Iterate through the stylesheets
			for (let i = 0, length = sheets.length; i < length; i++) {
				// Check to see if the current stylesheet href matches ours
				if (sheets[i].href && sheets[i].href === styleSheetLink.href) {
					// Set the flag to true
					defined = true;
				}
			}

			// Check to see if the new stylesheet is defined in the DOM
			if (defined) {
				// Perform the callback
				cb();
			} else {
				// Set a small timeout
				setTimeout(() => {
					// Poll the stylesheets
					styleSheetLink.onloadcssdefined(cb);
				}, 50);
			}
		};

		// Call the onload function with a callback to set the media type so that the stylesheet applies
		styleSheetLink.onloadcssdefined(() => {
			// Set the `media` attribute to a matching value
			styleSheetLink.media = media || 'screen';

			// Call the callback function
			callback();
		});
	}

	/**
	 * @description
	 * Loads an external JavaScript file into the DOM and will then call an optionally supplied callback if provided
	 *
	 * @param url - URL of the script
	 * @param [callback] - Optional callback function
	 */
	/* istanbul ignore next */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	loadExternalScript(url: string, onLoadCallback: any = () => {}, onErrorCallback: any = () => {}, appendHead = false, isAnonymous = true): void {
		// Create a new <script> Element
		const script: any = this.document.createElement('script');

		// Set the `async` attribute
		script.setAttribute('async', '');

		// Set the `type` attribute
		script.setAttribute('type', 'text/javascript');

		// Set 'crossOrigin' attribute
		if (isAnonymous) {
			script.setAttribute('crossOrigin', 'anonymous');
		}

		// IE check
		if (script.readyState) {
			// Define onreadystatechange
			script.onreadystatechange = () => {
				// Check the status
				if (script.readyState === 'loaded' || script.readyState === 'complete') {
					// Null out the reference
					script.onreadystatechange = null;

					// Call the callback
					onLoadCallback();
				}
			};
		} else {
			// Other browsers
			script.onload = () => {
				// Call the onLoadCallback
				onLoadCallback();
			};
		}

		script.onerror = () => {
			// Call error callback
			onErrorCallback();
		};

		// Set the `src` attribute
		script.setAttribute('src', url);

		// Append the Element to the bottom of the body
		this.document.body.appendChild(script);

		if (appendHead) {
			this.document.head.appendChild(script);
		} else {
			// Append the Element to the bottom of the body
			this.document.body.appendChild(script);
		}
	}

	/**
	 * @description
	 * Loads an external JavaScript file into the DOM and will then call an optionally supplied callback if provided
	 *
	 * @param url - URL of the script
	 * @param [onLoadCallback] - Optional onLoadCallback function
	 * @param [onErrorCallback] - Optional onErrorCallback function
	 */
	/* istanbul ignore next */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	loadExternalScriptWithIntegrity(url: string, integrity: string, onLoadCallback: any = () => {}, onErrorCallback: any = () => {}, appendHead = false): void {
		// Create a new <script> Element
		const script: any = this.document.createElement('script');

		// Set the `async` attribute
		script.setAttribute('async', '');

		// Set the `type` attribute
		script.setAttribute('type', 'text/javascript');

		// Sett 'crossOrigin' attribute
		script.setAttribute('crossOrigin', 'anonymous');

		// IE check
		if (script.readyState) {
			// Define onreadystatechange
			script.onreadystatechange = () => {
				// Check the status
				if (script.readyState === 'loaded' || script.readyState === 'complete') {
					// Null out the reference
					script.onreadystatechange = null;

					// Call the onLoadCallback
					onLoadCallback();
				}
			};
		} else {
			// Other browsers
			script.onload = () => {
				// Call the onLoadCallback
				onLoadCallback();
			};
		}

		script.onerror = () => {
			// Call error callback
			onErrorCallback();
		};

		// Set the `src` attribute
		script.setAttribute('src', url);

		// set integrity
		script.setAttribute('integrity', integrity);

		if (appendHead) {
			this.document.head.appendChild(script);
		} else {
			// Append the Element to the bottom of the body
			this.document.body.appendChild(script);
		}
	}

	/**
	 * @description
	 * Loads an external JavaScript file into the DOM and will then call an optionally supplied callback if provided
	 *
	 * @param url - URL of the script
	 * @param [onLoadCallback] - Optional onLoadCallback function
	 * @param [onErrorCallback] - Optional onErrorCallback function
	 * @param [onTimeoutCallback] - Optional onTimeoutCallback function
	 * @param [timeoutMilliseconds] - Optional timeout in milliseconds
	 */
	/* istanbul ignore next */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	public loadExternalScriptWithIntegrityAndTimeout(url: string, integrity: string, onLoadCallback: any = () => {}, onErrorCallback: any = () => {}, onTimeoutCallback: any = () => {}, appendHead = false, timeoutMilliseconds = 10000): void {
		let timeout = setTimeout(() => {
			onTimeoutCallback();
		}, timeoutMilliseconds);
		
		// Create a new <script> Element
		const script: any = this.document.createElement('script');

		// Set the `async` attribute
		script.setAttribute('async', '');

		// Set the `type` attribute
		script.setAttribute('type', 'text/javascript');

		// Sett 'crossOrigin' attribute
		script.setAttribute('crossOrigin', 'anonymous');

		// IE check
		if (script.readyState) {
			// Define onreadystatechange
			script.onreadystatechange = () => {
				// Check the status
				if (script.readyState === 'loaded' || script.readyState === 'complete') {
					// Null out the reference
					script.onreadystatechange = null;

					clearTimeout(timeout);

					// Call the onLoadCallback
					onLoadCallback();
				}
			};
		} else {
			// Other browsers
			script.onload = () => {
				clearTimeout(timeout);

				// Call the onLoadCallback
				onLoadCallback();
			};
		}

		script.onerror = () => {
			// Call error callback
			onErrorCallback();
		};

		// Set the `src` attribute
		script.setAttribute('src', url);

		// set integrity
		script.setAttribute('integrity', integrity);

		if (appendHead) {
			this.document.head.appendChild(script);
		} else {
			// Append the Element to the bottom of the body
			this.document.body.appendChild(script);
		}
	}

	/**
	 * @desc Attempts to find an SEO token by a given store ID.  If a store specific SEO token does not exist, it will attempt
	 * to fall back on the master store ID.
	 *
	 * @param seoTokenString - The SEO token to parse
	 * @param storeId - The store ID to attempt to get an SEO token for
	 * @param masterStoreId - The master store ID as a fallback
	 *
	 * @returns The parsed SEO token
	 */
	parseSeoTokenByStore(seoTokenString: string, storeId: string, masterStoreId: string): string {
		// Return SEO token
		let seoToken = '';

		// Ensure there is a string to parse
		if (typeof seoTokenString === 'string') {
			// Remove any underscores
			seoTokenString = seoTokenString.replace('_', '');

			// Check the seoToken against the specific store ID
			if (seoTokenString.indexOf(storeId) > -1) {
				seoToken = seoTokenString.replace(storeId, '');
			} else if (seoTokenString.indexOf(masterStoreId) > -1) {
				seoToken = seoTokenString.replace(masterStoreId, '');
			} else {
				seoToken = seoTokenString;
			}
		}

		// Return the token
		return seoToken;
	}

	/**
	 * @description
	 * takes an element and calls scrollIntoView with options
	 */
	scrollToElement(element: HTMLElement, options?: ScrollIntoViewOptions, offset?: number): boolean {
		const defaults = {
			behavior: 'smooth',
			block: 'start',
			inline: 'nearest'
		} as ScrollIntoViewOptions;

		options = options || defaults;

		if (typeof element !== 'undefined' && element !== null) {
			if (offset) {
				const scrollY = this.window.scrollY;
				const elementY = element.getBoundingClientRect().top;

				this.window.scrollTo({
					top: scrollY + elementY - offset,
					behavior: options.behavior
				});

				return true;
			}

			element.scrollIntoView({
				behavior: options.behavior,
				block: options.block,
				inline: options.inline
			});

			return true;
		}

		return false;
	}

	/**
	 * @description
	 * scrolls to the top of the page
	 * supply options to override smooth behavior
	 * @param options?
	 */
	scrollToTop(options?: ScrollToOptions) {
		const defaults = {
			top: 0,
			behavior: 'smooth'
		} as ScrollToOptions;

		options = options || defaults;

		window.scrollTo(options);
	}

	/**
	 * Sets aria-label on clicked element
	 * @param element Target element
	 * @param label aria-label text
	 */
	setAriaLabel(element: HTMLElement, label = ''): void {
		// Check if element exists and if it is in fact a HTML Element
		if (element) {
			element.setAttribute('aria-label', label);
		}
	}

	/**
	 * @description
	 * checks to see if the element is visible in the viewport
	 * @param element
	 */
	isElementInViewPort(element: Element): boolean {
		const { top, bottom, right, left } = element.getBoundingClientRect();
		const height = this.window.innerHeight || this.document.documentElement.clientHeight;
		const width = this.window.innerWidth || this.document.documentElement.clientWidth;

		return top >= 0 && left >= 0 && bottom <= height && right <= width;
	}

	/**
	 * @description
	 * check to see if browser supports IntersectionObserver
	 */
	hasIntersectionObserverSupport(): boolean {
		const hasIntersectionObserver = 'IntersectionObserver' in this.window;
		const userAgent = this.window.navigator.userAgent;
		const matches = userAgent.match(/Edge\/(\d*)\./i);
		const isEdge = !!matches && matches.length > 1;
		const isEdgeVersion16OrBetter = isEdge && !!matches && parseInt(matches[1], 10) > 15;

		return hasIntersectionObserver && (!isEdge || isEdgeVersion16OrBetter);
	}

	/**
	 * @description
	 * Intersection Observer that will unobserve after the element intersection ratio has reached the visibility threshold
	 * * @param {HTMLElement} element - HTML element being obsserved
	 * * @param {Function} callBack - Callback function that will be triggered when element visibilty percentage has met the threshold
	 * * @param {number} [threshold=0.1] - Percentage of element visibility that is needed before callback is triggered (e.g. 0.1 == 10% element visibility)
	 */
	singleViewIntersectionObserver(element: HTMLElement, callBack: () => void, threshold: number | number[] = 0.1): void {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (typeof(threshold) !== 'number') {
						threshold = 0.1;
					}
					if (entry.intersectionRatio >= threshold) {
						// entry.intersectionRatio value is 0 until element visibility reaches the threshold
						callBack();
						observer.unobserve(element);
					}
				});
			},
			{ threshold }
		);

		observer.observe(element);
	}

	/**
	 * @description
	 * check to see if killswitch is enabled.
	 * override takes precedence over window and killswitch if provided
	 * @param killswitchName
	 * @param killSwitch
	 * @param chainIdentifierAbbr
	 */
	checkKillSwitch(killSwitchName: string, killSwitch: any = {}, chainIdentifierAbbr: string): boolean {
		const overrideName = `${killSwitchName}Override`;
		const override = killSwitch[overrideName] !== undefined && killSwitch[overrideName] !== null ? killSwitch[overrideName][chainIdentifierAbbr] : undefined;

		if (override !== undefined) {
			return override;
		} else if (this.window[killSwitchName] !== undefined) {
			return this.window[killSwitchName];
		} else if (killSwitch[killSwitchName] !== undefined) {
			return killSwitch[killSwitchName][chainIdentifierAbbr];
		}

		return false;
	}

	/**
	 * @returns {Boolean}
	 *
	 * @description
	 * Used to check if a property passed from api is array containing only empty object
	 * @example
	 * // isObjectInArrayEmpty([{}],0) returns true
	 */

	isObjectInArrayEmpty<T>(array: T[]): boolean {
		return Object.keys(array[0]).length === 0;
	}

	/**
	 * @returns {Boolean}
	 *
	 * @description
	 * Used to check if the current page is a GG Static Page
	 */

	ggStaticPage(isStagePage: boolean): boolean {
		return isStagePage && this.chain.chainIdentifierAbbr === 'gg';
	}

	convertSizeChartNameToNewFormat(sizeChartName: string): string {
		return `sc-${sizeChartName
			.toLowerCase()
			.replace(/size|chart|content|_/gi, '')
			.replace(' ', '-')}`;
	}

	getMessageFromError(error: any): string {
		try {
			return (error.status ? `${error.status} ` : '') + (error.error ? error.error.message : error.message);
		} catch (buildError) {
			return 'Error extracting message.';
		}
	}

	removeWhiteSpace(str: string) {
		return str.trim().replace(/\s/g,'');
	}

	/**
	 * @description Compares if the passed in date is after the current date.
	 * @param data example format: 08-03-2022
	 * @returns boolean representing if passed in date is greater than current date.
	 */
	isDateAfterNowDate(date: string): boolean {
		const dateArr = date.replace('-', ' ');
		const newDate = new Date(dateArr);
		return newDate.getTime() >= Date.now();
	}
}

