/* istanbul ignore file */
/** @format */

import {
	Compiler,
	Injectable,
	Injector,
	ViewContainerRef,
	ComponentRef
} from '@angular/core';

@Injectable()
export class DynamicTemplateLoader {
	constructor(private injector: Injector, private compiler: Compiler) {}

	/**
	 * Loads component into template ref using the module and component that are passed in.
	 * Each component loaded this way needs to have a module with a resolveComponentFactory function. See the story.module.ts as an example.
	 * @param data - Object that your component takes in, used by engage components to deliver homR content
	 * @param elemRef - the template that the component is anchored to in your component, in the engage views this corresponds to templateRef
	 * @param addlData - Object which you could use to add additional data that is passed into your component, optional
	 * @param module - the dynamically imported module module, see examples in the home app -> app.component-modules.ts:22
	 * @param component - the dynamically imported component module, see examples in the home app -> app.component-modules.ts:22
	 */
	/* istanbul ignore next */
	async loadComponent(data: any, elemRef: ViewContainerRef, addlData: any, component: any) {
		const componentRef: ComponentRef<any> = elemRef.createComponent(component, {injector: this.injector});

		componentRef.instance.data = data;
		componentRef.instance.addlData = addlData;
		componentRef.changeDetectorRef.detectChanges();
	}

	async createModuleAndComponents(elemRef: ViewContainerRef, module: any, component: any, data: object) {
		this.compiler.compileModuleAsync(module).then((moduleFactory) => {
			const moduleRef = moduleFactory.create(this.injector);
			const componentFactory = moduleRef.componentFactoryResolver.resolveComponentFactory(component);
			const { instance } = elemRef.createComponent(componentFactory, 0, moduleRef.injector);
			this.componentInputData(instance, data);
		});
	}

	async createModuleAndReturnComponentRef(elemRef: ViewContainerRef, module: any, component: any, data: object, providers: any[] = null) {
		return this.compiler.compileModuleAsync(module).then((moduleFactory) => {
			const injector = providers ? this.createCustomInjector(providers) : this.injector;
			const moduleRef = moduleFactory.create(injector);
			const componentFactory = moduleRef.componentFactoryResolver.resolveComponentFactory(component);
			const { instance } = elemRef.createComponent(componentFactory, 0, moduleRef.injector);
			this.componentInputData(instance, data);
			return instance;
		});
	}

	private createCustomInjector(providers: any[]): Injector {
		return Injector.create({
			providers,
			parent: this.injector
		});
	}

	private componentInputData(componentRef: any, data: object) {
		for (const key in data) {
			// eslint-disable-next-line no-prototype-builtins
			if (data.hasOwnProperty(key)) {
				componentRef[key] = data[key];
			}
		}
	}
}
