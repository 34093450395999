/** @format */

import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Environment } from '@dcsg-ngx-ecommerce/core/model/environment.model';

import { map, retry } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class CeopsService {
	API_URL: string;
	constructor(
		private http: HttpClient,
		private environment: Environment,
		@Inject(PLATFORM_ID) private platformId: any
	) {
		this.API_URL = this.environment.api.ceops;
	}

	public getScripts$(urlToCheck, chain, retries = 0) {
		const requestUrl = `${this.API_URL}/scripts?chain=${chain}&page=${urlToCheck}${
			isPlatformServer(this.platformId)
				? '&sync=true'
				: this.environment.isLocal
				? '&all=true'
				: ''
		}`;
		return this.http.get(requestUrl).pipe(
			map((response: any) => {
				if (response.scripts && response.scripts.length > 0) {
					response.scripts.forEach((script) => {
						if (script && script.path) {
							script.src = script.path.includes('//')
								? script.path
								: `${this.API_URL}/out${script.path}.min.js?_=${response.version}`;
						}
					});
				}
				return response;
			}),
			retry(retries)
		);
	}
}
