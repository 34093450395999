/** @format */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, retry } from 'rxjs/operators';

import { Environment } from '../model/environment.model';
import { Injectable } from '@angular/core';
import { MediaType } from '@shared/data-access/enums';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class HttpDao {
	private headers: HttpHeaders = new HttpHeaders();

	constructor(
		private environment: Environment,
		private httpClient: HttpClient
	) {}

	public get<T>(url: string, retries = 0, httpOptions: object = {}): Observable<T> {
		this.appendHeaders(httpOptions);
		return this.httpClient.get(url, httpOptions).pipe(
			map((response: any) => {
				return response;
			}),
			retry(retries)
		);
	}

	public patch<T>(url: string, input: object, httpOptions: object = {}): Observable<T> {
		this.appendHeaders(httpOptions);
		return this.httpClient.patch(url, input, httpOptions).pipe(
			map((response: any) => {
				return response;
			})
		);
	}

	public post<T>(url: string, input: object, httpOptions: object = {}): Observable<T> {
		this.appendHeaders(httpOptions);
		return this.httpClient.post(url, input, httpOptions).pipe(
			map((response: any) => {
				return response;
			})
		);
	}

	public put<T>(url: string, input: object, httpOptions: object = {}): Observable<T> {
		this.appendHeaders(httpOptions);
		return this.httpClient.put(url, input, httpOptions).pipe(
			map((response: any) => {
				return response;
			})
		);
	}

	public delete<T>(url: string, httpOptions: object = {}): Observable<T> {
		this.appendHeaders(httpOptions);
		return this.httpClient.delete(url, httpOptions).pipe(
			map((response: any) => {
				return response;
			})
		);
	}

	private appendHeaders(httpOptions: object) {
		let headers = httpOptions['headers'];
		const useApiAuth = httpOptions['useApiAuth'];

		if (!headers) {
			headers = this.headers;
		}

		if (headers instanceof HttpHeaders) {
			headers = headers.set('Content-Type', MediaType.APPLICATION_JSON);

			if (useApiAuth) {
				headers = headers.append('partner_key', this.environment.apiAuthKeys.partnerKey);
				headers = headers.append('secret_key', this.environment.apiAuthKeys.secretKey);
			}
		} else {
			// Create HttpHeaders instance with existing header values from provided object
			headers = new HttpHeaders(headers).set('Content-Type', MediaType.APPLICATION_JSON);

			if (useApiAuth) {
				headers = headers.append('partner_key', this.environment.apiAuthKeys.partnerKey);
				headers = headers.append('secret_key', this.environment.apiAuthKeys.secretKey);
			}
		}

		httpOptions['headers'] = headers;
	}
}
