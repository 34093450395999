/** @format */

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ICart } from '@cart-checkout/data-access/interfaces';
import { AnalyticsService } from '@dcsg-ngx-ecommerce/core/service/analytics.service';
import { WINDOW } from '@dcsg-ngx-ecommerce/core/service/window.service';
import { Logger } from 'libs/dcsg-ngx-ecommerce-log/src/lib/service/logger.service';

/** @dynamic */
@Injectable({
	providedIn: 'root'
})
export class CartAnalyticsService extends AnalyticsService {
	constructor(
		@Inject(DOCUMENT) protected document: HTMLDocument,
		@Inject(WINDOW) protected window: Window,
		protected logger: Logger
	) {
		super(document, window, logger);
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setCartId
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param cartId -- orderId from cart
	 *
	 * @description
	 * Sets the cartId reveived orderId in response from addtocart.
	 */
	public setCartId(cartId: any): void {
		this.window.siteData.CartID = cartId;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setNewCart
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param isNewCart - Whether or not the Cart action resulted in a new Cart
	 *
	 * @description
	 * Sets the boolean for whether the Cart action resulted in a new Cart
	 */
	public setNewCart(isNewCart: boolean): void {
		this.window.siteData.NewCart = `${isNewCart}`;
	}

	/**
	 * @description
	 * Sets the Items Added to the Cart on the Object.
	 *
	 * @param inItemsAddedToCart - Array of Objects added to the Cart
	 */
	public setItemsAddedToCart(inItemsAddedToCart: any[]): void {
		this.window.siteData.ProductsAddedToCart = inItemsAddedToCart;
	}

	/**
	 * @description
	 * Sets the Items Added to the Cart on the Object.
	 *
	 * @param inItemsAddedToCart - Array of Objects added to the Cart
	 */
	public setBasePropsForCart(cart: ICart): void {
		if (this.window.siteData.ProductArray) {
			for (const [index, item] of cart.contents.entries()) {
				this.window.siteData.ProductArray[index].FulfillmentMethod = item.procure.mode;

				if (item.procure.store) {
					this.window.siteData.ProductArray[index].FulfillmentStore = item.procure.store;
				}
			}
		}
		this.window.siteData.Quantity = cart.count;
		this.window.siteData.CartActions = cart.actions;
	}

	/**
	 * @description
	 * set siteData object for empty cart
	 */
	public setEmptyCartProperites() {
		this.window.siteData.CartID = '';
		this.window.siteData.OrderID = '';
		this.window.siteData.PaypalAvailable = false;
		// Totals
		this.window.siteData.MerchandiseSubtotal = '0';
		this.window.siteData.ShippingTotal = '0';
		this.window.siteData.TaxTotal = '0';
		this.window.siteData.TotalOrderDiscount = '0';
		this.window.siteData.OrderTotal = '0';
		this.window.siteData.ShippingDiscountAmount = '0';
		this.window.siteData.ProductArray = [];
		this.window.siteData.OrderLevelPromoCodes = [''];
		this.window.siteData.OrderLevelPromoAmounts = [];
		this.window.siteData.Quantity = '0';
	}
}
