/** @format */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, makeStateKey, TransferState } from '@angular/core';
import { Chain } from '@dcsg-ngx-ecommerce/core/model/chain.model';

import { Environment } from '@dcsg-ngx-ecommerce/core/model/environment.model';
import { Logger } from '@dcsg-ngx-ecommerce/log/service/logger.service';
import { CustomHttpHeaders } from '@shared/data-access/enums';
import { Observable } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class KrakenService {
	readonly KRAKEN_URL: string;
	constructor(
		@Inject(PLATFORM_ID) protected platformId: any,
		private http: HttpClient,
		private state: TransferState,
		private chain: Chain,
		private environment: Environment,
		private logger: Logger
	) {
		this.KRAKEN_URL = this.environment.api.kraken.gatewayUrlProxy.path;
	}

	get(name: string, retries = 0): Observable<string> {
		const ELEMENT_KEY = makeStateKey(
			`kraken-${name}-${this.chain.chainMarketingIdentifierAbbr}`
		);
		const element: any = this.state.get(ELEMENT_KEY, null);

		if (element) {
			return new Observable((o) => {
				o.next(element);
				o.complete();
			});
		}

		const template_url = `${this.KRAKEN_URL}/p/${name}/${this.chain.chainMarketingIdentifierAbbr}`;

		let httpHeaders = new HttpHeaders({
			'X-TIMEOUT': '3000'
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true');

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders,
			responseType: 'text' as const
		};

		return this.http.get(template_url, httpOptions).pipe(
			map((response: any) => {
				this.state.set(ELEMENT_KEY, response);
				return response;
			}),
			retry(retries),
			catchError((error: any) => {
				this.logger.error('Error getting data from kraken', error);
				return new Observable((o) => {
					o.next(error);
					o.complete();
				});
			})
		);
	}
}
