/** @format */
import {
	CustomHttpHeaders,
	MediaType,
	PersonalizedLineAidTextString
} from '@shared/data-access/enums';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import {
	IATC,
	LaunchConfig,
	MyCustomizations,
	PersonalizationData
} from '@shared/data-access/interfaces';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

import { DCSG_CONSTANTS } from '@dcsg-ngx-ecommerce/core/properties/dcsg-constants';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class CartDao {
	constructor(private http: HttpClient) {}

	public handleError(error: HttpErrorResponse | any) {
		// return an ErrorObservable
		return throwError(error);
	}

	/**
	 * @description
	 * Adds a given SKU to cart
	 */
	public addSkuToCart$(
		sku: number | string,
		quantity: number,
		fulfillmentStore: number | string,
		personalizedData: PersonalizationData,
		customizationData: MyCustomizations,
		retries = 0,
		launchConfig: LaunchConfig,
		isBopl?: boolean,
		zipCode?: string | number,
		isSDD = false
	): Observable<any> {
		// HTTP Params
		let httpParams = new HttpParams().set('qty', `${quantity}`);
		if (isSDD) {
			httpParams = httpParams.set('zipcode', `${zipCode}`);
			httpParams = httpParams.set('sdd', `${isSDD}`);
		}
		// Set bopis/bopl params
		if (typeof fulfillmentStore === 'string' && fulfillmentStore.trim() !== '') {
			if (isBopl) {
				httpParams = httpParams.set('bopl', `${true}`).set('store', `${fulfillmentStore}`);
			} else {
				httpParams = httpParams.set('bopis', `${true}`).set('store', `${fulfillmentStore}`);
			}

			// Custom product ATC
		} else if (typeof customizationData?.MyCustomizerMap?.designId !== 'undefined') {
			// Set param(s)
			httpParams = httpParams.set(
				'mycustomizer',
				`${customizationData.MyCustomizerMap.designId}`
			);
			// Personalized Golf Balls ATC
		} else if (typeof personalizedData?.x_personalizedAttr !== 'undefined') {
			// Default type to TEXT only
			let personalizedType = 'golfball-text';

			const textLines: string[] = [];

			// type NUMBER only
			if (personalizedData.hasNumberLine && !personalizedData.hasTextLine) {
				if (personalizedData.numberLineCharLimit === 1) {
					// Update the type
					personalizedType = 'golfball-single-number';
				} else if (personalizedData.numberLineCharLimit === 2) {
					// Update the type
					personalizedType = 'golfball-double-number';
				}
				// type TEXT & NUMBER
			} else if (personalizedData.hasNumberLine && personalizedData.hasTextLine) {
				// Update the type
				personalizedType = 'golfball-text-number';
			}

			// Number line
			if (personalizedData.hasNumberLine) {
				// Set param(s)
				httpParams = httpParams.set(
					'number',
					`${personalizedData.x_personalizationNumberLine1.trim()}`
				);
			}

			// Text lines
			if (personalizedData.hasTextLine) {
				// Text line 1
				if (
					typeof personalizedData.x_personalizationTextLine1 === 'string' &&
					personalizedData.x_personalizationTextLine1.trim() !== ''
				) {
					// Add text line to Array
					textLines.push(`${personalizedData.x_personalizationTextLine1.trim()}`);
				}

				// Text line 2
				if (
					typeof personalizedData.x_personalizationTextLine2 === 'string' &&
					personalizedData.x_personalizationTextLine2.trim() !== ''
				) {
					// Add text line to Array
					textLines.push(`${personalizedData.x_personalizationTextLine2.trim()}`);
				}

				// Text line 3
				if (
					typeof personalizedData.x_personalizationTextLine3 === 'string' &&
					personalizedData.x_personalizationTextLine3.trim() !== ''
				) {
					// Add text line to Array
					textLines.push(`${personalizedData.x_personalizationTextLine3.trim()}`);
				}
			}

			// type LINEAID
			if (personalizedData.hasLineAid) {
				// Update the type
				personalizedType = 'golfball-lineaid';

				// Default line style parameter value
				let styleValue = 'none';

				// Check the line style
				if (
					personalizedData.x_personalizationLineStyle ===
					PersonalizedLineAidTextString.STRAIGHT
				) {
					// Update line style for StraightLine
					styleValue = 'straight';
				} else if (
					personalizedData.x_personalizationLineStyle ===
					PersonalizedLineAidTextString.TARGET
				) {
					// Update line style for TargetLine
					styleValue = 'target';
				}

				// Set param(s)
				httpParams = httpParams.set('style', `${styleValue}`);
			}

			// Set TYPE param(s)
			httpParams = httpParams.set('personalized', personalizedType);

			// Check to see if there are any text lines
			if (Array.isArray(textLines) && textLines.length > 0) {
				// Iterate the text lines
				textLines.forEach((textLine: string) => {
					// Set param(s)
					httpParams = httpParams.append('text', textLine);
				});
			}
		}

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			Accept: MediaType.WILDCARD
		});

		// Set a specific timeout
		httpHeaders = httpHeaders.set(
			CustomHttpHeaders.X_TIMEOUT,
			`${DCSG_CONSTANTS.timeouts.long}`
		);

		if (launchConfig.isSecureLaunch || launchConfig.isLaunch) {
			httpHeaders = httpHeaders.set(
				launchConfig.cartHeader.headerKey,
				launchConfig.cartHeader.value
			);
		}

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders,
			params: httpParams,
			responseType: 'text' as const,
			withCredentials: true
		};

		return this.http.put(`/api/v1/carts/contents/${sku}`, undefined, httpOptions).pipe(
			map((response: any) => {
				return response;
			}),
			retry(retries),
			catchError(this.handleError)
		);
	}

	public addSkuCollectionToCart$(
		atcObjects: IATC[],
		fulfillmentStore: number | string,
		retries = 0
	): Observable<any> {
		const reqBody = [];
		for (let i = 0, len = atcObjects.length; i < len; ++i) {
			const atcObject = atcObjects[i];
			const item = {
				sku: atcObject.skuId,
				qty: atcObject.quantity,
				procure: {}
			};

			if (
				atcObject.isBopis &&
				typeof fulfillmentStore === 'string' &&
				fulfillmentStore.trim() !== ''
			) {
				item.procure['mode'] = 'BOPIS';
				item.procure['store'] = fulfillmentStore;
			}

			reqBody.push(item);
		}

		const httpHeaders = new HttpHeaders({
			Accept: MediaType.WILDCARD
		});

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders,
			responseType: 'text' as const,
			withCredentials: true
		};

		return this.http.post('/api/v1/carts/contents', reqBody, httpOptions).pipe(
			map((response: any) => {
				return response;
			}),
			retry(retries),
			catchError(this.handleError)
		);
	}
}
