export enum CustomHttpHeaders {
	CHANNEL_ID = 'channelId',
	CHK = 'CHK',
	ENVIRONMENT = 'Env',
	LOYALTY_INCENTIVE_HEADER = 'apply_pii_incentive',
	ORIGIN = 'origin_host',
	POOL_C_SWIMLANE = 'pool-c-swimlane',
	SWIMLANE = 'swimlane',
	WC_TOKEN = 'WCToken',
	WC_TRUSTED_TOKEN = 'WCTrustedToken',
	X_AUTH_COOKIE_REQUIRED = 'X-AUTH-COOKIE-REQUIRED',
	X_AUTH_REQUEST = 'X-AUTH-REQUEST',
	X_AUTH_REQUIRED = 'X-AUTH-REQUIRED',
	X_AUTH_STRATEGY = 'X-AUTH-STRATEGY',
	X_CENTRASITE_KEY = 'x-CentraSite-APIKey',
	X_CHK_MOD = 'X-CHK-MOD',
	X_LOG_REQUEST = 'X-LOG-REQUEST',
	X_PROXY_REQUEST = 'X-PROXY-REQUEST',
	X_QTY_ERROR_HANDLING = 'X-QTY-ERROR_HANDLING',
	X_TIMEOUT = 'X-TIMEOUT'
}
