/** @format */

import { AccountType } from '@shared/data-access/enums';

/**
 * @description
 * Model for a User/Customer
 */
export class Customer {
	/**
	 * @description
	 * The account type for the Customer.  It will either be `registered` or `guest`.
	 */
	public accountType?: AccountType;

	/**
	 * @description
	 * Flag to determine if the Customer is Gold status for ScoreCard
	 */
	public isScoreCardGold?: boolean;

	/**
	 * @description
	 * The Customer's account status
	 */
	public accountStatus?: boolean;

	/**
	 * @description
	 * The Customer's address ID
	 */
	public addressId?: string;

	/**
	 * @description
	 * The Customer's address lines for address
	 */
	public addressLine?: string[];

	/**
	 * @description
	 * The Customer's address type, i.e. Shipping, Billing, ShippingAndBilling
	 */
	public addressType?: string;

	/**
	 * @description
	 * The Customer's city for address
	 */
	public city?: string;

	/**
	 * @description
	 * The Customer's country for address
	 */
	public country?: string;

	/**
	 * @description
	 * The Customer's primary email address
	 */
	public email1?: string;

	/**
	 * @description
	 * The Customer's first name
	 */
	public firstName?: string;

	/**
	 * @description
	 * The Customer's last name
	 */
	public lastName?: string;

	/**
	 * @description
	 * The last time the Customer's account was updated
	 */
	public lastUpdate?: string;

	/**
	 * @description
	 * The Customer's logon ID.  It follows the syntax `${storeId}:${email}`
	 */
	public logonId?: string;

	/**
	 * @description
	 * The Customer's account nickname.  It follows the syntax `${storeId}:${email}`
	 */
	public nickName?: string;

	/**
	 * @description
	 * Flag to determine if the Customer's password has expired
	 */
	public passwordExpired?: boolean;

	/**
	 * @description
	 * The Customer's primary phone number
	 */
	public phone1?: string;

	/**
	 * @description
	 * The Customer's preferred currency
	 */
	public preferredCurrency?: string;

	/**
	 * @description
	 * Flag to determine if this is the Customer's primary account
	 */
	public primary?: boolean;

	/**
	 * @description
	 * The Customer's profile type, i.e. `Consumer`
	 */
	public profileType?: string;

	/**
	 * @description
	 * The Customer's preference for receiving emails.  This is an Array of Objects which gives the preference for
	 * each store by ID, i.e. { storeID: "11201", value: "2" }
	 */
	public receiveEmailPreference?: any;

	/**
	 * @description
	 * Flag to determine if the customer has chosen to receive SMS messages (text messages)
	 *
	 * TODO: Is this reduntant with `receiveSMSPreference`?
	 */
	public receiveSMSNotification?: boolean;

	/**
	 * @description
	 * The Customer's preference for receiving SMS messages (text messages).  This is an Array of Objects which gives the preference for
	 * each store by ID, i.e. { storeID: "11201", value: "2" }
	 */
	public receiveSMSPreference?: any;

	/**
	 * @description
	 * The Customer's registration approval status
	 */
	public registrationApprovalStatus?: string;

	/**
	 * @description
	 * The Customer's registration timestamp
	 */
	public registrationDateTime?: string;

	/**
	 * @description
	 * The Customer's registration status
	 */
	public registrationStatus?: string;

	/**
	 * @description
	 * The Customer's session ID; a V4 UUID
	 */
	public sessionId: string;

	/**
	 * @description
	 * The Customer's state for address
	 */
	public state?: string;

	/**
	 * @description
	 * The Customer's user ID.  For a registered user, this should match the `userId` field for their AuthToken stored
	 * in WCS
	 */
	public userId?: string;

	/**
	 * @description
	 * The Customer's available points
	 */
	public x_acAvailablePoints?: string;

	/**
	 * @description
	 * The Customer's account balance (currency)
	 */
	public x_acBalance?: string;

	/**
	 * @description
	 * The Customer's points balance
	 */
	public x_acBalancePoints?: string;

	/**
	 * @description
	 * The number of deducted points from the points balance
	 */
	public x_acDeductedPoints?: string;

	/**
	 * @description
	 * The Customer's Loyalty ID (Scorecard Number)
	 */
	public x_acNumber?: string;

	/**
	 * @description
	 * The Customer's number of points needed to achieve their next reward
	 */
	public x_acPointsNeeded?: string;

	/**
	 * @description
	 * The Customer's account reward amount currently available
	 */
	public x_acRewardAmt?: string;

	/**
	 * @description
	 * I HAVE NO IDEA
	 */
	public x_field1?: string;

	/**
	 * @description
	 * The Customer's last logged-in session timestamp
	 */
	public x_userLastSession?: string;

	/**
	 * @description
	 * The Customer's ZIP code for address
	 */
	public zipCode?: string;

	/**
	 * @description
	 * The number of sessions the Customer has had the site before their cookies have been cleared
	 */
	public visitCount?: number;

	/**
	 * @description
	 * When the Customer cookie / state expires
	 */
	public expires?: any;

	/**
	 * @description
	 * Will build/populate a Customer from persisted data
	 */
}
