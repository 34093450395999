const imagesRoot = 'https://images.dickssportinggoods.com';

export const prodEnvironmentDefaults: any = {
	api: {
		homr: {
			proxy: {
				path: `/dcsg-ngx-home/homr/v2/api/`,
				timeout: 15000
			},
			url: 'https://www.dickssportinggoods.com/dcsg-ngx-home/homr/v2/api/',
			scene7Url: 'https://dks.scene7.com/is/image/dksfed/',
			scene7UrlCDN: 'https://dks.scene7.com/is/image/dkscdn/'
		},
		reactMarginals: {
			header: {
				AZ: 'https://prod-ecom-responsive-header-page.iso.ap01.pcf.dcsg.com',
				AZ2: 'https://prod-ecom-responsive-header-page.iso.ap02.pcf.dcsg.com',
				AZ3: 'https://prod-ecom-responsive-header-page.iso.ap03.pcf.dcsg.com',
				VS: 'https://prod-ecom-responsive-header-page.iso.ap02.pcf.dcsg.com'
			},
			footer: {
				AZ: 'https://prod-ecom-footer-page.iso.ap01.pcf.dcsg.com',
				AZ2: 'https://prod-ecom-footer-page.iso.ap02.pcf.dcsg.com',
				AZ3: 'https://prod-ecom-footer-page.iso.ap03.pcf.dcsg.com',
				VS: 'https://prod-ecom-footer-page.iso.ap01.pcf.dcsg.com'
			},
			calia: {
				AZ: 'https://prod-calia-navigation.iso.ap01.pcf.dcsg.com',
				AZ2: 'https://prod-calia-navigation.iso.ap02.pcf.dcsg.com',
				AZ3: 'https://prod-calia-navigation.iso.ap03.pcf.dcsg.com',
				VS: 'https://prod-calia-navigation.iso.ap01.pcf.dcsg.com'
			},
			caliaHeaderUrl: 'https://static-search.dickssportinggoods.com/responsive-header',
			caliaFooterUrl: 'https://static-search.dickssportinggoods.com/footer',
			headerUrl: 'https://static-search.dickssportinggoods.com/responsive-header',
			footerUrl: 'https://static-search.dickssportinggoods.com/footer'
		},
		spring: {
			microService: {
				breadcrumb: {
					GCP: 'https://breadcrumbservice.iso.gp01.pcf.dcsg.com/',
					AZ: 'https://breadcrumbservice.iso.ap01.pcf.dcsg.com/',
					AZ2: 'https://breadcrumbservice.iso.ap02.pcf.dcsg.com/',
					AZ3: 'https://breadcrumbservice.iso.ap01.pcf.dcsg.com/',
					VS: 'https://breadcrumbservice.iso.vp01.pcf.dcsg.com/'
				},
				category: {
					GCP: 'https://categoryservice.iso.gp01.pcf.dcsg.com/',
					AZ: 'https://categoryservice.iso.ap01.pcf.dcsg.com/',
					AZ2: 'https://categoryservice.iso.ap02.pcf.dcsg.com/',
					AZ3: 'https://categoryservice.iso.ap01.pcf.dcsg.com/',
					VS: 'https://categoryservice.iso.vp01.pcf.dcsg.com/'
				},
				recommendations: {
					GCP: 'https://prod-ds-recs-details.iso.ap01.pcf.dcsg.com',
					AZ: 'https://prod-ds-recs-details.iso.ap01.pcf.dcsg.com',
					AZ2: 'https://prod-ds-recs-details.iso.ap01.pcf.dcsg.com',
					AZ3: 'https://prod-ds-recs-details.iso.ap03.pcf.dcsg.com',
					VS: 'https://prod-ds-recs-details.iso.ap01.pcf.dcsg.com'
				},
				seoservice: {
					GCP: 'https://active-seoservice.iso.gp01.pcf.dcsg.com/',
					AZ: 'https://active-seoservice.iso.ap01.pcf.dcsg.com/',
					AZ2: 'https://active-seoservice.iso.ap02.pcf.dcsg.com/',
					AZ3: 'https://active-seoservice.iso.ap01.pcf.dcsg.com/',
					VS: 'https://active-seoservice.iso.vp01.pcf.dcsg.com/'
				},
				taxoservice: 'https://browse.dickssportinggoods.com/dcsg-ngx-category/taxo/api/'
			},
			microServiceProxy: {
				recommendations: {
					path: '/api/spring/msvc/ds/v1/',
					timeout: '15000'
				}
			}
		},
		ceops: 'https://ceops.dickssportinggoods.com',
		dataScience: {
			basePath: '/api/spring/msvc/ds/v1/',
			target: '' // TODO: Find out what the prod url is
		}
	},
	images: {
		netstorage: {
			marketing: `${imagesRoot}/marketing/`,
			product: `${imagesRoot}/product/`
		},
		scene7proxy: {
			marketing: `https://dks.scene7.com/is/image/dksfed/`,
			marketingContent: `https://dks.scene7.com/is/content/dksfed/`,
			product: `https://dks.scene7.com/is/image/dkscdn/`
		},
		imageCollections: {
			url: 'https://main-iapi.iso.ap01.pcf.dcsg.com/image-collections/'
		}
	},
	thirdParty: {
		auth0: {
			dsg: {
				enabled: true
			},
			gg: {
				enabled: true
			},
			calia: {
				enabled: false
			},
			vrst: {
				enabled: false
			},
			pl: {
				enabled: true
			},
			g3: {
				enabled: false
			},
			sm: {
				enabled: false
			},
			plf: {
				enabled: false
			}
		}
	},
	baseDomain: {
		dsg: 'https://www.dickssportinggoods.com',
		gg: 'https://www.golfgalaxy.com',
		calia: 'https://www.dickssportinggoods.com',
		vrst: 'https://www.vrst.com',
		pl: 'https://www.publiclands.com',
		mj: 'https://www.moosejaw.com',
		g3: 'https://www.goinggoing.com',
		sm: 'https://www.sportsmatter.org',
		plf: 'https://www.publiclandsfund.org'
	},
	identityServiceKey: {
		partnerKey: 'MyAccount',
		secretKey: 'PsbiPfwWl9inJxvh3fxN'
	},
	feature: {
		homRAudiences: {
			dsg: false,
			gg: false,
			calia: false,
			vrst: false,
			pl: false,
			mj: false,
			g3: false,
			sm: false,
			plf:false
		}
	}
};
