import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, interval, of } from 'rxjs';
import { filter, switchMap, takeWhile } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class CookieService {
	private akamaiExpCookies: string[] = ['akaas_AS_Exp_Desktop', 'akaas_AS_Exp_Mobile', 'akaas_GG_AS_EXP', 'akaas_AS_meta', 'akaas_AS_EXP_DSG'];

	private akamaiExpCookiesByHostname: any = {
		'm.dickssportinggoods.com': 'akaas_AS_Exp_Mobile',
		'm2.dickssportinggoods.com': 'akaas_AS_Exp_Mobile',
		't.dickssportinggoods.com': 'akaas_AS_Exp_Mobile',
		'www.dickssportinggoods.com': 'akaas_AS_Exp_Desktop',
		'aos.dickssportinggoods.com': 'akaas_AS_Exp_Desktop',
		'meta.dickssportinggoods.com': 'akaas_AS_meta',
		'm.golfgalaxy.com': 'akaas_GG_AS_EXP',
		't.golfgalaxy.com': 'akaas_GG_AS_EXP',
		'www.golfgalaxy.com': 'akaas_GG_AS_EXP',
		'aos.golfgalaxy.com': 'akaas_GG_AS_EXP',
		'meta.golfgalaxy.com': 'akaas_GG_AS_EXP'
	};

	// Check for is Browser
	documentIsAccessible = false;

	constructor(@Inject(DOCUMENT) private readonly document: any, @Inject(PLATFORM_ID) protected platformId: any) {
		this.document = document;
		this.documentIsAccessible = isPlatformBrowser(this.platformId);
	}

	/**
	 * @param name - Cookie name
	 * @returns Whether the cookie by name is present
	 */
	check(name): boolean {
		if (!this.documentIsAccessible) {
			return false;
		}
		name = encodeURIComponent(name);
		const regExp = this.getCookieRegExp(name);
		return regExp.test(this.document.cookie);
	}

	/**
	 * @param name - Cookie name
	 * @returns The cookie if document is accessible, else an empty string
	 */
	get(name): any {
		if (this.documentIsAccessible && this.check(name)) {
			name = encodeURIComponent(name);
			const regExp = this.getCookieRegExp(name);
			const result = regExp.exec(this.document.cookie);
			return decodeURIComponent(result[1]);
		} else {
			return '';
		}
	}

	/**
	 * @returns An array of cookies if document is accessible
	 */
	getAll() {
		if (!this.documentIsAccessible) {
			return {};
		}
		const cookies = {};
		const document = this.document;
		if (document.cookie && document.cookie !== '') {
			const split = document.cookie.split(';');
			for (const data of split) {
				const currentCookie = data.split('=');
				currentCookie[0] = currentCookie[0].replace(/^ /, '');
				cookies[decodeURIComponent(currentCookie[0])] = decodeURIComponent(currentCookie[1]);
			}
		}
		return cookies;
	}

	/**
	 * @description
	 * Gets the swimlane value from the Akamai experience cookie if present
	 */
	getSwimlaneValue(hostname?: string): number {
		// Default the swimlane to -1
		let swimlane = -1;

		// experience cookie value
		let expCookieValue;

		// Check hostname
		if (typeof hostname === 'string' && hostname.trim() !== '' && this.check(this.akamaiExpCookiesByHostname[hostname])) {
			// Get cookie by hostname
			expCookieValue = this.get(this.akamaiExpCookiesByHostname[hostname]);
		} else {
			// Iterate through the swimlane cookie names to grab first match
			for (let i = 0, length = this.akamaiExpCookies.length; i < length; i += 1) {
				// Check to see if the current cookie in the list exists
				if (this.check(this.akamaiExpCookies[i])) {
					// Get the value of the current cookie
					expCookieValue = this.get(this.akamaiExpCookies[i]);

					// Break out to not unnecessarily iterate
					break;
				}
			}
		}

		// Ensure there is something to check
		if (typeof expCookieValue === 'string' && expCookieValue.trim() !== '') {
			// Get the RV/swimlane values
			const expCookieValues = /rv=(\d+)/i.exec(expCookieValue);

			// Check to see if there is an RV/swimlane value in the cookie string
			if (Array.isArray(expCookieValues) && expCookieValues.length >= 2 && parseInt(expCookieValues[1], 10)) {
				// Get the swimlane number from the cookie string
				swimlane = parseInt(expCookieValues[1], 10);
			}
		}

		// Return the swimlane
		return swimlane;
	}

	getAllSwimlaneCookies(): any {
		const swimlaneCookies = {};

		// Iterate through the swimlane cookie names
		for (let i = 0, length = this.akamaiExpCookies.length; i < length; i += 1) {
			// Check to see if the current cookie in the list exists
			if (this.check(this.akamaiExpCookies[i])) {
				// Set the cookie name and value
				swimlaneCookies[this.akamaiExpCookies[i]] = this.get(this.akamaiExpCookies[i]);
			}
		}

		// Return all of the possible swimlane cookies
		return swimlaneCookies;
	}

	/**
	 * @param name - Cookie name
	 * @param value - Cookie value
	 * @param [expires] - Number of days until the cookies expires or an actual `Date`
	 * @param [path] - Cookie path
	 * @param [domain] - Cookie domain
	 * @param [secure] - Secure flag
	 */
	set(name, value, expires?, path?, domain?, secure?, maxAge?) {
		if (!this.documentIsAccessible) {
			return false;
		}

		let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)};`;

		/* istanbul ignore else */
		if (expires) {
			/* istanbul ignore else */
			if (typeof expires === 'number') {
				const dateExpires = new Date(new Date().getTime() + expires * 1000 * 60 * 60 * 24);
				cookieString += `expires=${dateExpires.toUTCString()};`;
			} else if (expires instanceof Date) {
				cookieString += `expires=${expires.toUTCString()};`;
			}
		}

		/* istanbul ignore else */
		if (path) {
			cookieString += `path=${path};`;
		}

		/* istanbul ignore else */
		if (domain) {
			cookieString += `domain=${domain};`;
		}

		/* istanbul ignore else */
		if (secure) {
			cookieString += 'secure;';
		}

		if(maxAge) {
			cookieString += `max-age=${maxAge};`;
		}

		this.document.cookie = cookieString;
	}

	/**
	 * @param name - Cookie name
	 * @param path - Cookie path
	 * @param domain - Cookie domain
	 */
	delete(name: string, path: string, domain: string) {
		if (!this.documentIsAccessible) {
			return false;
		}
		this.set(name, '', -1, path, domain, '');
	}

	/**
	 * @param name - Cookie name
	 */
	deleteGeneric(name) {
		if (!this.documentIsAccessible) {
			return false;
		}
		this.set(name, '', -1, '/', this.document.domain, '');
	}

	/**
	 * @param name - Cookie name
	 */
	deleteRegexIfPresent(name) {
		if (!this.documentIsAccessible) {
			return false;
		}
		const value = this.document.cookie.match(name);
		this.set(value, '', -1, '/', this.document.domain, '');
	}

	/**
	 * @param path - Cookie path
	 * @param domain - Cookie domain
	 */
	deleteAll(path: string, domain: string) {
		if (!this.documentIsAccessible) {
			return false;
		}
		const cookies = this.getAll();
		for (const cookieName in cookies) {
			/* istanbul ignore else */
			// eslint-disable-next-line no-prototype-builtins
			if (cookies.hasOwnProperty(cookieName)) {
				this.delete(cookieName, path, domain);
			}
		}
	}

	listenForCookieChanges(cookieName: string): Observable<string> {
		let lastCookieValue = this.get(cookieName); // 'static' memory between function calls
		return new Observable((obs) => {
			const checkCookie = () => {
				const currentCookieValue = this.get(cookieName);

				if (currentCookieValue !== lastCookieValue) {
					lastCookieValue = currentCookieValue; // store latest cookie

					obs.next(lastCookieValue);
				}
			};
			window.setInterval(checkCookie, 1500);
		});
	}

	waitForCookieAvailable(cookieName: string): Observable<string> {
		return interval(500).pipe(
			switchMap(() => {
				const cookieValue = this.get(cookieName) as string;
				return of(cookieValue);
			}),
			filter((cookieValue) => !!cookieValue),
			takeWhile((cookieValue) => !cookieValue, true) //inclusive: true => emits the value that interrupts the stream
		);
	}

	/**
	 * @param name - Cookie name
	 */
	private getCookieRegExp(name: string) {
		// eslint-disable-next-line no-useless-escape
		const escapedName = name.replace(/([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/gi, '\\$1');
		return new RegExp(`(?:^${escapedName}|;\\s*${escapedName})=(.*?)(?:;|$)`, 'g');
	}
}
