/**
 * TypeScript wrapper around `https://github.com/srfrnk/re-tree`
 */
export class ReTree {

	public test(string: string , regex: any): any {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const self = this;

		if (typeof regex === 'string') {
			regex = new RegExp(regex);
		}

		if (regex instanceof RegExp) {
			return regex.test(string);
		} else if (regex && Array.isArray(regex.and)) {
			return regex.and.every((item: any) => {
				return self.test(string, item);
			});
		} else if (regex && Array.isArray(regex.or)) {
			return regex.or.some((item: any) => {
				return self.test(string, item);
			});
		} else if (regex && regex.not) {
			return !self.test(string, regex.not);
		} else {
			return false;
		}
	}

	public exec(string: string, regex: any): any {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const self = this;

		/* istanbul ignore else */
		if (typeof regex === 'string') {
			regex = new RegExp(regex);
		}

		/* istanbul ignore else */
		if (regex instanceof RegExp) {
			return regex.exec(string);
		} else if (regex && Array.isArray(regex)) {
			return regex.reduce((res: any, item: any) => {
				return (res) ? res : self.exec(string, item);
			}, null);
		} else {
			return null;
		}
	}
}
