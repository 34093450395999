/** @format */

// TODO -- This file can be deleted once other Express servers are refactored

const ggAOS = '125';
const dsgAOS = '112';
const plAOS = '162';

const aosCheck = (vhHeader: any): any => {
	let aosKey = '';

	if (typeof vhHeader === 'string') {
		if (vhHeader === dsgAOS) {
			// Is DSG AOS
			aosKey = 'aos-dsg-';
		} else if (vhHeader === ggAOS) {
			// Is GG AOS
			aosKey = 'aos-gg-';
		} else if (vhHeader === plAOS) {
			// Is GG AOS
			aosKey = 'aos-pl-';
		}
	}

	return aosKey;
};

const isVHHeaderAnAOSHeader = (vhHeader: any): boolean => {
	return typeof vhHeader === 'string' && [dsgAOS, ggAOS, plAOS].includes(vhHeader);
};

export { aosCheck, isVHHeaderAnAOSHeader };
