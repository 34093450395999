import { BehaviorSubject, Observable, pipe } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged, filter, pluck } from 'rxjs/operators';
// TODO: Seek alternative to pluck(probably map). This is being deprecated in RXJS 8.

import { IState } from '../model/app.state';

// Default state
const defaultState: IState = {
	AUTHENTICATED: false,
	CART_COUNT: 0,
	CUSTOMER: undefined,
	CROSS_SELLS: undefined,
	CUST_STORE: undefined,
	KILLSWITCH_VALUES: undefined,
	ECOMM_INVENTORY: undefined,
	BOPIS_INVENTORY: undefined,
	ENVIRONMENT_KILLSWITCH_VALUES: undefined,
	SEARCHDEX_RESPONSE: undefined,
	PRIMARY_CATEGORY: undefined,
	ATTRIBUTE_SELECTIONS: undefined,
	CART: undefined,
	CHECKOUT: undefined,
	SECURE_LAUNCH: false,
	HOMR_DATA: undefined,
	SCORECARD_DATA: undefined,
	NEARBY_STORE_COUNT: undefined,
	REWARDS: undefined,
	PERSONAL_INFO: undefined,
	LOYALTY_BALANCE: undefined,
	ACCOUNT_SETTINGS: undefined,
	PURCHASES: undefined,
	USER_PROFILE: undefined,
	USER_SESSION_INVALID: undefined,
	SPC_CARDSTATUS_MAP: undefined,
	BATFITTING_SESSIONS: undefined,
};

export class Store {
	private subject = new BehaviorSubject<IState>(defaultState);

	private store = this.subject.asObservable().pipe(distinctUntilChanged());

	public get value() {
		return this.subject.value;
	}

	public select<T>(name: string, addUndefinedOrNullFilter = false): Observable<T> {
		const ops = [ pluck(name) ];

		if (addUndefinedOrNullFilter) {
			ops.push(filter( (result: T) => {
				return result !== undefined && result !== null;
			}));
		}

		return this.store.pipe(pipe.apply(this, ops));
	}

	public set(name: string, state: any): void {
		this.subject.next({
			...this.value, [name]: state
		});
	}

	public selectDistinctKey<T>(name: string, addUndefinedOrNullFilter = false): Observable<T> {
		const ops = [ distinctUntilKeyChanged(name), pluck(name) ];

		if (addUndefinedOrNullFilter) {
			ops.push(filter( (result: T) => {
				return result !== undefined && result !== null;
			}));
		}

		return this.store.pipe(pipe.apply(this, ops));
	}
}
