/** @format */

import { isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	Component,
	Inject,
	OnDestroy,
	OnInit,
	PLATFORM_ID,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import {
	AnalyticsService,
	Chain,
	CookieService,
	DynamicTemplateLoader,
	HttpService,
	Store,
	WINDOW
} from '@dcsg-ngx-ecommerce/core';
import { TaggingService } from '@dcsg-ngx-ecommerce/engage/service/tagging.service';

import { Subscription } from 'rxjs';
import { ComponentBindings } from '../../../app.component-modules';
import { triggerTargetView } from '@home/utils';
import { MetaService, SeoService } from '@seo/data-access';

@Component({
	selector: 'home-static-component',
	templateUrl: './static.component.html',
	styleUrls: ['./static.component.scss']
})
export class StaticComponent implements AfterViewInit, OnInit, OnDestroy {
	categoryData: any;
	templateData: any;
	categoryId: string;
	emsName: string;
	categoryIdentifier: string;
	queryParameters: any;
	certonaSectionNumber;
	certonaRecommendationsResponse: any;
	pageTitle: string;
	showSidePanel: false;
	public backgroundColor: string;
	public components = ComponentBindings();

	/**
	 * @description
	 * The Object for certona response
	 *
	 * @type {any}
	 */

	@ViewChild('templateOutlet', { read: ViewContainerRef })
	templateOutlet: ViewContainerRef;
	children: any[] = [];
	pageType = 'static';
	certonaArray: any[] = [];
	homrContainer = false;
	searchDexResponse: any;
	flcActive = false;

	/**
	 * @type boolean
	 *
	 * @description
	 * Boolean for displaying featuredSearchdex categories
	 */
	public showFeaturedCategories = false;

	private elementDataSubscription: Subscription;
	private templateLoaderSubscription: Subscription;
	public shouldPageHeaderBeRemoved;
	public showNoContent = false;

	constructor(
		@Inject(PLATFORM_ID) protected platformId: any,
		@Inject(WINDOW) private window: Window,
		private route: ActivatedRoute,
		private taggingService: TaggingService,
		private metaService: MetaService,
		// TODO: private searchdexService: SearchdexService,
		private analyticsService: AnalyticsService,
		private cookieService: CookieService,
		private httpService: HttpService,
		private store: Store,
		private chain: Chain,
		private title: Title,
		private seoService: SeoService,
		private dynamicTemplateLoader: DynamicTemplateLoader
	) {
		this.queryParameters = this.route.snapshot.params;
	}
	/* istanbul ignore next */
	ngOnInit() {
		this.shouldPageHeaderBeRemoved = this.httpService.getParameterValuesFromQueryString(
			window.location.search,
			'turnOffMarginals'
		)
			? true
			: false;
		this.pageTitle = this.queryParameters['seoName'];
		this.route.data.subscribe((data) => {
			this.templateData = data.homrData;
			if (this.templateData) {
				if (this.templateData.backgroundColor) {
					this.backgroundColor = this.templateData.backgroundColor;
				}
				this.pageTitle = this.templateData.pageTitle
					? data.homrData.pageTitle.split('|')[0].toUpperCase()
					: this.pageTitle.toUpperCase();
			} else {
				this.showNoContent = true;
			}
		});
		if (typeof this.window.certonaRecommendations === 'undefined') {
			this.window.DSGShoppingPathJS = {};
			// Assign the Component method to the global Window so that Certona can execute it, passing in `this` as context
			this.window.certonaRecommendations = this.getCertonaRecommendations.bind(this);
			this.window.DSGShoppingPathJS.certonaRecommendations =
				this.getCertonaRecommendations.bind(this);
		}
	}
	/* istanbul ignore next */
	ngAfterViewInit() {
		if (this.templateData) {
			this.getHomrData();
		}
	}
	/* istanbul ignore next */
	ngOnDestroy() {
		if (this.elementDataSubscription) {
			this.elementDataSubscription.unsubscribe();
		}

		if (this.templateLoaderSubscription) {
			this.templateLoaderSubscription.unsubscribe();
		}
	}

	/* istanbul ignore next */
	getHomrData() {
		this.taggingService.setTemplateTag(this.templateData.templateTag);
		this.loadTemplate();
		this.certonaArray = this.templateData.content.Certona;
		this.templateData.content.certonaArray = this.certonaArray;
		this.setPageData(
			this.templateData.staticId,
			this.templateData.includeInSitemap,
			this.templateData.pageTitle,
			this.templateData.metaDescription,
			this.templateData.metaKeywords,
			this.templateData.canonicalName
		);
	}

	/* istanbul ignore next */
	public async loadTemplate() {
		const keys = Object.keys(this.templateData.content);
		for (const k in keys) {
			if (this.templateData.content[keys[k]]) {
				const key = keys[k];
				const data = this.templateData.content[key];
				if (this.components[key]) {
					await this.components[key](
						this.dynamicTemplateLoader,
						data,
						this.templateOutlet,
						{ tagSectionNum: k + 1, widgetTypeTag: key, isStaticPage: true }
					);
				}
			}
		}
	}
	/* istanbul ignore next */
	setPageData(
		staticID: string,
		includeInSitemap: boolean,
		title: string,
		description?: string,
		keywords?: string,
		canonicalName?: string
	) {
		if (!title) {
			title = this.chain.metaTitle;
		}
		const setTitle = `${title.split('|')[0]} | ${this.chain.chainName}`;
		this.title.setTitle(setTitle);
		this.analyticsService.setPageName(title);

		if (canonicalName) {
			this.seoService.addCanonicalTag(canonicalName, 'homR');
		}

		let metaDescription = description || this.chain.metaDescription;

		if (this.chain.chainIdentifierAbbr === 'pl' && !description) {
			metaDescription = `Learn more about ${title} at Public Lands. Get the information you need and make the most of your time in the free outdoors.`;
		}
		this.metaService.addTag({ name: 'description', content: metaDescription }, true);

		if (keywords) {
			this.metaService.addTag({ name: 'keywords', content: keywords }, true);
		}

		if (!includeInSitemap) {
			this.metaService.addTag({ name: 'robots', content: 'noindex' }, true);
		}

		if (staticID) {
			this.analyticsService.setStaticID(staticID);
		}

		if (isPlatformBrowser(this.platformId)) {
			// Set the search redirect Analytics
			const sTerm = this.cookieService.get('sTermCookie');
			if (sTerm) {
				this.analyticsService.setEnteredSearchTerm(sTerm);
				this.analyticsService.setSearchType('Redirect');
			}

			this.analyticsService.triggerAnalyticsEvent('NewPageView');
			triggerTargetView('static--post-content-load');
		}
	}
	/* istanbul ignore next */
	public getCertonaRecommendations(certonaResponse: any) {
		(window as any).certonaResponse = certonaResponse;
		// Set the response on the component
		this.store.set('CERTONA_RESPONSE', certonaResponse);
	}
}
