/** @format */

// eslint-disable-next-line no-shadow
export enum DynamicFooterTemplateModules {
	FOOTER_CALIA = 'footer_calia',
	FOOTER_DEFAULT = 'footer_default',
	FOOTER_DSG = 'footer_dsg',
	FOOTER_PLACEHOLDER = 'footer_placeholder',
	FOOTER_REACT = 'footer_react'
}
