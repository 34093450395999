// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../../../types/typings.d.ts" />

import { ModuleWithProviders, NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';
import { LoggerConfig } from '@logger';

@NgModule({
	imports: [
		HttpClientModule
	]
})
export class DcsgNgxEcommerceLogModule {
	static forRoot(config: LoggerConfig | undefined): ModuleWithProviders<DcsgNgxEcommerceLogModule> {
		return {
			ngModule: DcsgNgxEcommerceLogModule,
			providers: [
				{ provide: LoggerConfig, useValue: config || undefined }
			]
		};
	}
}
