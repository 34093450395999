import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { AuthClaimsEnum } from '../enum/auth/auth-claims.enum';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Chain } from '@dcsg-ngx-ecommerce/core/model/chain.model';
import { CookieService } from '@dcsg-ngx-ecommerce/core/service/cookie.service';
import { HttpService } from '@dcsg-ngx-ecommerce/core/service/http.service';
import { Store } from '@dcsg-ngx-ecommerce/core/persistence/app.store';
import { UtilityService } from '@dcsg-ngx-ecommerce/core/service/utility.service';
import { Environment } from '@dcsg-ngx-ecommerce/core/model/environment.model';

import { DCSG_CONSTANTS } from '@dcsg-ngx-ecommerce/core/properties/dcsg-constants';
import { CreateAccountStateEnum } from '@dcsg-ngx-ecommerce/myaccount/enum/create-account-state.enum';
import { AccountType } from '@shared/data-access/enums';
import { Customer } from '@shared/data-access/interfaces';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	/*
	 * Public
	 */

	/**
	 * @description
	 * Authentication status. Updated via "getIsAuthenticated"
	 */
	public isAuthenticated: boolean;

	/**
	 * @description
	 * Authentication token. Updated via "getTokenSilently"
	 */
	public token: string;

	/*
	 * Private
	 */
	private isPartyAttributesKillSwitchEnabled: boolean;

	constructor(
		private chain: Chain,
		private cookieService: CookieService,
		private httpService: HttpService,
		@Inject(PLATFORM_ID) private platformId,
		private router: Router,
		private store: Store,
		private utilityService: UtilityService,
		private environment: Environment
	) {
		// Initialize variables
		this.isAuthenticated = false;
		this.token = null;

		/*
		 * NOTE: Only perform in the browser!
		 */
		/* istanbul ignore else */
		if (isPlatformBrowser(this.platformId)) {
			// Subscribe to the CUSTOMER state so that the associated cookie(s) can be updated automatically
			this.store.select('CUSTOMER').subscribe((customer: Customer) => {
				// Check to see if defined
				if (typeof customer !== 'undefined') {
					// Set the auth cookies
					this.setAuthCookies(customer);
				}
			});
		}
	}

	/**
	 * @description
	 * Checks to see if there is already a cookie / state available and will use that data if available
	 */
	public checkIfReturningCustomer(): void {
		// Create a temp Customer
		const tempCustomer: Customer = new Customer();

		/*
		 * Use the NGX auth cookie as the primary data, and override from the legacy cookie where necessary
		 */
		// Check to see if NGX auth cookie is present
		/* istanbul ignore else */
		if (this.cookieService.check('DCSG_NGX_CUST')) {
			// Set the Customer
			const ngxCustomerCookie = JSON.parse(this.cookieService.get('DCSG_NGX_CUST'));

			// Get the ScoreCard Gold value from partyAttributes
			let isScoreCardGold = '';
			const attr = JSON.parse(sessionStorage.getItem('partyAttributes'));
			if (attr) {
				const attributes = typeof attr?.partyAttributes === 'string' && JSON.parse(attr?.partyAttributes)?.attributes || [];
				attributes.forEach((attribute) => {
					if (attribute.id === 'gold') {
						isScoreCardGold = attribute.value;
					}
				});
			}

			/* istanbul ignore next */
			if (this.environment.killSwitch && this.environment.killSwitch.isPartyAttributesEnabled) {
				this.isPartyAttributesKillSwitchEnabled = this.environment.killSwitch.isPartyAttributesEnabled[
					this.chain.chainIdentifierAbbr
				];
			}

			// Cookie which determines the ScoreCard Gold tier status
			const loyaltyGoldStatusCookie = this.cookieService.get(DCSG_CONSTANTS.cookies.loyalty.scoreCardGoldStatus.cookieName);

			// Set the properties from the cookie
			tempCustomer.accountType = ngxCustomerCookie.accountType;
			tempCustomer.accountStatus = ngxCustomerCookie.accountStatus;
			tempCustomer.addressId = ngxCustomerCookie.addressId;
			tempCustomer.addressLine = ngxCustomerCookie.addressLine;
			tempCustomer.addressType = ngxCustomerCookie.addressType;
			tempCustomer.city = ngxCustomerCookie.city;
			tempCustomer.country = ngxCustomerCookie.country;
			tempCustomer.email1 = ngxCustomerCookie.email1;
			tempCustomer.firstName = ngxCustomerCookie.firstName;
			tempCustomer.lastName = ngxCustomerCookie.lastName;
			tempCustomer.lastUpdate = ngxCustomerCookie.lastUpdate;
			tempCustomer.logonId = ngxCustomerCookie.logonId;
			tempCustomer.nickName = ngxCustomerCookie.nickName;
			tempCustomer.passwordExpired = ngxCustomerCookie.passwordExpired;
			tempCustomer.phone1 = ngxCustomerCookie.phone1;
			tempCustomer.preferredCurrency = ngxCustomerCookie.preferredCurrency;
			tempCustomer.primary = ngxCustomerCookie.primary;
			tempCustomer.profileType = ngxCustomerCookie.profileType;
			tempCustomer.receiveEmailPreference = ngxCustomerCookie.receiveEmailPreference;
			tempCustomer.receiveSMSNotification = ngxCustomerCookie.receiveSMSNotification;
			tempCustomer.receiveSMSPreference = ngxCustomerCookie.receiveSMSPreference;
			tempCustomer.registrationApprovalStatus = ngxCustomerCookie.registrationApprovalStatus;
			tempCustomer.registrationDateTime = ngxCustomerCookie.registrationDateTime;
			tempCustomer.sessionId = ngxCustomerCookie.sessionId || this.utilityService.generateUUID();
			tempCustomer.state = ngxCustomerCookie.state;
			tempCustomer.userId = ngxCustomerCookie.userId || DCSG_CONSTANTS.guestUserId;
			tempCustomer.x_userLastSession = ngxCustomerCookie.x_userLastSession;
			tempCustomer.zipCode = ngxCustomerCookie.zipCode;
			tempCustomer.visitCount = ngxCustomerCookie.visitCount;
			tempCustomer.expires = ngxCustomerCookie.expires;

			if (this.isPartyAttributesKillSwitchEnabled) {
				tempCustomer.isScoreCardGold = (isScoreCardGold === 'true');
			} else {
				tempCustomer.isScoreCardGold = typeof loyaltyGoldStatusCookie === 'string' && loyaltyGoldStatusCookie.toLowerCase().trim() === DCSG_CONSTANTS.cookies.loyalty.scoreCardGoldStatus.cookieValue.toLowerCase().trim();
			}
		}

		// Set the account type
		tempCustomer.accountType = tempCustomer.accountType || AccountType.GUEST;

		// Set the expires if necessary
		tempCustomer.expires = tempCustomer.expires || this.utilityService.addDaysToDate(new Date(), 30);

		// Update the visit count
		tempCustomer.visitCount = tempCustomer.visitCount > 0 ? tempCustomer.visitCount + 1 : 1;

		// Update the Store
		this.store.set('CUSTOMER', tempCustomer);
	}


	/**
	 * @description
	 * Returns observable with authentication value and updates the
	 * isAuthenticated property on the service if the user is already authenticated
	 */
	public getIsAuthenticated$(): Observable<boolean> {
		// Return the completed stream from the Promise
		return window.authFunctions?.isAuthenticated ?
			from(window.authFunctions.isAuthenticated()).pipe(
				tap((result: boolean) => {
					this.isAuthenticated = result;
				})
			) :
			of(false);
	}

	/**
	 * @description
	 * Returns observable with fresh token value and updates the token
	 * property on the service
	 */
	public getTokenSilently$(): Observable<string> {
		const tokens$ = from(window.authFunctions.getTokens());
		const refreshTokenResponse$: Observable<string> = tokens$.pipe(
			map((token: string) => JSON.parse(token).accessToken),
			catchError(() =>
				tokens$.pipe(
					map((token: string) => `${token && token?.indexOf('Bearer ') === -1 ? 'Bearer ' : ''}${token}`)
				)
			),
			tap((token: string) => this.token = token)
		);

		// Return the completed stream from the Promise
		return refreshTokenResponse$;
	}

	/**
	 * @description
	 * Handles authentication on app entry. Place in app.component.ts
	 * NOTE: This should only be called on app initialization and
	 * sets up local authentication streams
	 */
	public localAuthSetup(): void {
		this.getIsAuthenticated$()
			.subscribe();
	}

	/**
	 * @description
	 * This will redirect athelte to create account page if athelete not a existing athelete or
	 * it will take to the sign in page.
	 * A desired redirect path can be passed to createAccount method (e.g., from a route guard).
	 */

	public createAccount(appState?: any): Observable<void> {
		const route = this.router.url.toLowerCase();
		const isCartOrCheckout = route.includes('orderitemdisplay') || route.includes('singlepagecheckout');

		// if create account exists do that action
		/* istanbul ignore next */
		if (window.authFunctions?.createAccount) {
			window.authFunctions.createAccount(appState?.target ? appState.target : undefined, isCartOrCheckout);
			return new Observable((observer) => { observer.next(null); observer.complete(); });
		}
		return new Observable((observer) => { observer.next(null); observer.complete(); });
	}

	/**
	 * @description
	 * A desired redirect path can be passed to login method (e.g., from a route guard).
	 */
	public login(appState?: any): Observable<void> {
		const route = this.router.url.toLowerCase();
		const isCartOrCheckout = route.includes('orderitemdisplay') || route.includes('singlepagecheckout');

		if (window.authFunctions?.login) {
			window.authFunctions.login(appState?.target ? appState.target : undefined, isCartOrCheckout);
			return new Observable((observer) => {
				observer.next(null);
				observer.complete();
			});
		}
		return new Observable((observer) => {
			observer.next(null);
			observer.complete();
		});
	}

	/**
	 * @description
	 * Triggers Auth0 logout sequence. Will redirect to the url provided in
	 * the IAuth0ClientOptions object
	 */
	public logout(): void {
		window.authFunctions.logout();
	}

	public createAccount$(): Observable<any> {
		const headers = new HttpHeaders({
			'X-TIMEOUT': '15000'
		});

		return this.httpService.post('/api/v1/accounts', {}, { observe: 'response', headers }).pipe(
			map((response: HttpResponse<any>) => {
				if (response.status === 204) {
					return CreateAccountStateEnum.SUCCESS;
				}

				return CreateAccountStateEnum.ERROR;
			}),
			catchError((err) => {
				let result = CreateAccountStateEnum.ERROR;
				if (err.status === 406) {
					result = CreateAccountStateEnum.INVALID_EMAIL;
				}

				return new Observable((observer) => {
					observer.next(result);
					observer.complete();
				});
			})
		);
	}

	/**
	 * @description
	 * returns identityId from token
	 */
	 public async getScoreCardNumber$(): Promise<string> {
		return await this.getTokenSilently$().pipe(take(1)).toPromise().then((token: string) => {
			try {
				return JSON.parse(atob(token.split('.')[1]))[AuthClaimsEnum.SCORECARD]; // get scorecard number from auth JWT
			} catch (err) {
				return Promise.reject(err);
			}
		}).catch((err) => {
			return Promise.reject(err);
		});
	}

	/**
	 * @description
	 * returns identityId from token
	 */
	public async getIdentityId$(): Promise<string> {
		return await this.getTokenSilently$().pipe(take(1)).toPromise().then((token: string) => {
			try {
				return JSON.parse(atob(token.split('.')[1]))[AuthClaimsEnum.IDENTITYID];
			} catch (err) {
				return Promise.reject(err);
			}
		}).catch((err) => {
			return Promise.reject(err);
		});
	}

	/**
	 * @description
	 * triggers reset password flow
	 */
	public triggerResetPassword$(): Observable<void> {
		return new Observable<void>(observer => {
			window.authFunctions.triggerResetPassword().then(() => {
				observer.next(null);
				observer.complete();
			}).catch(error => {
				observer.error(error);
			});
		});
	}

	/**
	 * @description
	 * Sets the NGX auth cookie
	 */
	private setAuthCookies(customer: Customer): void {
		// Set the NGX auth cookie
		this.cookieService.set('DCSG_NGX_CUST', JSON.stringify(customer), customer.expires || 30, '/', '', '');
	}

}
