export enum StoreStatus {
	FULLY_CLOSED_NO_SHIP_FROM_STORE_NO_BOPIS = '1',
	OPEN_FOR_FOOT_TRAFFIC_CLOSED_FOR_BOPIS = '5',
	OPEN_FOR_SHIP_FROM_STORE_AND_BOPIS_AND_FOOT_TRAFFIC = '4',
	OPEN_FOR_SHIP_FROM_STORE_AND_BOPIS_CLOSED_FOR_FOOT_TRAFIC = '3',
	OPEN_FOR_SHIP_FROM_STORE_CLOSED_FOR_BOPIS = '2'
}

export const OPEN_STORE_STATUS = Object.freeze([StoreStatus.OPEN_FOR_FOOT_TRAFFIC_CLOSED_FOR_BOPIS, StoreStatus.OPEN_FOR_SHIP_FROM_STORE_AND_BOPIS_AND_FOOT_TRAFFIC]);

export const BOPIS_AVAILABLE = Object.freeze([StoreStatus.OPEN_FOR_SHIP_FROM_STORE_AND_BOPIS_CLOSED_FOR_FOOT_TRAFIC]);

export const NOT_BUYABLE_OPEN_STATUS = Object.freeze([StoreStatus.OPEN_FOR_SHIP_FROM_STORE_AND_BOPIS_AND_FOOT_TRAFFIC, StoreStatus.OPEN_FOR_FOOT_TRAFFIC_CLOSED_FOR_BOPIS]);
