/** @format */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Environment } from '@dcsg-ngx-ecommerce/core/model/environment.model';
import { LocalStorageService } from '@dcsg-ngx-ecommerce/core/service/local-storage.service';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class PromoteIQService {
	private PROMOTE_IQ_API_URL: string;
	constructor(
		@Inject(PLATFORM_ID) protected platformId: any,
		private http: HttpClient,
		private environment: Environment,
		private localStorageService: LocalStorageService
	) {
		this.PROMOTE_IQ_API_URL = this.environment.api.promoteIQ;
	}

	/**
	 * @description
	 *  Takes in objects and formats it to a query string for the api request
	 * @param object
	 */
	// eslint-disable-next-line @typescript-eslint/ban-types
	public serialize(obj: {}): string {
		let value;
		const url_params = [];
		for (const key in obj) {
			if (Array.isArray(obj[key])) {
				value = obj[key].join(',');
			} else {
				value = obj[key];
			}
			url_params.push(`${key}=${value}`);
		}
		return url_params.join('&').replace(`&ca`, `%26ca`);
	}

	/**
	 * @description
	 * Creates the params used for the api request
	 * Optionally passes session and user properties based on localStorageData from the TAG_ID
	 *
	 * @param ecodes -array of ecode IDs
	 */
	// eslint-disable-next-line @typescript-eslint/ban-types
	public createParams(
		targets: object,
		count: number,
		slot = 14035,
		category?: number,
		store?: string
	): string {
		// obtains the tagIDS object from local storage
		const localStorage = this.localStorageService.get('TAG_IDs') || '{}';
		const tagIDs = JSON.parse(localStorage);

		// parsing local storage object for values needed in product IQ get request
		const sessionId = tagIDs?.AdobeMCVID?.ID;
		const athleteId = tagIDs?.AthleteID?.ID;
		const paramObject = {
			...(category && { category_id: category }),
			...(store && { store }),
			targets: this.serialize(targets),
			slot,
			count,
			count_fill: count,
			audience_id: this.audienceSelector()
		};
		if (sessionId) {
			Object.assign(paramObject, { session: sessionId, user: athleteId || sessionId });
		}
		return this.serialize(paramObject);
	}

	private audienceSelector(): number {
		let loyalty;
		let isGold;
		if (window._dsgTag) {
			loyalty = window._dsgTag.CustomerIDs.Loyalty;
			isGold = window._dsgTag.CustomerIDs.isGold;
		}

		if (loyalty && isGold) {
			return 3;
		} else if (loyalty && !isGold) {
			return 2;
		} else {
			return 1;
		}
	}

	/**
	 * @description
	 * Makes a client side get request to obtain data for the product IQ component
	 *
	 * @param params - query string
	 */
	public getPromoteIQData$(params: string, retries = 0): Observable<any> {
		const httpHeaders = {
			'Content-Type': 'application/x-www-form-urlencoded'
		};

		const httpOptions = {
			headers: new HttpHeaders(httpHeaders)
		};

		return this.http.get(`${this.PROMOTE_IQ_API_URL}${params}`, httpOptions).pipe(
			map((response: any) => {
				return response;
			}),
			retry(retries)
		);
	}

	public fireTrackingEvent$(url: string) {
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		this.http.get(url).subscribe(() => {});
	}

	/**
	 * @description
	 * Checks to see if session ID exists
	 *
	 * @param ecodes -array of ecode IDs
	 */
	public sessionIdExists(sessionBoolean = false) {
		const sessionIDChecker = JSON.parse(this.localStorageService.get('TAG_IDs') || '{}');
		const sessionAdobeMCVID = sessionIDChecker?.AdobeMCVID?.ID;
		if (sessionAdobeMCVID) {
			sessionBoolean = true;
		}
		return sessionBoolean;
	}
}
