import { CustomHttpHeaders, MediaType } from '@shared/data-access/enums';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ISkuLocation, SkuLocationResponse, StoreInformation } from '@shared/data-access/interfaces';
import { map, retry } from 'rxjs/operators';

import { Chain } from '@dcsg-ngx-ecommerce/core/model/chain.model';
import { DCSG_CONSTANTS } from '@dcsg-ngx-ecommerce/core/properties/dcsg-constants';
import { Environment } from '@dcsg-ngx-ecommerce/core/model/environment.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class StoreDao {
	constructor(private chain: Chain, private environment: Environment, private http: HttpClient) {}

	/**
	 * @description
	 * Gets a list of stores within the search radius of the provided coordinates
	 */
	public getAllStoresWithinRadius$(latitude: number | string, longitude: number | string, zipCode: string, retries = 0, searchRadius = DCSG_CONSTANTS.defaultStoreSearchRadius, useRadius?: boolean): Observable<any> {
		// Default store fronts to current chain
		let storeFronts = this.chain.chainIdentifierAbbr;

		// Check if cross-channel BOPIS is enabled
		if (this.environment.killSwitch && this.environment.killSwitch.crossChainBopis[this.chain.chainIdentifierAbbr] && this.chain.crossChainBopisPartners) {
			// Append the other partners
			storeFronts += `,${this.chain.crossChainBopisPartners.toString()}`;
		}

		// default to lat and long
		let addrString = `${latitude},${longitude}`;

		// Check to see if there are valid geo coordinates
		if (zipCode && (latitude === 0 || latitude === '0')) {
			addrString = zipCode;
		}

		let url = `${this.environment.api.elastic.availability}/api/v3/stores/search?addr=${addrString}&lob=${storeFronts}`;

		if (useRadius) {
			url = `${url}&radius=${searchRadius}`;
		}

		// Make the HTTP Request
		return this.http.get(url).pipe(
			map((response: any) => {
				// Return the response and handle the code in a parent class
				return response;
			}),
			retry(retries)
		);
	}

	/**
	 * @description
	 * Accepts SKU(s) and location and returns inventory details for the supplied SKU(s) by given location
	 */
	public getSkuInventoryByLocation$(skuData: number | string | number[] | string[], location: number | string | number[] | string[], retries = 0): Observable<ISkuLocation[]> {

		const httpParams = new HttpParams().set('location', Array.isArray(location) ? location.toString() : [location].toString()).set('sku', Array.isArray(skuData) ? skuData.toString() : [skuData].toString());

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			Accept: MediaType.APPLICATION_JSON,
			'X-TIMEOUT': `${DCSG_CONSTANTS.timeouts.medium}`
		});

		let apiVersion = 'v1';

		//make sure new api is enabled and new token is defined
		//obtain header value from EAT

		if(this.environment.killSwitch.newEATApiEnabled[this.chain.chainIdentifierAbbr]){
			apiVersion = 'v2';
			httpHeaders = httpHeaders.set('X-API-Key', this.environment.apiTokens.elastic.availability);
		}

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders,
			params: httpParams
		};

		// Make the HTTP Request
		return this.http.get(`${this.environment.api.elastic.inventory}/${apiVersion}/inventoryapis/searchinventory`, httpOptions).pipe(
			map((response: SkuLocationResponse) => {
				// Return data
				let returnData: ISkuLocation[] = [];

				// Check to see if there is any SKU inventory data
				/* istanbul ignore else */
				if (typeof response.data !== 'undefined' && typeof response.data.skus !== 'undefined') {
					// Set the return data
					returnData = response.data.skus;
				}

				// Return the raw data
				return returnData;
			}),
			retry(retries)
		);
	}

	/**
	 * @description
	 * Gets a list of stores within the search radius for the selected Sku
	 */
	public getStoreInformation$(zipcode: string, productSku: string, qty: number, isAvailabilitySelected: boolean, retries = 0): Observable<any> {
		// Default store fronts to current chain
		let storeFronts = this.chain.chainMarketingIdentifierAbbr;

		// Check if cross-channel BOPIS is enabled
		if (this.environment.killSwitch.crossChainBopis[this.chain.chainIdentifierAbbr] && this.chain.crossChainBopisPartners) {
			// Append the other partners
			storeFronts += `,${this.chain.crossChainBopisPartners.toString()}`;
		}

		// Query String Parameters
		const httpParams = new HttpParams().set('addr', `${zipcode}`).set('radius', `${DCSG_CONSTANTS.defaultStoreSearchRadius}`).set('uom', 'imperial').set('lob', storeFronts).set('sku', `${productSku}`).set('res', 'locatorsearch');

		// HTTP Headers
		let httpHeaders = new HttpHeaders({
			Accept: MediaType.WILDCARD,
			'X-TIMEOUT': `${DCSG_CONSTANTS.timeouts.medium}`
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true');

		let apiVersion = 'v3';

		//make sure new api is enabled and new token is defined
		//obtain header value from EAT
		if(this.environment.killSwitch.newEATApiEnabled[this.chain.chainIdentifierAbbr]){
			apiVersion = 'v4';
			httpHeaders = httpHeaders.set('X-API-Key', this.environment.apiTokens.elastic.availability);
		}

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders,
			params: httpParams
		};

		// Make the HTTP Request
		return this.http.get(`${this.environment.api.elastic.availability}/ws/${apiVersion}/omni/stores`, httpOptions).pipe(
			map((response: any) => {
				// Return the raw data
				return response;
			}),
			retry(retries)
		);
	}

	/**
	 * @description
	 * Returns information for a specific store by number
	 *
	 * @returns - {Observable<StoreInformation>}
	 */
	// Note: Below function is also being used by AOS Store modal to determine if valid store number is entered
	public getBopisStoreInformation$(storeNumber: string, useV3 = false): Observable<StoreInformation> {
		// HTTP Headers
		const httpHeaders = new HttpHeaders({
			Accept: MediaType.APPLICATION_JSON,
			'Content-Type': MediaType.APPLICATION_JSON,
			'Access-Control-Allow-Origin': '*',
			'X-TIMEOUT': `${DCSG_CONSTANTS.timeouts.medium}`
		});

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		const apiVersion = useV3 ? 'v3' : 'v1';

		// Make the HTTP Request
		return this.http.get(`${this.environment.api.elastic.availability}/api/${apiVersion}/stores/${storeNumber}`, httpOptions).pipe(
			map((response: StoreInformation) => {
				return response;
			}),
			retry(1)
		);
	}

	// Brandify Calls
	/**
	 * @description
	 * Gets a list of stores within the search radius of the provided coordinates
	 *
	 * @param latitude - The latitude
	 * @param longitude - The longitude
	 * @param zipCode - The address entered by user
	 * @param retries - The number of times to retry the HTTP Request
	 * @param searchRadius - The search radius to look for stores with the provided coordinates
	 * @returns - {Observable<any>}
	 */
	public getAllStoresWithinRadiusBrandify$(latitude: number, longitude: number, zipCode: string, retries = 0, searchRadius = DCSG_CONSTANTS.defaultStoreSearchRadius): Observable<any> {
		// HTTP Headers
		const httpHeaders = new HttpHeaders({
			Accept: MediaType.APPLICATION_JSON,
			'Content-Type': MediaType.APPLICATION_WWW_FORM_URLENCODED,
			'X-TIMEOUT': `${DCSG_CONSTANTS.timeouts.xshort}`
		});

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Request data
		let requestData = {};

		if (zipCode && latitude === 0) {
			requestData = {
				request: {
					appkey: this.environment.apiTokens.brandify.storeLocator[this.chain.chainIdentifierAbbr],
					formdata: {
						dataview: 'store_apiview',
						geolocs: {
							geoloc: [
								{
									country: 'US',
									addressline: zipCode
								}
							]
						},
						searchradius: `${searchRadius}`
					}
				}
			};
		} else {
			requestData = {
				request: {
					appkey: this.environment.apiTokens.brandify.storeLocator[this.chain.chainIdentifierAbbr],
					formdata: {
						dataview: 'store_apiview',
						geolocs: {
							geoloc: [
								{
									country: 'US',
									latitude,
									longitude
								}
							]
						},
						searchradius: `${searchRadius}`
					}
				}
			};
		}

		// Make the HTTP Request
		return this.http.post(`${this.environment.api.brandify.brandifyBaseUrl}${this.chain.serviceIdentifier}/rest/locatorsearch`, requestData, httpOptions).pipe(
			map((response: any) => {
				// Return the response and handle the code in a parent class
				return response;
			}),
			retry(retries)
		);
	}

	public getItemQuantities(itemList: string) {
		const httpHeaders = new HttpHeaders({
			'Accept': MediaType.APPLICATION_JSON,
			'Content-Type': MediaType.APPLICATION_JSON,
			'Access-Control-Allow-Origin': '*',
			'Cache-Control': 'no-cache,no-store,must-revalidate,max-age=0',
			'Pragma': 'no-cache',
			'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
			'X-TIMEOUT': `${DCSG_CONSTANTS.timeouts.medium}`
		});
		const httpOptions = {
			headers: httpHeaders
		};

		return this.http.get<any>(`${this.environment.api.elastic.availability}/v1/inventoryapis/searchinventory?${itemList}`, httpOptions).pipe(
			map((data: any) => {
				return data;
			}),
			retry(1)
		);
	}

	/* istanbul ignore next */
	public getSameDayAvailability$(zipCode = '', skus: string[] = []): Observable<any> {
		// Standard HTTP Headers
		const httpHeaders = new HttpHeaders({
			'X-TIMEOUT': DCSG_CONSTANTS.timeouts.medium,
			'X-API-Key': `${this.environment.apiTokens.elastic.availability}`
		});

		const httpOptions = {
			headers: httpHeaders
		};

		let skuParams = '';
		if (skus.length) {
			for (const sku of skus) {
				skuParams += `&skus=${sku}`;
			}
		}

		return this.http.get<any>(`${this.environment.api.elastic.availability}/v1/same-day-availability/zipcode/sku?zipCode=${zipCode}${skuParams}`, httpOptions).pipe(
			retry(1)
		);
	}
}
