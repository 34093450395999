/** @format */

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Logger } from '@dcsg-ngx-ecommerce/log/service/logger.service';
import { DCSG_CONSTANTS } from '../properties/dcsg-constants';
import { WINDOW } from './window.service';

/** @dynamic */
@Injectable({
	providedIn: 'root'
})
export class AnalyticsService {
	public chatDetailsArray = [];

	constructor(
		@Inject(DOCUMENT) protected document: HTMLDocument,
		@Inject(WINDOW) protected window: Window,
		protected logger: Logger
	) {}

	/**
	 * @description
	 * Returns a supplied Date formatted as MM-DD-YYYY
	 *
	 * @returns The formatted date to analytics format: MM-DD-YYYY
	 */
	public getFormattedDate(currentDate: Date): string {
		// Get the full year
		const year = currentDate.getFullYear();

		// Get the month
		let month = (1 + currentDate.getMonth()).toString();

		// Check to see if leading 0 is needed
		month = month.length > 1 ? month : `0${month}`;

		// Get the date
		let day = currentDate.getDate().toString();

		// Check to see if leading 0 is needed
		day = day.length > 1 ? day : `0${day}`;

		// Return the formatted string
		return `${month}-${day}-${year}`;
	}

	/**
	 * @description
	 * Returns a supplied Date formatted as HH:mm
	 *
	 * @returns The formatted time to analytics format: HH:mm
	 */
	public getFormattedTime(currentDate: Date): string {
		// Get the hours
		let hours = `${currentDate.getUTCHours()}`;

		// Check to see if leading 0 is needed
		hours = hours.length > 1 ? hours : `0${hours}`;

		// Get the minutes
		let minutes = `${currentDate.getUTCMinutes()}`;

		// Check to see if leading 0 is needed
		minutes = minutes.length > 1 ? minutes : `0${minutes}`;

		// Return the formatted string
		return `${hours}:${minutes}`;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#resetAnalytics
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @description
	 * Resets/re-initializes the siteData property
	 */
	public resetAnalytics(): void {
		this.window.siteData = {};
		this.window.siteData.ErrorID = [];
		this.window.siteData.ErrorMessage = [];
		this.window.siteData.EventData = [];
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#resetFields
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @description
	 * Deletes fields of of the siteData property
	 */
	public resetFields(...fields: string[]) {
		for (const field of fields) {
			delete this.window.siteData[field];
		}
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setAppID
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @description
	 */
	public setAppID(appID: string): void {
		window.siteData.appID = appID;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setChain
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param chain - The name of the store chain
	 *
	 * @description
	 * Sets the store chain on the Object.  This is the branch of DICK'S Sporting goods that the customer is currently
	 * shopping at (i.e. Golf Galaxy, Field & Stream, DICK'S Sporting Goods, etc.)
	 */
	public setChain(chain: string): void {
		this.window.siteData.Chain = chain;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setChatEvent
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param eventDetails - The event is header or footer
	 *
	 * @description
	 * Sets the chat analytics deetails
	 */
	public setChatEvent(eventDetails: string): void {
		const ChatDetails = {
			EventData: undefined,
			Location: eventDetails
		};

		this.chatDetailsArray.push(ChatDetails);

		const chatDetailsObject = {
			ChatClicked: Object.assign([], this.chatDetailsArray)
		};

		this.setEventData(chatDetailsObject);
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setDYMTerm
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param didYouMeanTerm - The Did You Mean Term
	 *
	 * @description
	 * Sets the Did You Mean Term on the Object.
	 */
	public setDYMTerm(didYouMeanTerm: string): void {
		this.window.siteData.DYMTerm = didYouMeanTerm;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setDYMType
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param didYouMeanType - The Did You Mean Type, e.g. DYMAuto
	 *
	 * @description
	 * Sets the Did You Mean Type on the Object.
	 */
	public setDYMType(didYouMeanType: string): void {
		this.window.siteData.DYMType = didYouMeanType;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setEAthlete
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param eAthlete - The hashed email of a user
	 *
	 * @description
	 * Sets the hashed email (EAthlete) on the Object.
	 */
	public setEAthlete(eAthlete: string): void {
		this.window.siteData.EAthlete = eAthlete;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setEnteredSearchTerm
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param enteredSearchTerm - The Entered Search Term
	 *
	 * @description
	 * Sets the Entered Search Term on the Object.
	 */
	public setEnteredSearchTerm(enteredSearchTerm: string): void {
		this.window.siteData.EnteredSearchTerm = enteredSearchTerm;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setHomrAudiences
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param homrData - The an array of audiences from a page call
	 *
	 * @description
	 * Adds audiences set in homR to site data
	 */
	public setHomrAudiences(homrData: any): void {
		if (!this.window.siteData) {
			this.resetAnalytics();
		}

		if (!this.window.siteData.HomrAudiences) {
			// If blank initialize the object
			this.window.siteData.HomrAudiences = [];
		}

		if (homrData.length > 0) {
			homrData.forEach((item) => {
				this.window.siteData.HomrAudiences.push(item);
			});
		}
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setErrorId
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param errorId - The error ID(s) to set
	 *
	 * @description
	 * Sets an Array of error IDs which are unique identifies for individual errors
	 */
	public setErrorId(errorId?: any): void {
		// Error IDs
		let errorIds = [];

		// Check argument type
		if (typeof errorId === 'string' && errorId.trim() !== '') {
			errorIds.push(errorId);
		} else if (Array.isArray(errorId) && errorId.length > 0) {
			errorIds = errorId;
		} else if (typeof errorId === 'number') {
			// Could be number
			errorIds.push(`${errorId}`);
		}

		this.window?.siteData?.ErrorID.push(...errorIds);
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setErrorMessage
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param errorMessage - The error message(s) to set
	 *
	 * @description
	 * Sets an Array of error IDs which are unique identifies for individual errors
	 */
	public setErrorMessage(errorMessage?: any): void {
		// Error messages
		let errorMessages = [];

		// Check argument type
		if (typeof errorMessage === 'string' && errorMessage.trim() !== '') {
			errorMessages.push(errorMessage);
		} else if (Array.isArray(errorMessage) && errorMessage.length > 0) {
			errorMessages = errorMessage;
		}

		this.window.siteData.ErrorMessage.push(...errorMessages);
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setEventData
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param eventData - The Event data to set
	 *
	 * @description
	 * Sets the update Event type
	 */
	public setEventData(eventData: any): void {
		// Event data
		let events = [];

		// Check argument type
		if (typeof eventData === 'string' && eventData.trim() !== '') {
			events.push(eventData);
		} else if (Array.isArray(eventData) && eventData.length > 0) {
			events = eventData;
		} else if (typeof eventData === 'object') {
			events.push(eventData);
		}

		this.window.siteData.EventData.push(...events);
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setEventType
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param eventType - The current update Event type
	 *
	 * @description
	 * Sets the update Event type
	 */
	public setEventType(eventType: string): void {
		this.window.siteData.EventType = eventType;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setLoggedIn
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param loggedIn - The Logged In flag as a String
	 *
	 * @description
	 * Sets the Signed In status on the Object. This denotes whether the user has signed in or not.
	 */
	public setLoggedIn(loggedIn: string): void {
		this.window.siteData.LoggedIn = loggedIn;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setLoggedInOccurred
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param loggedInOccurred - The Logged In Occured flag as a String
	 *
	 * @description
	 * Sets the Signed In Occurred flag on the Object. This denotes whether the user has performed the sign in action.
	 */
	public setLoggedInOccurred(loggedInOccurred: string): void {
		this.window.siteData.LoggedInOccured = loggedInOccurred;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setLoyalty
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param isLoyaltyMember - The loyalty (string) boolean
	 *
	 * @description
	 * Sets the Loyalty on the Object. This denotes whether the user is a loyalty member or not.
	 */
	public setLoyalty(isLoyaltyMember: string): void {
		this.window.siteData.Loyalty = isLoyaltyMember;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setLoyaltyID
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param loyaltyID - The loyalty ID of the current user
	 *
	 * @description
	 * Sets the loyalty ID on the Object.  The unique identifier for each member in the loyalty program
	 */
	public setLoyaltyID(loyaltyID: string): void {
		this.window.siteData.LoyaltyID = loyaltyID;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setLoyaltyPointsBalance
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param loyaltyPointsBalance - The number of loyalty points of the current user
	 *
	 * @description
	 * Sets the loyalty points balance on the Object
	 */
	public setLoyaltyPointsBalance(loyaltyPointsBalance: string): void {
		this.window.siteData.LoyaltyPointsBalance = loyaltyPointsBalance;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setRewardValue
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param LoyaltyBalance
	 *
	 * @description
	 * Sets the loyalty points balance on the Object
	 */
	public setRewardValue(rewardValue: number): void {
		this.window.siteData.rewardValue = rewardValue;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setModalName
	 * @methodOf dcsg.app.services.service:setModalName
	 *
	 * @param modalName - The name of the modal opened
	 *
	 * @description
	 * Sets the Modal name on the Object
	 */
	public setModalName(modalName: string): void {
		window.siteData.ModalName = modalName;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setPage
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param pageTitle - The title of the current state
	 *
	 * @description
	 * Sets the page title of the current state. Duplicate of siteData.PageName
	 */
	public setPage(pageTitle: string): void {
		this.window.siteData.Page = pageTitle;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setPageName
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param pageTitle - The title of the current state
	 *
	 * @description
	 * Sets the page title of the current state.  Duplicate of siteData.Page
	 */
	public setPageName(pageTitle: string): void {
		this.window.siteData.PageName = pageTitle;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setPageTemplate
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param template - The template name of the current state
	 *
	 * @description
	 * Sets the page template of the current state
	 */
	public setPageTemplate(template: string): void {
		this.window.siteData.PageTemplate = template;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setPageType
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param type - The type of the current state
	 *
	 * @description
	 * Sets the page type of the current state
	 */
	public setPageType(type: string): void {
		this.window.siteData.PageType = type;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setPageUrl
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param pageUrl - The page URL
	 *
	 * @description
	 * Sets the Page URL on the Object.  This is the URL of the current page
	 */
	public setPageUrl(pageUrl: string): void {
		this.window.siteData.PageURL = pageUrl ? decodeURI(pageUrl) : '';
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setPromotionMessages
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param promotions - The current promotions
	 *
	 * @description
	 * Sets the current promotion messages displayed on the page
	 */
	public setPromotionMessages(promotions: any) {
		this.window.siteData.PromoMessage = promotions;
	}

	/**
	 * @param prop - window.siteData property name
	 * @param propData - window.siteData propety value
	 *
	 * @description
	 * sets property and value based on provided params
	 */
	public setProperty(prop: string, propData?: any): void {
		if (typeof prop === 'string') {
			this.window.siteData[prop] = propData;
		}
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setReferringPage
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param referringPage - The referring page
	 *
	 * @description
	 * Sets the Referring Page on the Object. The Page which referred the user to the page that they are currently on.
	 */
	public setReferringPage(referringPage: string): void {
		this.window.siteData.ReferringPage = referringPage;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setReferringPageType
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param referringPageType - The referring page Type
	 *
	 * @description
	 * Sets the Referring Page Type on the Object. The type of page which referred the user to the page that they are currently on.
	 */
	public setReferringPageType(referringPageType: string): void {
		this.window.siteData.ReferringPageType = referringPageType;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setReferringPageUrl
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param referringPageUrl - The referring page URL
	 *
	 * @description
	 * Sets the Referring Page URL on the Object. The URL of the page which referred the user to the page that they are currently on.
	 */
	public setReferringPageUrl(referringPageUrl: string): void {
		this.window.siteData.ReferringPageURL = referringPageUrl ? decodeURI(referringPageUrl) : '';
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setRegistrationOccurred
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param registrationOccurred - The registration occurred flag
	 *
	 * @description
	 * Sets the Referring Page URL on the Object. The URL of the page which referred the user to the page that they are currently on.
	 */
	public setRegistrationOccurred(registrationOccurred: string) {
		this.window.siteData.RegistrationOccured = registrationOccurred;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setReplacementTerm
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param replacementTerm - The replacement term
	 *
	 * @description
	 * Sets the replacement term for search on the Object.
	 */
	public setReplacementTerm(replacementTerm: string): void {
		this.window.siteData.ReplacementTerm = replacementTerm;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setReplacementType
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param replacementType - The replacement type
	 *
	 * @description
	 * Sets the replacement type for search on the Object.
	 */
	public setReplacementType(replacementType: string): void {
		this.window.siteData.ReplacementType = replacementType;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setSalesCatalogDetails
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param salesCatalogDetails
	 *
	 * @description
	 * Sets the Sales Category Details on the Object.
	 */
	public setSalesCatalogDetails(salesCatalogDetails: object[]): void {
		this.window.siteData.MCSalesCatalogDetails = salesCatalogDetails;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setSearchResultCount
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param searchResultCount - The search results count
	 *
	 * @description
	 * Sets the search results count on the Object.
	 */
	public setSearchResultCount(searchResultCount: string): void {
		this.window.siteData.SearchResultCount = searchResultCount;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setSearchType
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param searchType - The search type
	 *
	 * @description
	 * Sets the search type on the Object.
	 */
	public setSearchType(searchType: string): void {
		this.window.siteData.SearchType = searchType;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setSearchType
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param searchType - The action type
	 *
	 * @description
	 * Sets the search type on the Object.
	 */
	public setActionType(actionType: string): void {
		this.window.siteData.ActionType = actionType;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setServerDate
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param date - The current Date String, formatted MM-DD-YYYY
	 *
	 * @description
	 * Sets the Server Date on the Object. Date that the user accessed the page.
	 */
	public setServerDate(date: string): void {
		this.window.siteData.ServerDate = date;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setServerTime
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param time - The current Time String, formatted HH:mm
	 *
	 * @description
	 * Sets the Server Time on the Object. Time that the user accessed the page.
	 */
	public setServerTime(time: string): void {
		this.window.siteData.ServerTime = time;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setSessionID
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param sessionId - The current Session ID, as a v4 UUID
	 *
	 * @description
	 * Sets the Session ID on the Object. Unique identifier for the specific full visit for the user.
	 */
	public setSessionID(sessionId: string): void {
		this.window.siteData.SessionID = sessionId;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setLoggedIn
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param shouldBeLoggedIn - The Should Be Logged In flag as a Boolean
	 *
	 * @description
	 * Sets the Should Be Signed In status on the Object. This denotes whether the session should have expired or not.
	 */
	public setShouldBeLoggedIn(shouldBeLoggedIn: boolean): void {
		this.window.siteData.ShouldBeLoggedIn = `${shouldBeLoggedIn}`;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setSortMethod
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param sortMethod - The sort method
	 *
	 * @description
	 * Sets the sort method on the Object.
	 */
	public setSortMethod(sortMethod: string): void {
		this.window.siteData.SortMethod = sortMethod;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setStaticID
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param staticId - The title of the current state
	 *
	 * @description
	 * Sets the page title of the current state.  Duplicate of siteData.Page
	 */
	public setStaticID(staticId: string): void {
		this.window.siteData.StaticID = staticId;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setType
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param type - The update type, either PageType or EventType
	 * @param value - The value to set for the type
	 *
	 * @description
	 * Sets the type of update, will either be a page update or an event update and will call the appropriate method accordingly
	 * to set the correct siteData property.  If an existing type already exists, it will remove the incorrect type
	 */
	public setType(type: string, value: string): void {
		// Check the Type
		if (typeof type === 'string' && type.toLowerCase() === 'event') {
			// Check if PageType is present
			if (this.window.siteData.PageType) {
				// Delete the EventType property
				delete this.window.siteData.PageType;
			}

			// Set the EventType
			this.setEventType(value);
		} else {
			// Check if EvenType is present
			if (this.window.siteData.EventType) {
				// Delete the EventType property
				delete this.window.siteData.EventType;
			}

			// Set the PageType
			this.setPageType(value);
		}
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setUserID
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param userID - The ID of the current user, either guest or registered
	 *
	 * @description
	 * Sets the user ID on the Object. The unique identifier for the user currently browsing the site as either
	 * guest or registered.
	 */
	public setUserID(userID: string): void {
		this.window.siteData.UserID = userID;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setVisitNumber
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param visitNumber - The visit number
	 *
	 * @description
	 * Sets the visit number on the Object. This denotes how many times the user has been on the site. Resets
	 * when the user clears cache or cookies.
	 */
	public setVisitNumber(visitNumber: number): void {
		this.window.siteData.VisitNumber = `${visitNumber}`;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setSearchPageBaseProperties
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param search - The search details
	 *
	 * @description
	 * Sets the search details properties.  These properties are base search properties and are required on siteData
	 * regardless if a search was executed or not.
	 */
	public setSearchPageBaseProperties(search?: {
		recordSetTotal?: string;
		searchDetails: {
			DYMTerm: string;
			DYMType: string;
			replacementTerm: string;
			searchTerm: string;
			searchType: string;
		};
	}) {
		// Default replacement type
		let replacementType;

		// Check if search is undefined, i.e. no search was performed
		if (typeof search === 'undefined') {
			// Initialize an empty search Object
			search = Object.assign({}, DCSG_CONSTANTS.analyticsReporting.searchEmptyResults);
		}

		// Set DYMTerm
		this.setDYMTerm(search.searchDetails.DYMTerm || '');

		// Set DYMType
		this.setDYMType(search.searchDetails.DYMType || '');

		// Set the Entered Search Term
		this.setEnteredSearchTerm(search.searchDetails.searchTerm || '');

		// Set the Results Count
		this.setSearchResultCount(+search.recordSetTotal >= 0 ? `${search.recordSetTotal}` : '');

		// Set the searchType
		this.setSearchType(search.searchDetails.searchType || '');

		// Check to see if a Did You Mean Term is set
		if (
			typeof search.searchDetails.DYMTerm === 'string' &&
			search.searchDetails.DYMTerm.trim() !== ''
		) {
			// Check to see if the search term is in the Did You Mean term
			if (search.searchDetails.DYMTerm.indexOf(search.searchDetails.searchTerm) === 0) {
				replacementType = 'Addition';
			} else {
				replacementType = 'Replacement';
			}
		}

		// Set Replacement Term
		this.setReplacementTerm(search.searchDetails.replacementTerm || '');

		// Set Replacement Type
		this.setReplacementType(replacementType || '');
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setAOSDetails
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param AOSData - The AOS storeData
	 *
	 * @description
	 * Sets the AOS KioskInfo
	 */
	public setAOSDetails(AOSData): void {
		this.window.siteData.KioskInfo = AOSData;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#setIdentityId
	 * @methodOf dcsg.app.services.service:setIdentityId
	 *
	 * @param identityId
	 *
	 * @description
	 * Sets IdentityId
	 */
	public setIdentityId(identityId: string): void {
		this.window.siteData.IdentityId = identityId;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#triggerPreRenderEvent
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 * @fires dcsg.app.services.service:AnalyticsService#reporting:PagePreRender
	 *
	 * @param eventName - The name of the render event, i.e. reporting:PagePreRender, reporting:PagePostRender, reporting:update
	 * @param eventData - The data to pass along with the event
	 *
	 * @description
	 * Triggers the Event to notify window.siteData has been updated and passes the Object as data to the Event
	 */
	public triggerAnalyticsEvent(eventName: string, eventData?: any) {
		this.logger.log(
			`AnalyticsService.triggerRenderEvent -- Event ${eventName} triggered: `,
			Object.assign({}, eventData || this.window.siteData)
		);
		// Trigger the Event and pass along the siteData Object
		this.document.dispatchEvent(
			this.createCustomEvent(
				`${eventName}`,
				Object.assign({}, eventData || this.window.siteData)
			)
		);
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#triggerAnalyticsEventAsPromise
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param eventName - The name of the render event, i.e. NewPageView, CheckoutAction, OrderSubmit, etc.
	 * @param eventData - The data to pass along with the event
	 * @param timeout - The time to wait before
	 *
	 * @description
	 * Calls analytics function that returns a Promise and passes the Object as data. If a timeout is not provided,
	 * then the function within _dsgTag will reject immediately.  This function will wait until Adobe has returned
	 * success that the pixel has been received, so it can be used for async logic control.  Example: send PaymentType
	 * property to analytics on Payments page before redirecting to Order Confirmation page so that we can ensure the
	 * data has been successfully sent to Adobe before triggering a URL change and causing any open XHRs (e.g. Adobe pixel)
	 * to be cancelled.  If for some reason the function is unavailable from analytics, this function will simply return a
	 * rejected Promise.  Additionally, if the Event name does not exist in analytics or isn't being handled there, it will
	 * simply auto-reject the Promise.
	 */
	public triggerAnalyticsEventAsPromise(
		eventName: string,
		eventData: any,
		timeout: number
	): Promise<boolean> {
		// Ensure the analytics function exists
		if (
			typeof this.window._dsgTag?.FunctionRepository?.TriggerAnalyticsEventAsPromise ===
			'function'
		) {
			return this.window._dsgTag.FunctionRepository.TriggerAnalyticsEventAsPromise(
				eventName,
				eventData,
				timeout
			);
		}

		// Default to rejecting the Promise
		return Promise.reject(false);
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#triggerCheckoutActionEvent
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 *
	 * @param eventName - The name of the render event, i.e. reporting:PagePreRender, reporting:PagePostRender, reporting:update
	 *
	 * @description
	 * Triggers the Event to notify window.siteData has been updated and passes the Object as data to the Event
	 */
	public triggerCheckoutActionEvent(eventName: string) {
		this.window.siteData.EventType = 'CheckoutAction';
		this.window.siteData.ActionType = eventName;

		this.logger.log(
			'AnalyticsService.triggerChecktoutActionEvent -- Event CheckoutAction triggered: ',
			Object.assign({}, this.window.siteData)
		);

		// Trigger the Event and pass along the siteData Object
		this.document.dispatchEvent(
			this.createCustomEvent(`${eventName}`, Object.assign({}, this.window.siteData))
		);
	}

	/**
	 * @description
	 * Utility function to create and return a custom event
	 *
	 * @param eventName - The name of the Event to create
	 * @param eventData - The data to pass to the created Event
	 * @returns The created custom Event
	 */
	public createCustomEvent(eventName: string, eventData?: any): Event {
		let customEvent;

		if (window.CustomEvent) {
			// Create the Event
			customEvent = new CustomEvent(eventName, {
				detail: eventData
			});
		} else {
			// Create the Event
			customEvent = this.document.createEvent('CustomEvent');

			// Initialize the Event
			customEvent.initCustomEvent(eventName, true, true, eventData);
		}
		// Return the custom event
		return customEvent;
	}

	/**
	 * @description
	 * Utility function to create and return a custom event
	 *
	 * @param eventName
	 * @param eventData
	 * @returns - {Event}
	 */
	private createCustomEventAndActuallyUseEventData(eventName: string, eventData?: any): Event {
		let customEvent;

		if (window.CustomEvent) {
			// Create the Event
			customEvent = new CustomEvent(eventName, {
				detail: eventData ? eventData : this.window.siteData
			});
		} else {
			// Create the Event
			customEvent = document.createEvent('CustomEvent');

			// Initialize the Event
			customEvent.initCustomEvent(
				eventName,
				true,
				true,
				eventData ? eventData : this.window.siteData
			);
		}

		// Return the custom event
		return customEvent;
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#triggerEmailFormSubmit
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 * @fires dcsg.app.services.service:AnalyticsService#reporting:update
	 *
	 * @description
	 * Triggers the Event to notify window.siteData has been updated and passes the Object as data to the Event
	 */
	public triggerEmailFormSubmit(SignUpMessage: string, EAthlete?: string) {
		this.logger.info(
			'AnalyticsService.triggerEmailFormSubmit -- Event reporting: update triggered: ',
			Object.assign({}, this.window.siteData)
		);
		this.document.dispatchEvent(
			this.createCustomEventAndActuallyUseEventData('EmailSignup', {
				SignUpMessage,
				EAthlete
			})
		);
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#triggerPostRenderEvent
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 * @fires dcsg.app.services.service:AnalyticsService#reporting:PagePostRender
	 *
	 * @description
	 * Triggers the Event to notify window.siteData has been updated and passes the Object as data to the Event
	 */
	public triggerPostRenderEvent() {
		this.logger.info(
			'AnalyticsService.triggerPostRenderEvent -- Event reporting:PagePostRender triggered: ',
			Object.assign({}, this.window.siteData)
		);

		// Trigger the Event and pass along the siteData Object
		this.document.dispatchEvent(
			this.createCustomEvent(
				'reporting:PagePostRender',
				Object.assign({}, this.window.siteData)
			)
		);
	}

	/**
	 * @ngdoc method
	 * @name dcsg.app.services.service:AnalyticsService#trigger3rdPartyTags
	 * @methodOf dcsg.app.services.service:AnalyticsService
	 * @fires dcsg.app.services.service:AnalyticsService#trigger3rdPartyTags
	 *
	 * @description
	 * Triggers the Event to notify Utag to start loading non-essential third party tags.
	 */
	public trigger3rdPartyTags() {
		this.logger.info(
			'AnalyticsService.trigger3rdPartyTags -- Event trigger3rdPartyTags triggered: ',
			Object.assign({}, this.window.siteData)
		);

		// Trigger the Event and pass along the siteData Object
		this.document.dispatchEvent(
			this.createCustomEvent('trigger3rdPartyTags', Object.assign({}, this.window.siteData))
		);
	}
}
