/** @format */

import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { DynamicTemplateLoader } from '@dcsg-ngx-ecommerce/core';
import { HmfButtonComponent } from '@dsg-tech/homefield-angular';
import { HomrContentResolve } from '../../common/resolve/homr.resolve';
import { KrakenService } from '@home/data-access';
import { NoContentComponent } from '../no-content/no-content.component';
import { PageHeaderComponent } from './static-component/page-header/page-header.component';
import { StaticComponent } from './static-component/static.component';
import { TaggingService } from '@dcsg-ngx-ecommerce/engage/service/tagging.service';

@NgModule({
	imports: [
		CommonModule,
		HmfButtonComponent
		// TODO: CommonCoreModule
	],
	declarations: [NoContentComponent, PageHeaderComponent, StaticComponent],
	providers: [DynamicTemplateLoader, HomrContentResolve, KrakenService, TaggingService],
	exports: [NoContentComponent, PageHeaderComponent, StaticComponent],
	schemas: [NO_ERRORS_SCHEMA]
})
export class StaticModule {}
