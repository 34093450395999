/** @format */

import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import * as Constants from '../model/device.constants';
import { DEVICE_CHANNEL_IDS, DEVICE_CHANNELS } from '../model/device.constants';

import { BreakPoint, BreakPointNew } from '@shared/data-access/enums';

import { HttpService } from './http.service';
import { ReTree } from './retree';
import { WINDOW } from './window.service';
import { DeviceInfo } from '@shared/data-access/interfaces';

/** @dynamic */
@Injectable({
	providedIn: 'root'
})
export class DeviceService {
	ua = '';
	userAgent = '';
	os = '';
	browser = '';
	device = '';
	os_version = '';
	browser_version = '';
	device_viewport_dimensions;

	constructor(
		@Inject(DOCUMENT) private document: HTMLDocument,
		private httpService: HttpService,
		@Inject(WINDOW) private window: Window
	) {
		this.ua = this.httpService.getUserAgent();

		this._setDeviceInfo();
	}

	/**
	 * @desc Sets the initial value of the device when the service is initiated.
	 * This value is later accessible for usage
	 */
	private _setDeviceInfo() {
		const reTree = new ReTree();
		const ua = this.ua;
		this.userAgent = ua;
		const mappings = [
			{ const: 'OS', prop: 'os' },
			{ const: 'BROWSERS', prop: 'browser' },
			{ const: 'DEVICES', prop: 'device' },
			{ const: 'OS_VERSIONS', prop: 'os_version' }
		];

		mappings.forEach((mapping) => {
			this[mapping.prop] = Object.keys(Constants[mapping.const]).reduce(
				(obj: any, item: any) => {
					obj[Constants[mapping.const][item]] = reTree.test(
						ua,
						Constants[`${mapping.const}_RE`][item]
					);
					return obj;
				},
				{}
			);
		});

		mappings.forEach((mapping) => {
			this[mapping.prop] = Object.keys(Constants[mapping.const])
				.map((key) => {
					return Constants[mapping.const][key];
				})
				.reduce((previousValue, currentValue) => {
					return previousValue === Constants[mapping.const].UNKNOWN &&
						this[mapping.prop][currentValue]
						? currentValue
						: previousValue;
				}, Constants[mapping.const].UNKNOWN);
		});

		this.browser_version = '0';

		/* istanbul ignore else */
		if (this.browser !== Constants.BROWSERS.UNKNOWN) {
			const re = Constants.BROWSER_VERSIONS_RE[this.browser];
			const res = reTree.exec(ua, re);

			/* istanbul ignore else */
			if (res) {
				this.browser_version = res[1];
			}
		}
	}

	/**
	 * @description
	 * Returns the device breakpoint
	 *
	 * @returns The breakpoint for the current viewport width
	 */
	getDeviceBreakPoint(forceCalculate = false): BreakPoint {
		// Get the width
		const width = this.getDeviceViewportDimensions(forceCalculate).width;

		if (width < 576) {
			return BreakPoint.EXTRASMALL;
		}

		if (width < 768) {
			return BreakPoint.SMALL;
		}

		if (width < 992) {
			return BreakPoint.MEDIUM;
		}

		if (width < 1200) {
			return BreakPoint.LARGE;
		}

		// Default to XL
		return BreakPoint.EXTRALARGE;
	}

	/**
	 * @description
	 * Returns the device breakpoint.
	 * Determines the device breakpoint using the new css breakpoints that is documented on Homefield.
	 * https://homefield.dcsg.com/?path=/docs/resources-styles-breakpoints--docs
	 *
	 * @param {boolean} forceCalculate determines whether viewport should be re-calculted.
	 * @returns {number} The breakpoint for the current viewport width
	 */
	getDeviceBreakPointNew(forceCalculate = false): BreakPointNew {
		// Get the width
		const width = this.getDeviceViewportDimensions(forceCalculate).width;

		switch (true) {
			case width < 376:
				return BreakPointNew.EXTRAEXTRASMALL;

			case width < 481:
				return BreakPointNew.EXTRASMALL;

			case width < 769:
				return BreakPointNew.SMALL;

			case width < 1025:
				return BreakPointNew.MEDIUM;

			case width < 1281:
				return BreakPointNew.LARGE;

			case width < 1441:
				return BreakPointNew.EXTRALARGE;

			default:
				return BreakPointNew.EXTRAEXTRALARGE;
		}
	}
	/**
	 * @description
	 * Returns the business channel ID of the device
	 *
	 * @returns the device business channel ID
	 */
	getDeviceChannelId(): string {
		if (this.isMobile()) {
			return DEVICE_CHANNEL_IDS.MOBILE;
		} else if (this.isTablet()) {
			return DEVICE_CHANNEL_IDS.TABLET;
		}

		return DEVICE_CHANNEL_IDS.DESKTOP;
	}

	/**
	 * @description
	 * Returns the business channel of the device
	 *
	 * @returns the device business channel
	 */
	getDeviceChannel(): string {
		if (this.isMobile()) {
			return DEVICE_CHANNELS.MOBILE;
		} else if (this.isTablet()) {
			return DEVICE_CHANNELS.TABLET;
		}

		return DEVICE_CHANNELS.DESKTOP;
	}

	/**
	 * @desc Returns the device information
	 * @returns the device information object.
	 */
	getDeviceInfo(): DeviceInfo {
		return {
			userAgent: this.userAgent,
			os: this.os,
			browser: this.browser,
			device: this.device,
			osVersion: this.os_version,
			browserVersion: this.browser_version
		};
	}

	/**
	 * @description
	 * Returns the height and width of the device viewport
	 */
	getDeviceViewportDimensions(forceCalculate = false): { height: number; width: number } {
		// Check to see if the device viewport dimensions are defined
		if (typeof this.device_viewport_dimensions === 'undefined' || forceCalculate) {
			this.device_viewport_dimensions = {
				height: Math.max(
					this.document.documentElement.clientHeight,
					this.window.innerHeight || 0
				),
				width: Math.max(
					this.document.documentElement.clientWidth,
					this.window.innerWidth || 0
				)
			};
		}

		return this.device_viewport_dimensions;
	}

	/**
	 * @desc Compares the current device info with the mobile devices to check
	 * if the current device is a mobile.
	 * @returns whether the current device is a mobile
	 */
	isMobile(): boolean {
		return [
			Constants.DEVICES.ANDROID,
			Constants.DEVICES.IPHONE,
			Constants.DEVICES.I_POD,
			Constants.DEVICES.BLACKBERRY,
			Constants.DEVICES.FIREFOX_OS,
			Constants.DEVICES.WINDOWS_PHONE,
			Constants.DEVICES.VITA
		].some((item) => {
			return this.device === item;
		});
	}

	/**
	 * @description Gets the current breakpoint of the device depending on the screen width.
	 * @param maxBreakPoint used to determine the mobile screen size threshold
	 * @returns boolean representing if mobile screen criteria has been met.
	 */
	isMobileBreakpoint(maxBreakPoint: BreakPoint = BreakPoint.MEDIUM): boolean {
		const breakpoint = this.getDeviceBreakPoint(true);
		return breakpoint <= maxBreakPoint;
	}

	/**
	 * @desc Compares the current device info with the tablet devices to check
	 * if the current device is a tablet.
	 * @returns whether the current device is a tablet
	 */
	isTablet() {
		return [Constants.DEVICES.I_PAD, Constants.DEVICES.FIREFOX_OS].some((item) => {
			return this.device === item;
		});
	}

	/**
	 * @desc Compares the current device info with the desktop devices to check
	 * if the current device is a desktop device.
	 * @returns whether the current device is a desktop device
	 */
	isDesktop() {
		return [
			Constants.DEVICES.PS4,
			Constants.DEVICES.CHROME_BOOK,
			Constants.DEVICES.UNKNOWN
		].some((item) => {
			return this.device === item;
		});
	}
}
