/** @format */

export enum BreakPoint {
	EXTRASMALL = 0,
	SMALL = 1,
	MEDIUM = 2,
	LARGE = 3,
	EXTRALARGE = 4
}

export enum BreakPointNew {
	EXTRAEXTRASMALL = 0,
	EXTRASMALL = 1,
	SMALL = 2,
	MEDIUM = 3,
	LARGE = 4,
	EXTRALARGE = 5,
	EXTRAEXTRALARGE = 6
}

export enum BreakPointMinWidthValues {
	EXTRAEXTRASMALL = 0,
	EXTRASMALL = 376,
	SMALL = 481,
	MEDIUM = 769,
	LARGE = 1025,
	EXTRALARGE = 1281,
	EXTRAEXTRALARGE = 1441
}
