<!-- @format -->
<div class="seo-bar hmf-px-xs hmf-py-l">
	<ul
		class="breadcrumbs hmf-pb-xxxs"
		itemprop="breadcrumb"
		itemscope=""
		itemtype="http://schema.org/BreadcrumbList">
		<li
			class="breadcrumbs--crumb"
			itemtype="http://schema.org/ListItem"
			itemprop="itemListElement"
			itemscope="">
			<a
				href="/"
				itemprop="item">
				<span itemprop="name">Home</span>
			</a>
			<meta
				itemprop="position"
				content="1" />
		</li>
		<li
			class="breadcrumbs--crumb breadcrumbs--crumb__current"
			itemtype="http://schema.org/ListItem"
			itemprop="itemListElement"
			itemscope="">
			<i class="separator">|</i>
			<span itemprop="name">{{ breadcrumbs }}</span>
		</li>
	</ul>
	<h1
		*ngIf="pageTitle"
		class="hmf-header-bold-l">
		{{ pageTitle }}
	</h1>
</div>
