/** @format */

import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable, TransferState, makeStateKey } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

import { Chain } from '../model/chain.model';
import { Environment } from '../model/environment.model';

@Injectable({
	providedIn: 'root'
})
export class Scene7Dao {
	readonly MEDIA_SET_PARSE_ERROR_PREFIX = 'SCENE7_RESPONSE_PARSE_ERROR:';
	readonly MEDIA_SET_STATE_KEY_PREFIX = 'media-set';

	constructor(
		private chain: Chain,
		private environment: Environment,
		private http: HttpClient,
		private state: TransferState
	) {}

	public static handleError(error: HttpErrorResponse | any) {
		// return an ErrorObservable
		return throwError(error);
	}

	/**
	 * @description
	 * Gets an image set from Scene7 for a given product and supplied arguments
	 */
	public getProductImageSet$(
		eCode: string,
		swatchColor: string,
		swatchColorPrefix: string,
		mediaSetSuffix: string,
		retries = 0
	): Observable<any> {
		// Get unique key
		const STATE_KEY = makeStateKey(
			`${this.MEDIA_SET_STATE_KEY_PREFIX}-${eCode}-${swatchColor}`
		);

		// Attempt to get the state by key
		const stateData: any = this.state.get(STATE_KEY, null);

		// Fix for GG NP0
		let imageCdn = this.environment.thirdParty.scene7.baseConfig.cdn;

		if (
			imageCdn === 'GolfGalaxyTest/' &&
			window.location.hostname.includes(`prod-${this.chain.seoIdentifier}`)
		) {
			imageCdn = 'GolfGalaxy/';
		}

		// Check to see if state exists
		if (stateData) {
			// Return the existing state
			return new Observable((observer) => {
				observer.next(stateData);
				observer.complete();
			});
		}

		// Query String Parameters
		const httpParams = new HttpParams()
			.set('req', 'set,json,UTF-8')
			.set('labelkey', 'label')
			.set('handler', 'customScene7Handler');

		// HTTP Options
		const httpOptions = {
			params: httpParams,
			responseType: 'text' as const
		};

		// Make the HTTP Request
		return this.http
			.get(
				`${this.environment.thirdParty.scene7.baseConfig.baseUrl}${this.environment.thirdParty.scene7.baseConfig.imageDirectory}${imageCdn}${eCode}${swatchColorPrefix}${swatchColor}${mediaSetSuffix}`,
				httpOptions
			)
			.pipe(
				map((response: any) => {
					// Return Array
					let returnMediaSet: any = {};

					try {
						// String replace the wrapping text characters
						const formattedResponse = response
							.replace('/*jsonp*/', '')
							.replace('s7jsonError(', '')
							.replace('customScene7Handler(', '')
							.replace(', "");', '')
							.replace(',"");', '')
							.trim();

						// Attempt to parse the JSON string
						const parsedResponse = JSON.parse(formattedResponse);

						// Set the parsed media set
						returnMediaSet = parsedResponse.set;
					} catch (error) {
						throw new Error(
							`${this.MEDIA_SET_PARSE_ERROR_PREFIX}${JSON.stringify(error, [
								'message',
								'arguments',
								'type',
								'name'
							])}`
						);
					}

					// Set the state
					this.state.set(STATE_KEY, returnMediaSet);

					// Return the result
					return returnMediaSet;
				}),
				retry(retries)
			);
	}

	/**
	 * @description
	 * Gets an image collection from image manager for a given product and supplied arguments
	 */
	public getImageCollection$(collectionId: string, retries = 0) {
		return this.http
			.get(this.environment.thirdParty.images.imageCollections.url + collectionId)
			.pipe(
				map((collectionData: any) => {
					return collectionData;
				}),
				retry(retries),
				catchError(Scene7Dao.handleError)
			);
	}
}
