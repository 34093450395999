/** @format */

import {
	BOPIS_AVAILABLE,
	INVENTORY_TYPE,
	NOT_BUYABLE_OPEN_STATUS,
	OPEN_STORE_STATUS,
	StoreStatus
} from '@shared/data-access/enums';
import { EmitEvent, EventBusService, Events } from './event-bus.service';
import {
	ISku,
	ISkuLocation,
	StoreDetails,
	StoreInformation,
	StoreLayerItem
} from '@shared/data-access/interfaces';
import { Observable, forkJoin, zip } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CookieService } from './cookie.service';
import { IStoreSelectItems } from './../../../../store-selection/src/lib/interfaces/store-selection-store-select-items.interface';
import { Injectable } from '@angular/core';
import { Store } from '../persistence/app.store';
import { StoreDao } from '../../../../shared/data-access/dao/src/lib/store.dao';
import { UtilityService } from './utility.service';

@Injectable({
	providedIn: 'root'
})
export class StoreService {
	constructor(
		private cookieService: CookieService,
		private eventBusService: EventBusService,
		private store: Store,
		private storeDao: StoreDao,
		private utilityService: UtilityService
	) {}

	/**
	 * @description
	 * Initializes the Store Service.  Does any data setup as necessary
	 */
	public initialize() {
		// Attempt to get the saved store
		const currentSavedStore = this.getSavedCustomerStore();

		// Check to see if there is a saved store
		if (
			typeof currentSavedStore === 'string' &&
			currentSavedStore.trim() !== '' &&
			currentSavedStore.length > 0 &&
			!currentSavedStore.includes('undefined')
		) {
			// Set the Cart count on the Store
			this.store.set('CUST_STORE', currentSavedStore);
		} else {
			// Attempt to get the geolocation of the user
			this.utilityService.getCurrentGeolocationPosition().subscribe({
				next: (position: GeolocationPosition) => {
					// Check to see if position is available
					if (typeof position !== 'undefined') {
						// Set the locationallows cookie to Y,incase user clicks ALLOW
						this.cookieService.set('locationAllowed', 'Y', 30);

						// Get the list of stores within the current position radius
						this.handleGeoLocationResponseNext(position);
					}
				},
				error: (error) => {
					// Trigger the StoreInventoryResults Event with an empty result
					this.eventBusService.emit(new EmitEvent(Events.StoreInventoryResults, []));

					// Set the locationallows cookie to N,incase user clicks BLOCK
					this.cookieService.set('locationAllowed', 'N', 30);

					// Get the list of stores within the current position radius
					const zipCode = this.cookieService.get('whereabouts');
					if (
						typeof zipCode === 'string' &&
						zipCode.trim() !== '' &&
						zipCode.length > 0
					) {
						this.handleGeoLocationResponseNext(undefined, zipCode);
					} else {
						console.log('Location Cookie is not available');
					}

					// Throw error
					throw new Error(JSON.stringify(error, ['message']));
				}
			});
		}

		// Subscribe to the store
		this.store.select('CUST_STORE').subscribe((currentStore: string) => {
			// Don't set a cookie value of 0 since it's the default value
			if (typeof currentStore !== 'undefined') {
				// Update the cookie
				this.setCustomerStoreCookie(currentStore);
			}
		});
	}

	public handleGeoLocationResponseNext(position: GeolocationPosition, zipcode?: string) {
		// Check to see if position is available

		let latitude = 0;
		let longitude = 0;
		/* istanbul ignore else */
		if (typeof position !== 'undefined') {
			latitude = position.coords.latitude;
			longitude = position.coords.longitude;
		}
		// Get the list of stores within the current position radius
		this.getAllStoresWithinRadius$(latitude, longitude, zipcode ? zipcode : '', 2).subscribe(
			(resultList) => {
				// Check the length of the result list
				/* istanbul ignore else */
				if (Array.isArray(resultList.results) && resultList.results.length > 0) {
					// Get the closest store
					const closestStore = resultList.results[0].store;

					// Update the application store
					if (
						closestStore.zip &&
						closestStore.name &&
						closestStore.location &&
						closestStore.status
					) {
						this.store.set(
							'CUST_STORE',
							`${closestStore.zip.match(/\d{5}/)[0]}_${closestStore.name}_${
								closestStore.location
							}_${closestStore.status}`
						);
					}
				}
			}
		);
	}

	/**
	 * @description
	 * Gets a list of stores within the search radius of the provided coordinates
	 *
	 * @param latitude - The latitude
	 * @param longitude - The longitude
	 * @param retries - The number of times to retry the HTTP Request
	 * @param searchRadius - The search radius to look for stores with the provided coordinates
	 * @returns All stores within a given radius
	 */
	public getAllStoresWithinRadius$(
		latitude: number,
		longitude: number,
		zipCode?: string,
		retries?: number,
		searchRadius?: number
	): Observable<any> {
		return this.storeDao.getAllStoresWithinRadius$(
			latitude,
			longitude,
			zipCode,
			retries,
			searchRadius,
			true
		);
	}

	/**
	 * @description
	 * Accepts SKU(s) and location and returns inventory details for the supplied SKU(s) by given location.
	 * NOTE: If multiple locations are provided, only a single-dimension Array will be returned of ISkuLocation
	 * objects.  The consumer of this method will need to take care to check location number as necessary.
	 */
	public getSkuInventoryByLocation$(
		skuData: number | string | number[] | string[],
		location: number | string | number[] | string[],
		retries?: number
	): Observable<ISkuLocation[]> {
		return this.storeDao.getSkuInventoryByLocation$(skuData, location, retries).pipe(
			map((skuInventoryDetailsResponse: ISkuLocation[]) => {
				const skuInventoryDetails: ISkuLocation[] = Object.assign(
					[],
					skuInventoryDetailsResponse
				);

				// Check to see if there are SKU location inventory details to iterate
				/* istanbul ignore else */
				if (Array.isArray(skuInventoryDetails) && skuInventoryDetails.length > 0) {
					// Iterate the array
					for (let i = 0, length = skuInventoryDetails.length; i < length; i += 1) {
						// Check to see that there is a location present and "available to sell" quantity
						/* istanbul ignore else */
						if (skuInventoryDetails[i].location && skuInventoryDetails[i].atsqty) {
							// Parse the ATS quantity to a number
							skuInventoryDetails[i].inventory = parseInt(
								skuInventoryDetails[i].atsqty,
								10
							);
						}

						// Check to see that there is a location present and "in store availability" quantity
						/* istanbul ignore else */
						if (skuInventoryDetails[i].location && skuInventoryDetails[i].isaqty) {
							// Parse the ATS quantity to a number
							skuInventoryDetails[i].isaInventory = parseInt(
								skuInventoryDetails[i].isaqty,
								10
							);
						}
					}
				}

				return skuInventoryDetails;
			})
		);
	}

	/**
	 * @description
	 * Attempts to lookup a SKU location from a provided ISkuLocation list.  It
	 * will return undefined if it cannot find one.
	 */
	public getSkuInventoryFromLocations(
		skuLocations: ISkuLocation[],
		skuId: string | number
	): ISkuLocation | undefined {
		if (skuLocations !== undefined && skuLocations.length > 0) {
			for (const skuLocation of skuLocations) {
				if (`${skuLocation.sku}` === `${skuId}`) {
					return skuLocation;
				}
			}
		}
		return;
	}

	/**
	 * @description
	 * Gets a list of stores within the search radius for the selected Sku
	 *
	 * @param zipcode - The zipcode/state/address entered by user
	 * @param productSku - The resolved SKU
	 * @param qty - Product Quantity
	 * @param isAvailabilitySelected - Boolean for show store with availability
	 * @param retries - The number of times to retry the HTTP Request
	 * @returns The store information
	 */
	public getStoreInformation$(
		zipcode: string,
		productSku: string,
		qty: number,
		isAvailabilitySelected: boolean,
		retries?: number
	) {
		// Check to see if a SKU was provided
		if (productSku) {
			return this.storeDao.getStoreInformation$(
				zipcode,
				productSku,
				qty,
				isAvailabilitySelected
			);
		}

		// Default the coordinates
		let coordinates = ['0', '0'];

		// Check to see if geo coordinates were provided as a string
		if (zipcode.match(',')) {
			coordinates = zipcode.split(',');
		}

		// Get the stores
		return this.storeDao
			.getAllStoresWithinRadius$(coordinates[0], coordinates[1], zipcode, retries)
			.pipe(
				map((requestResponse: any) => {
					return {
						meta: [
							{
								key: requestResponse.code
							}
						],
						data: {
							origin: requestResponse.origin,
							results: requestResponse.results
						}
					};
				})
			);
	}

	/**
	 * @description
	 * Checks to see if the customer store has been saved and returns it, otherwise returns undefined
	 *
	 * @returns The saved store or undefined
	 */
	public getSavedCustomerStore(): string | undefined {
		// Current saved store
		let savedStore: string;

		/*
		 * Use the NGX cookie as the primary data, and override from the legacy cookie where necessary
		 */
		// Check to see if NGX cookie is present
		if (this.cookieService.check('DCSG_NGX_CUST_STORE')) {
			// Set the Cart count
			savedStore = this.cookieService.get('DCSG_NGX_CUST_STORE');
		}

		// Check to see if legacy cookie is present
		if (this.cookieService.check('setStoreCookie')) {
			// Set the Cart count
			savedStore = this.cookieService.get('setStoreCookie');
		}

		// Return the store value
		return savedStore;
	}

	/**
	 * @description
	 * Sets a cookie with the currently set store for the customer (default or selected in Store layer)
	 *
	 * @param cookieString - The name of the store
	 */
	public setCustomerStoreCookie(cookieString: string): void {
		// Set the NGX cookie
		this.cookieService.set('DCSG_NGX_CUST_STORE', `${cookieString}`, 300, '/');

		// Set the legacy cookie
		this.cookieService.set('setStoreCookie', `${cookieString}`, 300, '/');
	}

	/**
	 * @description
	 * Given the string {zipcode}_{name}_{number}_{status}, splits the string and returns an object with each value
	 *
	 * @param cookieString - string with zipcode, name and number of the store
	 */
	public getStoreDataFromStoreCookieString(cookieString: string): StoreDetails {
		if (!cookieString || cookieString.length === 0) {
			return null;
		}

		const [storeZip, storeName, storeNumber, storeStatus] = cookieString.split('_');

		return {
			storeZip,
			storeName,
			storeNumber,
			storeStatus
		};
	}

	/**
	 * @description Adds bopis skus to StoreInformation Object. Used on Billing/Shipping.
	 * @param cartItems
	 */
	public getSkuStoreInformation(cartItems: any[]): Observable<StoreInformation[]> {
		const storeNumbers = this.getUniqueStoreNumbersFromCartItems(cartItems);
		const storeInformationRequests = storeNumbers.map((storeNumber) => {
			return this.getBopisStoreInformation(storeNumber);
		});

		return forkJoin(storeInformationRequests).pipe(
			filter(
				(storeInformation: StoreInformation[]) =>
					typeof storeInformation !== 'undefined' && storeInformation !== null
			),
			map((storeInformationList) => {
				return storeInformationList.map((store: StoreInformation) => {
					// TODO: Rewrite this as store.items isnt part of the response.
					store.items = cartItems.filter(
						(item) => item.bopis_store && item.bopis_store === store.store
					);
					return store;
				});
			})
		);
	}

	public getUniqueStoreNumbersFromCartItems(cartItems: any[]): string[] {
		return cartItems.reduce((storeNumbers, item) => {
			if (
				item.bopis_store &&
				!storeNumbers.find((storeNumber) => item.bopis_store === storeNumber)
			) {
				storeNumbers.push(item.bopis_store);
			}
			return storeNumbers;
		}, []);
	}

	/**
	 * @description
	 * gets the store information for bopis orders
	 *
	 * @param storeNumber - id of the store
	 */
	public getBopisStoreInformation(
		storeNumber: string,
		useV3 = false
	): Observable<StoreInformation> {
		return this.storeDao.getBopisStoreInformation$(storeNumber, useV3);
	}

	/**
	 * @description
	 * This method will search through the provided `inventoryData` to find
	 * the total inventory amount for a given `sku` and `location`.  It will return `0` if no
	 * inventory data is found for the provided `sku` and/or `location`.
	 *
	 * @param sku - Sku your searching inventory data for
	 * @param location - Store location your searching inventory for
	 * @param inventoryData - Inventory data to search through
	 */
	public findSkuLocationInventory(
		sku,
		location,
		inventoryData: any[],
		inventoryField = 'atsqty'
	): number {
		const inventory = inventoryData.find((value) => {
			return value.sku === sku && value.location === location;
		});

		return inventory ? +inventory[inventoryField] : 0;
	}

	// Brandify Calls
	/**
	 * @description
	 * Initializes the Store Service.  Does any data setup as necessary
	 */
	/* istanbul ignore next */
	public initializeBrandify() {
		// Attempt to get the saved store
		const currentSavedStore = this.getSavedCustomerStore();

		// Check to see if there is a saved store
		if (
			typeof currentSavedStore === 'string' &&
			currentSavedStore.trim() !== '' &&
			currentSavedStore.length > 0
		) {
			// Set the Cart count on the Store
			this.store.set('CUST_STORE', currentSavedStore);
		} else {
			// Attempt to get the geolocation of the user
			this.utilityService.getCurrentGeolocationPosition().subscribe({
				next: (position: GeolocationPosition) => {
					// Check to see if position is available
					if (typeof position !== 'undefined') {
						// Set the locationallows cookie to Y,incase user clicks ALLOW
						this.cookieService.set('locationAllowed', 'Y', 300);

						// Get the list of stores within the current position radius
						this.getAllStoresUsingZipOrCoords(
							position.coords.latitude,
							position.coords.longitude,
							2
						);
					}
				},
				error: (error) => {
					// Trigger the StoreInventoryResults Event with an empty result
					this.eventBusService.emit(new EmitEvent(Events.StoreInventoryResults, []));

					// Set the locationallows cookie to N,incase user clicks BLOCK
					this.cookieService.set('locationAllowed', 'N', 300);

					// Get the list of stores within the current position radius
					const zipCode = this.cookieService.get('whereabouts');
					if (
						typeof zipCode === 'string' &&
						zipCode.trim() !== '' &&
						zipCode.length > 0
					) {
						this.getAllStoresUsingZipOrCoords(0, 0, 2, zipCode);
					} else {
						console.log('the whereabouts cookie is not available');
					}

					// Throw error
					throw new Error(JSON.stringify(error, ['message']));
				}
			});
		}

		// Subscribe to the store
		this.store.select('CUST_STORE').subscribe((currentStore: string) => {
			// Don't set a cookie value of 0 since it's the default value
			if (typeof currentStore !== 'undefined') {
				// Update the cookie
				this.setCustomerStoreCookie(currentStore);
			}
		});
	}

	/**
	 * @description
	 * get the user's nearest store based on zip code/coordinates
	 *
	 * @param latitude - latitude
	 * @param longitude - longitude
	 * @param retries - number of retries to get store list
	 * @param zipCode - zip code.This will be available if user denies to give geo coordinates.
	 */
	private getAllStoresUsingZipOrCoords(
		latitude: number,
		longitude: number,
		retries?: number,
		zipCode?: string
	) {
		// Get the list of stores within the current position radius
		this.getAllStoresWithinRadiusBrandify$(latitude, longitude, retries, zipCode).subscribe(
			(resultList) => {
				// Check the length of the result list
				if (
					Array.isArray(resultList.response.collection) &&
					resultList.response.collection.length > 0
				) {
					// Get the closest store
					const closestStore = resultList.response.collection[0];

					// Update the application store
					this.store.set(
						'CUST_STORE',
						`${closestStore.postalcode}_${closestStore.name}_${closestStore.clientkey}`
					);
				}
			}
		);
	}

	/**
	 * @description
	 * Gets a list of stores within the search radius of the provided coordinates
	 *
	 * @param latitude - The latitude
	 * @param longitude - The longitude
	 * @param retries - The number of times to retry the HTTP Request
	 * @param searchRadius - The search radius to look for stores with the provided coordinates
	 * @returns - {Observable<any>}
	 */
	public getAllStoresWithinRadiusBrandify$(
		latitude: number,
		longitude: number,
		retries?: number,
		zipCode?: string,
		searchRadius?: number
	): Observable<any> {
		return this.storeDao.getAllStoresWithinRadiusBrandify$(
			latitude,
			longitude,
			zipCode,
			retries,
			searchRadius
		);
	}

	public isStoreStatusOpen(status: any) {
		// return true for now bc store is not set
		if (status <= 0) {
			return true;
		}
		return OPEN_STORE_STATUS.indexOf(status) >= 0;
	}

	public isStoreOpenForBopisOnly(status: any) {
		if (status <= 0) {
			return true;
		}
		return BOPIS_AVAILABLE.indexOf(status) >= 0;
	}

	public isStoreStatusFour(storeStatus: any) {
		if (storeStatus <= 0) {
			return true;
		}
		return StoreStatus.OPEN_FOR_SHIP_FROM_STORE_AND_BOPIS_AND_FOOT_TRAFFIC === storeStatus;
	}

	public isStoreStatusFive(storeStatus: any) {
		if (storeStatus <= 0) {
			return true;
		}
		return StoreStatus.OPEN_FOR_FOOT_TRAFFIC_CLOSED_FOR_BOPIS === storeStatus;
	}

	public isStoreStatusThree(storeStatus: any) {
		if (storeStatus <= 0) {
			return true;
		}
		return (
			StoreStatus.OPEN_FOR_SHIP_FROM_STORE_AND_BOPIS_CLOSED_FOR_FOOT_TRAFIC === storeStatus
		);
	}

	public isNonBuyableProductStoreOpen(status: any) {
		if (status <= 0) {
			return true;
		}
		return NOT_BUYABLE_OPEN_STATUS.indexOf(status) >= 0;
	}

	/**
	 * @description
	 * Determines which enum to use based off the isa and bopis flags
	 * @param isaFlag
	 * @param bopisFlag
	 */
	public findInventoryType(isaFlag: boolean, bopisFlag: boolean, bypass: boolean) {
		// sku is bopisable and sku is isa return both
		if (bopisFlag && isaFlag) {
			return INVENTORY_TYPE.BOTH;
		}
		// sku only bopis or from cart
		if ((bopisFlag && !isaFlag) || bypass) {
			return INVENTORY_TYPE.ATS;
		}
		// sku only ISA
		if (!bopisFlag && isaFlag) {
			return INVENTORY_TYPE.ISA;
		}
		return INVENTORY_TYPE.NONE;
	}

	/**
	 * @description
	 * Returns inventory count based of INVENTORY_TYPE enum
	 * @param inventoryType
	 * @param store
	 */
	public fetchInventoryFromStore(
		inventoryType: INVENTORY_TYPE,
		atsInventory: string | number,
		isaInventory: string | number,
		crossChainBopis = false
	) {
		if (inventoryType === INVENTORY_TYPE.BOTH) {
			return (
				this.utilityService.convertToNumber(atsInventory) +
				this.utilityService.convertToNumber(isaInventory)
			);
		}
		if (inventoryType === INVENTORY_TYPE.ATS) {
			return this.utilityService.convertToNumber(atsInventory);
		}

		if (inventoryType === INVENTORY_TYPE.ISA) {
			return this.utilityService.convertToNumber(isaInventory);
		}
		if (crossChainBopis && inventoryType === INVENTORY_TYPE.NONE) {
			return (
				this.utilityService.convertToNumber(isaInventory) +
				this.utilityService.convertToNumber(atsInventory)
			);
		}
		return 0;
	}

	/**
	 * @description
	 * finds store data from the requested key
	 * Source data comes from the cookie set
	 * @param storeString
	 * @param req
	 */
	public findStoreData(storeString: string | any, req: string): string | undefined {
		if (
			typeof storeString === 'string' &&
			storeString.trim() !== '' &&
			storeString.length > 0
		) {
			const storeTokens = storeString.split('_');

			if (req.toLocaleLowerCase().trim() === 'zip' && Array.isArray(storeTokens)) {
				return storeTokens[0];
			} else if (
				req.toLocaleLowerCase().trim() === 'storename' &&
				Array.isArray(storeTokens)
			) {
				return storeTokens[1];
			} else if (
				req.toLocaleLowerCase().trim() === 'storenumber' &&
				Array.isArray(storeTokens)
			) {
				return storeTokens[2];
			} else if (
				req.toLocaleLowerCase().trim() === 'storestatus' &&
				Array.isArray(storeTokens)
			) {
				return storeTokens[3];
			}
		}
		return;
	}

	/**
	 * @description
	 * Helper function that will populate customer selected store information from the cookie
	 * @param currentSavedStore
	 * @param storeDetails
	 */
	public populateStore(currentSavedStore: string, storeDetails: StoreDetails) {
		// Set the store zip/postal code
		storeDetails.storeZip = this.findStoreData(currentSavedStore, 'zip');

		// Set the store zip/postal code
		storeDetails.storeName = this.findStoreData(currentSavedStore, 'storename');

		// Set the store zip/postal code
		storeDetails.storeNumber = this.findStoreData(currentSavedStore, 'storenumber');

		if (this.findStoreData(currentSavedStore, 'storeStatus') === undefined) {
			if (storeDetails !== undefined && storeDetails.storeNumber !== undefined) {
				const STORE_OBSERVABLE = this.getBopisStoreInformation(storeDetails.storeNumber);
				const DATA_OBSERVABLES = zip(STORE_OBSERVABLE);
				DATA_OBSERVABLES.subscribe((values) => {
					if (Array.isArray(values) && values.length > 0) {
						storeDetails = storeDetails === undefined ? {} : storeDetails;
						storeDetails.storeStatus = values[0].status;
					}
				});
			}
		} else {
			storeDetails.storeStatus = this.findStoreData(currentSavedStore, 'storeStatus');
		}
	}
	/**
	 * @description
	 * finds the actual store layer item if it is selected
	 * @param list
	 * @return StoreLayerItem[]
	 */
	public findSelectedSku(list): StoreLayerItem[] | any {
		if (list === undefined) {
			return undefined;
		}
		return list.filter((item) => {
			return item.selectedSku ? item : undefined;
		});
	}

	/**
	 * @description
	 * determines if any skus are selected
	 * @param skus
	 * @param selectedSku
	 *
	 * @return StoreLayerItem[]
	 */
	public isSkuInStore(skus: any, skuIds: string[] | string): StoreLayerItem[] | any {
		if (skus === undefined) {
			return undefined;
		}
		return skus.filter((item) => {
			return skuIds.indexOf(item.sku) >= 0;
		});
	}

	/**
	 * @description
	 * Finds all the selected objects and adds its id to a list
	 * @param list
	 */
	public convertDataIntoList(list: StoreLayerItem[] | IStoreSelectItems[]) {
		if (list === undefined) {
			return [];
		}

		const idList = [];
		for (let i = 0, length = list.length; i < length; i += 1) {
			const current = list[i];
			if (current.selectedSku) {
				idList.push(current.id);
			}
		}
		return idList;
	}
	/**
	 * @description
	 * Checks if a sku is selected in the store items
	 */
	public isSkuSelected(list): boolean {
		if (list === undefined) {
			return false;
		}
		return list.some((item) => {
			return this.utilityService.isTruthy(item?.selectedSku);
		});
	}

	/**
	 * Populates the store item list based off the skus provided the current product
	 * @param skus
	 * @param selectedSkuId
	 * @param isAtLeastOneSkuBopis
	 */
	public populateStoreLayerItems(
		skus: ISku[],
		selectedSkuId: any,
		isAtLeastOneSkuBopis: boolean | any
	): StoreLayerItem[] {
		const storeLayerItems = [];

		if (skus === undefined) {
			return storeLayerItems;
		}

		for (let i = 0, length = skus.length; i < length; i += 1) {
			const currentSku = skus[i];
			storeLayerItems.push({
				bopisable: currentSku.descAttributesParsed.isBopisEnabled,
				isaFlag: currentSku.descAttributesParsed.isIsaEnabled,
				buyable: currentSku.buyable,
				displayHomr:
					currentSku.descAttributesParsed.isPersonalizable || isAtLeastOneSkuBopis,
				id: currentSku.id,
				selectedSku: selectedSkuId === currentSku.id
			} as StoreLayerItem);
		}
		return storeLayerItems;
	}

	/**
	 * @description
	 * Filters list of ISkuLocations based off the passed in store location number
	 * @param skuLocations
	 * @param storeLocationNumber
	 */
	public filterResults(skuLocations: ISkuLocation[], storeLocationNumber: number | string | any) {
		if (typeof skuLocations === 'undefined') {
			return [];
		}

		const returnList = [];

		const convertedStoreNumber = this.utilityService.convertToNumber(storeLocationNumber);

		if (typeof convertedStoreNumber === 'undefined') {
			return returnList;
		}

		for (let i = 0, length = skuLocations.length; i < length; i += 1) {
			const skuLocation = skuLocations[i];

			const skuLocationStoreNumber = this.utilityService.convertToNumber(
				skuLocation.location
			);

			if (skuLocationStoreNumber === convertedStoreNumber) {
				returnList.push(skuLocation);
			}
		}

		return returnList;
	}

	public updateSku(list: StoreLayerItem[], id: string | number, isSelected: boolean) {
		if (list === undefined) {
			return undefined;
		}
		return list.find((item) => {
			if (item.id === id) {
				item.selectedSku = isSelected;
			}
		});
	}
}
