/** @format */
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	Component,
	Inject,
	OnDestroy,
	PLATFORM_ID,
	ViewChild,
	ViewContainerRef
} from '@angular/core';
import { Subscription } from 'rxjs';

import { TaggingService } from '@dcsg-ngx-ecommerce/engage/service/tagging.service';

import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import {
	AnalyticsService,
	Chain,
	DynamicTemplateLoader,
	Store,
	WINDOW
} from '@dcsg-ngx-ecommerce/core';
import { ComponentBindings } from '../../../app.component-modules';
import { HomeDefault } from './home-default';
import { triggerTargetView } from '@home/utils';
import { MetaService, SeoService } from '@seo/data-access';

@Component({
	selector: 'home-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	providers: [HomeDefault]
})
export class HomeComponent implements AfterViewInit, OnDestroy {
	public templateData: any = {};
	public componentList: any;
	public surveyUrl: string;
	public DSGShoppingPathJS;
	public certonaSectionNumber;
	public certonaRecommendationsResponse: any;

	private elementDataSubscription: Subscription;
	private templateLoaderSubscription: Subscription;

	// Flags
	public flagSurvey: string;

	public components: any;

	@ViewChild('templateOutlet', { read: ViewContainerRef })
	templateOutlet: ViewContainerRef;
	children: any[] = [];
	pageType = 'home';
	searchDexResponse: any;

	/**
	 * @type boolean
	 *
	 * @description
	 * Boolean for displaying featuredSearchdex categories
	 */
	public showFeaturedCategories = false;

	constructor(
		@Inject(DOCUMENT) private document: any,
		@Inject(WINDOW) private window: Window,
		@Inject(PLATFORM_ID) protected platformId: any,
		private analyticsService: AnalyticsService,
		private taggingService: TaggingService,
		private homeDefault: HomeDefault,
		public store: Store,
		private chain: Chain,
		private route: ActivatedRoute,
		private title: Title,
		private metaService: MetaService,
		private seoService: SeoService,
		private dynamicTemplateLoader: DynamicTemplateLoader
	) {
		// TODO: figure out survey this.flagSurvey = false;
		this.components = ComponentBindings();
	}

	ngAfterViewInit() {
		this.route.data.subscribe((data) => {
			this.setTemplateData(data);
		});

		if (typeof this.window.certonaRecommendations === 'undefined') {
			this.window.DSGShoppingPathJS = {};
			// Assign the Component method to the global Window so that Certona can execute it, passing in `this` as context
			this.window.certonaRecommendations = this.getCertonaRecommendations.bind(this);
			this.window.DSGShoppingPathJS.certonaRecommendations =
				this.getCertonaRecommendations.bind(this);
		}
	}

	ngOnDestroy() {
		if (this.elementDataSubscription) {
			this.elementDataSubscription.unsubscribe();
		}

		if (this.templateLoaderSubscription) {
			this.templateLoaderSubscription.unsubscribe();
		}
	}

	public setTemplateData(data) {
		this.templateData =
			data.homrData && data.homrData.content
				? data.homrData
				: this.getEvergreen(this.chain.chainIdentifierAbbr);
		this.taggingService.setTemplateTag(this.templateData.templateTag);
		this.setPageTitleAndMetaDescription(data);
		this.loadTemplate();
	}

	public setPageTitleAndMetaDescription(data) {
		this.title.setTitle(data?.homrData?.pageTitle || this.chain.metaTitle);
		const metaDescription = data?.homrData?.metaDescription || this.chain.metaDescription;
		this.metaService.addTag({ name: 'description', content: metaDescription }, true);
		this.metaService.addTag({ property: 'og:url', content: this.chain.metaCanonical }, true);
		if (this.chain.chainIdentifierAbbr !== 'PLF' || this.chain.chainIdentifierAbbr !== 'SM') {
			this.seoService.setSearchAction(this.chain.metaCanonical); // Sets the search action script tag
		}
	}

	public async loadTemplate() {
		const keys = Object.keys(this.templateData.content);
		for (const k in keys) {
			if (this.templateData.content[keys[k]]) {
				const key = keys[k];
				const data = this.templateData.content[key];
				if (this.components[key]) {
					await this.components[key](
						this.dynamicTemplateLoader,
						data,
						this.templateOutlet,
						{ tagSectionNum: +k + 1, widgetTypeTag: key }
					);
				}
			}
		}

		if (isPlatformBrowser(this.platformId) && !this.document.body.classList.contains('aos')) {
			// Trigger the Post-render Event
			this.analyticsService.triggerAnalyticsEvent('NewPageView');
			triggerTargetView(`homepage--post-content-load`);
		}
	}

	public getEvergreen(chainString: string) {
		return this.homeDefault.getDefault(chainString);
	}

	public getCertonaRecommendations(certonaResponse: any) {
		// Set the response on the component
		this.store.set('CERTONA_RESPONSE', certonaResponse);
	}
}
