// Application imports
import { Chain } from '../model/chain.model';
import { DCSG_CONSTANTS } from '../properties/dcsg-constants';
import { Environment } from '../model/environment.model';
// Vendor imports
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { Scene7Dao } from '../dao/scene7.dao';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class Scene7Service {
	constructor(private chain: Chain, private environment: Environment, private scene7Dao: Scene7Dao, @Inject(PLATFORM_ID) protected platformId: any) {}

	/**
	 * @description
	 * Gets an image set from Scene7 for a given product and supplied arguments
	 */
	public getProductImageSet$(eCode: string, swatchColor = '', swatchColorPrefix = '', mediaSetSuffix = '', retries?: number): Observable<any> {
		return this.scene7Dao.getProductImageSet$(eCode, swatchColor, swatchColorPrefix, mediaSetSuffix, retries);
	}

	/**
	 * @description
	 * Returns the image URL for a Product given certain parameters
	 */
	public getProductImageUrl(eCode, inCatalogEntryTypeCode = 'productbean', inColor = '', inImagePreset = '', inParameters = {}, useImageSuffix = true): string {
		// Set the image suffix
		const imageSuffix =
			typeof inCatalogEntryTypeCode === 'string' &&
			inCatalogEntryTypeCode.toLowerCase() !== 'bundlebean' &&
			useImageSuffix
				? '_is'
				: '';

		// The Product URL
		let productUrl = '';

		// Query string
		let queryString = '';

		// Fix for GG NP0
		let imageCdn = this.environment.thirdParty.scene7.baseConfig.cdn;

		if (imageCdn === 'GolfGalaxyTest/' && window.location.hostname.includes(`prod-${ this.chain.seoIdentifier }`)) {
			imageCdn = 'GolfGalaxy/';
		}

		// Set the Protocol
		productUrl += this.environment.thirdParty.scene7.baseConfig.secureBaseUrl;

		// Set the Image Directory
		productUrl += this.environment.thirdParty.scene7.baseConfig.imageDirectory;

		// Set the CDN
		productUrl += imageCdn;

		// Set the eCode
		productUrl += encodeURIComponent(eCode);
		// Set the Color
		productUrl +=
			typeof inColor === 'string' && inColor.trim() !== ''
				? `_${inColor}`
				: '';

		// Set the image suffix
		productUrl += imageSuffix;

		// Check if image preset is provided
		/* istanbul ignore else */
		if (typeof inImagePreset === 'string' && inImagePreset.trim() !== '') {
			// Set the image preset
			queryString += `${inImagePreset}`;
		} else if (inParameters === Object(inParameters)) {
			// Build the query parameters
			queryString += Object.keys(inParameters)
				.map((key) => {
					return `${encodeURIComponent(key)}=${encodeURIComponent(
						inParameters[key]
					)}`;
				})
				.join('&');
		}

		// Check if query string was built
		if (typeof queryString === 'string' && queryString.trim() !== '') {
			// Set the query string
			productUrl += `?${queryString}`;
		}

		// Return the URL
		return productUrl;
	}

	getColorSwatchImageUrl(ecode: string, color: string, queryParamString:string): string {

		let imagePath = DCSG_CONSTANTS.baseProductImagePath;

		if(isPlatformBrowser(this.platformId) && (window.location.hostname.includes(`dksxchange`)) && !(window.location.hostname.includes(`prod-`))) {
			imagePath = `${imagePath}GolfGalaxyTest`;
		} else {
			imagePath = `${imagePath}dkscdn`;
		}

		return `${imagePath}/${ecode}_${color}_swatch/?$DSG_ProductSwatch$${queryParamString}`;
	}

	/**
	 * @description
	 * Helps create a url string for scene 7 images
	 *
	 * @param imageName - string of the scene 7 image name with any query strings
	 * @param imagePreset - preset to use for the image
	 */
	public scene7Url(
		imageName: string,
		imagePreset = 'HOMRxDEFAULT',
		isContent = false
	) {
		const params = this.environment.imagePresets ? `?$${imagePreset}$` : '';
		if (imageName && (imageName.includes('scene7.com') || imageName.includes('wcsstore'))) {
			if (this.environment.thirdParty.images.flags.useProxy) {
				imageName = imageName.replace('//dks.scene7.com', '//images.dickssportinggoods.com');
			}
			return imageName;
		} else {
			const url = this.environment.thirdParty.images.scene7proxy[isContent ? 'marketingContent' : 'marketing'];
			return `${url}${imageName}${params}`;
		}
	}

	/**
	 * @description
	 * Gets an image collection from image manager for a given product and supplied arguments
	 */
	public getImageCollection$(collectionId: string, retries?: number) {
		return this.scene7Dao.getImageCollection$(collectionId);
	}
}
