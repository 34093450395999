import { isPlatformBrowser } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID, makeStateKey, TransferState } from '@angular/core';

import { REQUEST, RESPONSE } from '@nguniversal/express-engine/tokens';

import { Observable } from 'rxjs';

import { HttpDao } from '../dao/http.dao';

import { WINDOW } from './window.service';

/** @dynamic */
@Injectable({
	providedIn: 'root'
})
export class HttpService {
	public readonly HTTP_REQUEST_HEADERS_STATE_KEY = 'http-request-headers';
	public readonly HTTP_RESPONSE_HEADERS_STATE_KEY = 'http-response-headers';
	public readonly HEADER_PRE_FIX = 'HS-';

	constructor(private httpDao: HttpDao, @Inject(PLATFORM_ID) private platformId, @Optional() @Inject(REQUEST) private request: any, @Optional() @Inject(RESPONSE) private response: any, private state: TransferState, @Inject(WINDOW) private window: Window) {}

	/**
	 * @description
	 * Checks a given query string to determine if the parameter is present
	 */
	public doesQueryStringHaveParameter(queryString: string, parameter: string): boolean {
		// Default flag to false
		let returnFlag = false;

		// Ensure there is something to check
		if (typeof queryString === 'string' && queryString.trim() !== '' && typeof parameter === 'string' && parameter.trim() !== '') {
			// Create an HttpParams instance
			const httpParams = new HttpParams({ fromString: queryString });

			// Check using HttpParams
			returnFlag = httpParams.has(parameter);
		}

		// Return the flag
		return returnFlag;
	}

	/**
	 * @description
	 * Returns the Host name
	 */
	public getHostName(): string {
		// Host name string
		let hostName = '';

		// Check to see if browser or server
		if (!isPlatformBrowser(this.platformId)) {
			// Get the Host name from Express
			hostName = this.request.hostname;
		} else {
			// Get the Host name from the window
			hostName = this.window.location.hostname;
		}

		// Return the Host name
		return hostName;
	}

	/**
	 * @description
	 * Returns a decoded, non-normalized query parameter string
	 */
	public getDecodedNonNormalizedQueryString(): string {
		// Return parameters
		let returnParameters = '';

		// Check to see if browser or server
		if (!isPlatformBrowser(this.platformId)) {
			// Get the query parameters name from Express and convert them to a string via HttpParams
			returnParameters = new HttpParams({ fromObject: this.request.query }).toString();
		} else {
			// Get the query parameters name from the window
			returnParameters = new HttpParams({ fromString: this.window.location.search.substring(this.window.location.search.indexOf('?') + 1) }).toString();
		}

		// Attempt to decode
		try {
			returnParameters = decodeURIComponent(returnParameters);
		} catch (error) {
			// Throw the error
			throw new Error(`Error decoding query string: ${ error }`);
		}

		// Return the string
		return returnParameters;
	}

	/**
	 * @description
	 * Gets a single value or all values (as a comma delimited string) for a given parameter in a given query string.  If the parameter is not present,
	 * it will return an empty string
	 */
	public getParameterValuesFromQueryString(queryString: string, parameter: string, getAllValues = false): string | undefined {
		// Return value
		let returnValue;

		// Ensure there is something to check
		/* istanbul ignore else */
		if (typeof queryString === 'string' && queryString.trim() !== '' && typeof parameter === 'string' && parameter.trim() !== '') {
			// Create an HttpParams instance
			const httpParams = new HttpParams({ fromString: queryString });

			// Ensure the parameter is present
			if (httpParams.has(parameter)) {
				// Check if we need to get first occurrence or all occurrences
				if (getAllValues) {
					// Check all values using HttpParams
					returnValue = httpParams.getAll(parameter).toString();
				} else {
					// Check single value using HttpParams
					returnValue = httpParams.get(parameter);
				}
			}
		}

		// Return the value
		return returnValue;
	}

	/**
	 * @description
	 * Attempts to get an HTTP Request header.  It will return undefined if not present on the request.
	 * NOTE: This will only return a value if execute in the server context, HTTP Request is not
	 * available in the browser context.
	 */
	public getRequestHeader(key: string): string | undefined {
		/* istanbul ignore else */
		if (!isPlatformBrowser(this.platformId) && this.request) {
			return this.request.headers[key];
		}
	}

	/**
	 * @description
	 * Attempts to get an HTTP Request header.  It will return undefined if not present on the request.
	 * NOTE: This will only return a value if execute in the server context, HTTP Request is not
	 * available in the browser context.
	 */
	public getRequestHeaderValue(headerName: string): string | undefined {
		// Return header value
		let returnValue;

		// Check to see if browser or server
		if (!isPlatformBrowser(this.platformId)) {
			// Attempt to get the header value from the HTTP Request
			returnValue = this.request.header(headerName);
		} else {
			// Attempt to get the header value from TransferState
			const STATE_KEY = makeStateKey(this.HTTP_REQUEST_HEADERS_STATE_KEY);

			// Attempt to get the state by key
			const stateData: any = this.state.get(STATE_KEY, null);

			// Check to see if there is state data
			/* istanbul ignore else */
			if (stateData) {
				// Attempt to get the value from the state data
				returnValue = stateData[headerName];
			}
		}

		// Return the header value
		return returnValue;
	}

	public getRequestHeaderValueAndSetStateKey(headerName: string): string | undefined {
		let returnValue;
		const STATE_KEY = makeStateKey(`${ this.HTTP_REQUEST_HEADERS_STATE_KEY }-${ headerName.toLowerCase() }`);
		const stateData: any = this.state.get(STATE_KEY, null);

		if (stateData) {
			return stateData;
		}

		if (!isPlatformBrowser(this.platformId)) {
			returnValue = this.request.header(headerName);
			this.state.set(STATE_KEY, returnValue);
			return returnValue;
		}
	}

	/**
	 * @description
	 * Attempts to get an HTTP Response header.  It will return undefined if not present on the request.
	 * NOTE: This will only return a value if execute in the server context, HTTP Response is not
	 * available in the browser context.
	 */
	public getResponseHeaderValue(headerName: string): string | undefined {
		// Return header value
		let returnValue;

		// Check to see if browser or server
		if (!isPlatformBrowser(this.platformId)) {
			// Attempt to get the header value from the HTTP Request
			returnValue = this.response.get(headerName);
		} else {
			// Attempt to get the header value from TransferState
			const STATE_KEY = makeStateKey(this.HTTP_RESPONSE_HEADERS_STATE_KEY);

			// Attempt to get the state by key
			const stateData: any = this.state.get(STATE_KEY, null);

			// Check to see if there is state data
			if (stateData) {
				// Attempt to get the value from the state data
				returnValue = stateData[headerName];
			}
		}

		// Return the header value
		return returnValue;
	}

	/**
	 * @description
	 * Gets the parameter from the URL
	 *
	 * @param name - string of name of parameter
	 */
	public getUrlParameter(name): string {
		// Return string
		let returnString = '';

		// Check to see if browser or server
		/* istanbul ignore else */
		if (isPlatformBrowser(this.platformId)) {
			// eslint-disable-next-line no-useless-escape
			name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');

			const regex = new RegExp(`[\\?&]${ name }=([^&#]*)`);

			const results = regex.exec(this.window.location.search);

			// Check for results
			/* istanbul ignore else */
			if (results !== null) {
				returnString = decodeURIComponent(results[1].replace(/\+/g, ' '));
			}
		}

		// Return the value
		return returnString;
	}

	/**
	 * @description
	 * Gets the current URL path
	 */
	public getUrlPath(): string {
		// URL path string
		let urlPath = '';

		// Check to see if browser or server
		if (!isPlatformBrowser(this.platformId)) {
			// Get the URL path from Express
			urlPath = this.request.path;
		} else {
			// Get the URL path from the window
			urlPath = this.window.location.pathname;
		}

		// Return the URL path
		return urlPath;
	}

	/**
	 * @description
	 * Gets the current URL protocol
	 */
	public getUrlProtocol(): string {
		// URL protocol string
		let urlProtocol = '';

		// Check to see if browser or server
		if (!isPlatformBrowser(this.platformId)) {
			// Get the URL protocol from Express
			urlProtocol = this.request.protocol.replace(':', '');
		} else {
			// Get the URL path from the window
			urlProtocol = this.window.location.protocol.replace(':', '');
		}

		// Return the URL protocol
		return urlProtocol;
	}

	/**
	 * @description
	 * Returns the User-Agent of the Client
	 */
	public getUserAgent(): string {
		// User Agent string
		let userAgent = '';

		// Check to see if browser or server
		if (!isPlatformBrowser(this.platformId)) {
			// Attempt to get the User Agent from Express
			userAgent = this.request.get('User-Agent');
		} else {
			// Attempt to get the User Agent from the Window
			userAgent = this.window.navigator.userAgent;
		}

		// Return the User Agent string
		return userAgent;
	}

	/**
	 * @description
	 * Saves HTTP Request headers to TransferState
	 */
	public saveRequestHeadersToState(headerNames: string[]): void {
		// Headers to save
		const httpHeaders: any = {};

		// Check to see if browser or server and that there are valid header names to save
		/* istanbul ignore else */
		if (!isPlatformBrowser(this.platformId) && Array.isArray(headerNames) && headerNames.length > 0) {
			// Iterate the header name
			for (let index = 0, length = headerNames.length; index < length; index += 1) {
				// Check to see if current name is valid and on the HTTP Request
				/* istanbul ignore else */
				if (typeof this.request.header(headerNames[index]) !== 'undefined') {
					// Add the header and value to the object to save
					httpHeaders[headerNames[index]] = this.request.header(headerNames[index]);
				}
			}

			// Check to see if there are any headers to save
			/* istanbul ignore else */
			if (Object.keys(httpHeaders).length > 0) {
				// Get unique key
				const STATE_KEY = makeStateKey(this.HTTP_REQUEST_HEADERS_STATE_KEY);

				// Set the headers to state
				this.state.set(STATE_KEY, httpHeaders);
			}
		}
	}

	/**
	 * @description
	 * Saves HTTP Response headers to TransferState
	 */
	public saveResponseHeadersToState(headerNames: string[]): void {
		// Headers to save
		const httpHeaders: any = {};

		// Check to see if browser or server and that there are valid header names to save
		/* istanbul ignore else */
		if (!isPlatformBrowser(this.platformId) && Array.isArray(headerNames) && headerNames.length > 0) {
			// Iterate the header name
			for (let index = 0, length = headerNames.length; index < length; index += 1) {
				// Check to see if current name is valid and on the HTTP Request
				/* istanbul ignore else */
				if (typeof this.response.get(headerNames[index]) !== 'undefined') {
					// Add the header and value to the object to save
					httpHeaders[headerNames[index]] = this.response.get(headerNames[index]);
				}
			}

			// Check to see if there are any headers to save
			/* istanbul ignore else */
			if (Object.keys(httpHeaders).length > 0) {
				// Get unique key
				const STATE_KEY = makeStateKey(this.HTTP_RESPONSE_HEADERS_STATE_KEY);

				// Set the headers to state
				this.state.set(STATE_KEY, httpHeaders);
			}
		}
	}

	/**
	 * @description
	 * Sets the HTTP Status Code
	 */
	public setStatusCode(statusCode: number): void {
		// Ensure this only occurs server-side
		/* istanbul ignore else */
		if (!isPlatformBrowser(this.platformId)) {
			// Set the status code
			this.response.status(statusCode);
		}
	}

	/**
	 * @description
	 * Redirect 301
	 */
	public redirect(url: string): void {
		// Ensure this only occurs server-side
		/* istanbul ignore else */
		if (!isPlatformBrowser(this.platformId)) {
			// Set the status code
			this.response.redirect(301, url);
		}
	}

	/**
	 * @description
	 * GET HTTP Request
	 */
	public get<T>(url: string, retries?, httpOptions?: object): Observable<T> {
		return this.httpDao.get(url, retries, httpOptions);
	}

	/**
	 * @description
	 * PATCH HTTP Request
	 */
	public patch<T>(url: string, input: object, httpOptions?: object): Observable<T> {
		return this.httpDao.patch(url, input, httpOptions);
	}

	/**
	 * @description
	 * POST HTTP Request
	 */
	public post<T>(url: string, input: object, httpOptions?: object): Observable<T> {
		return this.httpDao.post(url, input, httpOptions);
	}

	/**
	 * @description
	 * PUT HTTP Request
	 */
	public put<T>(url: string, input: object, httpOptions?: object): Observable<T> {
		return this.httpDao.put(url, input, httpOptions);
	}

	/**
	 * @description
	 * DELETE HTTP Request
	 */
	public delete<T>(url: string, httpOptions?: object): Observable<T> {
		return this.httpDao.delete(url, httpOptions);
	}

	/**
	 * @description
	 * Take a string and generates a header
	 * @param string
	 * @param separator
	 */
	public parseStringIntoHeader(string: string | any, separator: string) {
		const obj = {};

		if (string === undefined) {
			return {};
		}

		const stringList = string.split(separator);

		for (let i = 0; i < stringList.length; i++) {
			const key = `${ this.HEADER_PRE_FIX }${ String(i) }`;
			obj[key] = stringList[i];
		}
		return obj;
	}
}
