import { Injectable } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root'
})
export class MetaService {

	constructor(private metaService: Meta) {}

	/**
	 * @param tag MetaDefinition
	 * @param forceCreation boolean
	 */
	public addTag(tag: MetaDefinition, forceCreation: boolean) {
		return this.metaService.addTag(tag, true);
	}

	/**
	 * @param tags MetaDefinition
	 * @param forceCreation boolean
	 */
	public addTags(tags: MetaDefinition[], forceCreation = false) {
		return this.metaService.addTags(tags, true);
	}

	/**
	 * @param attrSelector selector
	 */
	public getTag(attrSelector: string) {
		return this.metaService.getTag(attrSelector);
	}

	/**
	 * @param attrSelector selector
	 */
	public getTags(attrSelector: string) {
		return this.metaService.getTags(attrSelector);
	}

	/**
	 * @param tag MetaDefinition
	 * @param selector selector
	 */
	public updateTag(tag: MetaDefinition, selector?: string) {
		return this.metaService.updateTag(tag, selector);
	}

	/**
	 * @param attrSelector selector
	 */
	public removeTag(attrSelector: string) {
		this.metaService.removeTag(attrSelector);
	}
}
