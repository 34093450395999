/* eslint-disable no-useless-escape */
/** @format */

import { Injectable } from '@angular/core';
import { Logger } from '@dcsg-ngx-ecommerce/log/service/logger.service';
import { UtilityService } from '@dcsg-ngx-ecommerce/core/service/utility.service';

@Injectable()
export class TaggingService {
	private templateTag: string = null;
	private campaignName = 'na';
	private defaultNone = 'na';

	constructor(private logger: Logger) {}

	/**
	 * @description
	 * Function used to create the data-em tags used in homepage construction
	 * Basic format:
	 * clp_[spotNumber]_[headline]_[alt]
	 *
	 * @param spotNumber
	 * @param headline
	 * @param alt
	 * @returns - {Tag}
	 */
	public static createSimpleTag(spotNumber = '0', headline: string, alt: string) {
		const regEx = /[.,\/#!$%\^&\*;:\'{}=\-_`~()\s]/;
		// return `clp_${UtilityService.cleanString(spotNumber, regEx)}${headline ? `_${UtilityService.cleanString(headline, regEx)}` : ''}${alt ? `_${UtilityService.cleanString(alt, regEx)}` : ''}`;
		const spotNumberClean = UtilityService.cleanString(spotNumber, regEx);
		const headlineClean = headline ? `_${UtilityService.cleanString(headline, regEx)}` : '';
		const altClean = alt ? `_${UtilityService.cleanString(alt, regEx)}` : '';
		return `clp_${spotNumberClean}${headlineClean}${altClean}`;
	}

	/**
	 * @description
	 * Function used to create the data-em tags used in Deal Drawer
	 * Basic format:
	 * LN_[drawerName]_[dealName]
	 *
	 * @param drawerName
	 * @param dealName
	 * @returns - {Tag}
	 */
	public static createDealDrawerTag(drawerName: string, dealName?: string) {
		drawerName = drawerName.replace(/\s|\!|\@|\#|\$|\&|\%|\^|\*|\(|\)|\{|\}|\[|\]|\;|\'|\"|\,|<|>|\.|\/|\\|\||\?|\_|\+|\=|\`|\~|\‘|\’|\“|\”|\…|\′/g, '');
		dealName = dealName ? dealName.replace(/\s|\!|\@|\#|\$|\&|\%|\^|\*|\(|\)|\{|\}|\[|\]|\;|\'|\"|\,|<|>|\.|\/|\\|\||\?|\_|\+|\=|\`|\~|\‘|\’|\“|\”|\…|\′/g, '') : '';
		// return `LN_${drawerName}${dealName ? `_${dealName}` : ''}`;
		let result = `LN_${drawerName}`;
		if (dealName) {
			result += `_${dealName}`;
		}
		return result;

	}

	/**
	 * @description
	 * Function used to set the base template string
	 * This is set after the template API is called and the tag name is returned from the API
	 *
	 * @param daTag
	 */
	public setTemplateTag(daTag: string) {
		this.templateTag = daTag;
	}

	/**
	 * @description
	 * Function used to create the data-em tags used in homepage construction
	 * Basic format:
	 * hp_[template#]_[section#]_[widgetTypeConcat]-[spotInSection]_[campaignName]_[cta#]-[ctaText]_[altText]
	 *
	 * @param sectionNum
	 * @param widgetType
	 * @param attrSetCount
	 * @param altText
	 * @returns - {Tag}
	 */
	public createTag(sectionNum: string, widgetType: string, attrSetIndex: number, ctaIndex: number, ctaText: string | number, altText: string | number, campaignName?: string, hideCtaIndex = false, prefix = 'clp', templateTag: string = this.templateTag) {
		// Clean up the incoming data
		if (!altText || !ctaText) {
			this.logger.debug('Alt or CTA Text Missing: ', { sectionNum, widgetType, attrSetIndex, ctaIndex, ctaText, altText, campaignName, hideCtaIndex, prefix, templateTag });
		}
		altText = altText ? altText.toString().replace(/[.,\/#!$%\^&\*;:'{}=\-_`~()\s]/g, '') : altText;
		ctaText = ctaText.toString().replace(/[.,\/#!$%\^&\*;:'{}=\-_`~()\s]/g, '');
		const ctaIndexTag = ctaIndex === 0 ? this.defaultNone : ctaIndex;

		// logic to determine if campaign name is there or not - assign 'na' if not
		campaignName = campaignName ? campaignName : this.campaignName;

		// Return the compiled tag
		// hp[template#][section#][widgetTypeConcat]-[spotInSection][campaignName][cta#]-[ctaText][altText]
		// return `${prefix}_${templateTag}_${sectionNum}_${widgetType}-${attrSetIndex}_${campaignName !== 'hidden' ? `${campaignName}_` : ''}${!hideCtaIndex ? ctaIndexTag : ''}${ctaIndexTag === this.defaultNone ? '' : '-'}${ctaText}_${altText}`;
		let result = `${prefix  }_${  templateTag  }_${  sectionNum  }_${  widgetType  }-${  attrSetIndex  }_`;

		if (campaignName !== 'hidden') {
			result += `${campaignName}_`;
		}

		if (!hideCtaIndex) {
			result += ctaIndexTag;
		}

		if (ctaIndexTag !== this.defaultNone) {
			result += '-';
		}

		result += `${ctaText}_${altText}`;

		return result;

	}

	/**
	 * @description
	 * Function used to figure out if altText is a copy of the title tag and whether
	 * to show a empty alt tag or use the altText
	 *
	 * @param altText
	 * @param title
	 * @returns empty string or altText from homR
	 */
	public altTag(altText: string, title: string): string {
		return altText.toLowerCase() === title?.toLowerCase() ? title : altText;
	}
}
