<!-- @format -->

<div
	class="content-container hmf-flex-column hmf-justify-content-center hmf-align-items-center hmf-text-align-center"
	[ngClass]="chain.chainIdentifierAbbr">
	<div
		class="text-container hmf-flex-column hmf-align-items-center hmf-text-align-center hmf-p-l">
		<h1 class="hmf-display-m hmf-display-m-l hmf-my-s">{{ errorMessageConfig?.title }}</h1>
		<p class="hmf-header-m hmf-header-m-l">{{ errorMessageConfig?.message }}</p>
		<a
			hmf-button
			[href]="errorMessageConfig?.link"
			variant="primary"
			[text]="errorMessageConfig?.linkText"></a>
	</div>
</div>
