import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CeopsService } from '../../service/ceops.service';
import { CeopsComponent } from './ceops.component';

@NgModule({
	declarations: [CeopsComponent],
	imports: [CommonModule],
	exports: [CeopsComponent],
	providers: [CeopsService]
})
export class CeopsModule {}
