export class LocalStorageConfig {
	/**
	 * Determines the key prefix. (Default: 'DCSG_NGX')
	 */
	prefix?: string;

	/**
	 * Determines if null | 'null' values should be stored. (Default: true)
	 */
	allowNull?: boolean;
}
