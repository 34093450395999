/** @format */

import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, makeStateKey, TransferState } from '@angular/core';
import { Chain } from '@dcsg-ngx-ecommerce/core/model/chain.model';

import { Environment } from '@dcsg-ngx-ecommerce/core/model/environment.model';

import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

import { HttpService } from '@dcsg-ngx-ecommerce/core';
import { CustomHttpHeaders, MediaType } from '@shared/data-access/enums';
import { Page } from '../interfaces/page.interface';


@Injectable({
	providedIn: 'root'
})
export class SeoDao {
	readonly QUESTIONS_AND_ANSWERS_STATE_KEY_PREFIX = 'q-and-a-';
	readonly REVIEWS_STATE_KEY_PREFIX = 'reviews-';
	readonly SEARCHDEX_INCLUDE_STATE_KEY_PREFIX = 'searchdex-';
	readonly SEO_TOKEN_DETAILS_STATE_KEY_PREFIX = 'details-by-seotoken-';
	readonly PAGE_SEO_STATE_KEY_PREFIX = 'page-seo-';

	constructor(
		private chain: Chain,
		private environment: Environment,
		@Inject(PLATFORM_ID) protected platformId: any,
		private http: HttpClient,
		private state: TransferState,
		private httpService: HttpService
	) {}

	/**
	 * @description
	 * Attempts to call an endpoint to get a SearchDex Managed Include. If the file is not available, a 404 will be returned.
	 */
	/* istanbul ignore next */
	/* ToDo Remove this Function, since its not being used anymore */
	public getSearchDexInclude$(fileName: string, retries = 0): Observable<any> {
		// Get unique key
		const STATE_KEY = makeStateKey(`${this.SEARCHDEX_INCLUDE_STATE_KEY_PREFIX}${fileName}`);

		// Attempt to get the state by key
		const stateData: any = this.state.get(STATE_KEY, null);

		// Check to see if state exists
		if (stateData) {
			// Return the existing state
			return new Observable((observer) => {
				observer.next(stateData);
				observer.complete();
			});
		}

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			Accept: MediaType.APPLICATION_JSON
		});

		// Custom HTTP Headers
		httpHeaders = httpHeaders.set(CustomHttpHeaders.X_PROXY_REQUEST, 'true');

		// Check platform
		if (!isPlatformBrowser(this.platformId)) {
			// Set a timeout on the server to prevent the response from taking too long
			httpHeaders = httpHeaders.set(
				CustomHttpHeaders.X_TIMEOUT,
				`${this.environment.thirdParty.searchDex.gatewayUrlProxy.timeout}`
			);
		}

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders
		};

		// Make the HTTP Request
		return this.http
			.get(
				`${this.environment.thirdParty.searchDex.gatewayUrlProxy.path}${fileName}.json`,
				httpOptions
			)
			.pipe(
				map((response: any) => {
					// Set the state data
					this.state.set(STATE_KEY, response);

					// Return the raw data
					return response;
				}),
				retry(retries)
			);
	}

	public getSEOData$(seoToken: string, retries = 0): Observable<any> {
		const ELEMENT_KEY = makeStateKey(`seo-response-${seoToken}`);
		const element: any = this.state.get(ELEMENT_KEY, null);

		// Check to see if state exists
		if (element) {
			// Return the existing state
			return new Observable((observer) => {
				observer.next(element);
				observer.complete();
			});
		}

		const requestUrl = `${this.environment.api.seoByChain}seo-category/v1/categories?seoUrl=${seoToken}&storeId=${this.chain.storeId}&children=true&published=true`;
		let httpHeaders = new HttpHeaders({
			'Content-Type': MediaType.APPLICATION_JSON
		});

		// Get Origin Host Header
		const origin = this.httpService.getRequestHeaderValueAndSetStateKey('ORIGIN_HOST');
		if (origin) {
			httpHeaders = httpHeaders.set(CustomHttpHeaders.ORIGIN, origin);
		}

		const httpOptions = {
			headers: httpHeaders
		};

		return this.http.get(requestUrl, httpOptions).pipe(
			map((response: any) => {
				this.state.set<any>(ELEMENT_KEY, response);
				return response;
			}),
			retry(retries)
		);
	}

	/**
	 * @description
	 * Gets the Page Seo by Url.The returned Page object will contain SEO related information.
	 */
	public getPageSeobyUrl$(url: string, retries = 0): Observable<Page> {
		// Get unique key
		const STATE_KEY = makeStateKey(`${this.PAGE_SEO_STATE_KEY_PREFIX}${url}`);

		// Attempt to get the state by key
		const stateData: any = this.state.get(STATE_KEY, null);

		// Check to see if state exists
		if (stateData) {
			// Return the existing state
			return new Observable((observer) => {
				observer.next(stateData);
				observer.complete();
			});
		}
		// Query String Parameters
		let httpParams = new HttpParams();

		// Set channel param
		httpParams = httpParams.set('channel', `${this.chain.chainMarketingIdentifierAbbr}`);

		// set inventory param
		httpParams = httpParams.set('inventory', 'true');

		// set active param
		httpParams = httpParams.set('active', 'true');

		// set skus param
		httpParams = httpParams.set('skus', 'false');

		// Set channel param
		httpParams = httpParams.set('url', `${url}`);

		// Standard HTTP Headers
		let httpHeaders = new HttpHeaders({
			Accept: MediaType.APPLICATION_JSON
		});

		// Get Origin Host Header
		const origin = this.httpService.getRequestHeaderValueAndSetStateKey('ORIGIN_HOST');
		if (origin) {
			httpHeaders = httpHeaders.set(CustomHttpHeaders.ORIGIN, origin);
		}

		// Check platform
		if (!isPlatformBrowser(this.platformId)) {
			// Set a timeout on the server to prevent the response from taking too long
			httpHeaders = httpHeaders.set(
				CustomHttpHeaders.X_TIMEOUT,
				`${this.environment.api.spring.microServiceProxy.seoservice.timeout}`
			);
		}

		// HTTP Options
		const httpOptions = {
			headers: httpHeaders,
			params: httpParams,
			withCredentials: true
		};

		// Make the HTTP Request
		return this.http
			.get(
				`${this.environment.api.seoByChain}catalog-productdetails/v4/byURL/${this.chain.storeId}`,
				httpOptions
			)
			.pipe(
				map((response: any) => {
					// Return page object
					let returnPage: Page = {};

					// Check to see if there is a result
					if (
						response.productsData &&
						response.productsData[0].style &&
						response.productsData[0].style.seo
					) {
						// Set the response
						returnPage = response.productsData[0].style.seo;

						returnPage = this.getUpdatedPage(returnPage);

						// Set the state
						this.state.set(STATE_KEY, returnPage as any);
					}

					// Return the raw data
					return returnPage;
				}),
				retry(retries)
			);
	}

	/**
	 * @description
	 * Remove Seo Poperties if its empty
	 */
	/* istanbul ignore next */
	private getUpdatedPage(returnPage) {
		// Check the page title
		if (typeof returnPage.title === 'string' && returnPage.title.trim() === '') {
			// Delete the page title
			delete returnPage.title;
		}

		// Check the page description
		if (
			typeof returnPage.metaDescription === 'string' &&
			returnPage.metaDescription.trim() === ''
		) {
			// Delete the page description
			delete returnPage.metaDescription;
		}

		// Check the page keywords
		if (typeof returnPage.metaKeyword === 'string' && returnPage.metaKeyword.trim() === '') {
			// Delete the page keywords
			delete returnPage.metaKeyword;
		}

		return returnPage;
	}
}
