import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GoogleAdManagerComponent } from './google-ad-manager.component';

@NgModule({
	imports: [CommonModule],
	declarations: [GoogleAdManagerComponent],
	exports: [GoogleAdManagerComponent]
})

export class GoogleAdManagerModule {}
