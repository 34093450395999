/** @format */

import {
	Chain,
	DcsgNgxEcommerceCoreModule,
	Environment,
	GlobalErrorHandler
} from '@dcsg-ngx-ecommerce/core';
import { DCSG_APP_NAME, LoggerConfig } from '@logger';
import { ErrorHandler, NgModule } from '@angular/core';
import { MAT_SELECT_SCROLL_STRATEGY_PROVIDER, MatSelectModule } from '@angular/material/select';
import { RouterModule, Routes } from '@angular/router';

import { APP_BASE_HREF } from '@angular/common';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CeopsModule } from '@dcsg-ngx-ecommerce/engage/component/ceops/ceops.module';
import { DcsgNgxEcommerceLogModule } from '@dcsg-ngx-ecommerce/log';
import { GoogleAdManagerModule } from '@dcsg-ngx-ecommerce/common/component/google-ad-manager/google-ad-manager.module';
import { HomeComponent } from './views/home/home-component/home.component';
import { HomeModule } from './views/home/home.module';
import { HomrContentResolve } from './common/resolve/homr.resolve';
import { HttpClientModule } from '@angular/common/http';
import { LocalStorageConfig } from '@shared/data-access/interfaces';
import { NoContentComponent } from './views/no-content/no-content.component';
import { StaticComponent } from './views/static/static-component/static.component';
import { StaticModule } from './views/static/static.module';
import { chain } from '@core/chain';
import { environment } from '../environments';

const APP_ROUTES: Routes = [
	{
		path: 's/:seoName',
		component: StaticComponent,
		resolve: {
			homrData: HomrContentResolve
		}
	},
	{
		path: '',
		component: HomeComponent,
		resolve: {
			homrData: HomrContentResolve
		}
	},
	{ path: 'search/SearchDisplay', component: NoContentComponent },
	{ path: '404', component: NoContentComponent },
	{ path: '**', component: NoContentComponent }
];

/*
 * App Configs
 */
const LOCAL_STORAGE_CONFIG: LocalStorageConfig = {
	prefix: ''
};

const LOGGER_CONFIG: LoggerConfig = {
	clientLoggerLevel: environment.logging.client.level,
	xhrLoggerLevel: environment.logging.xhr.level,
	xhrLoggerUrl: ``
};

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule.withServerTransition({ appId: 'dcsg-ngx-home' }),
		BrowserAnimationsModule,
		RouterModule.forRoot(APP_ROUTES, {
			useHash: false,
			initialNavigation: 'enabledBlocking',
			onSameUrlNavigation: 'ignore',
			enableTracing: false
		}),
		CeopsModule,
		GoogleAdManagerModule,
		HomeModule,
		HttpClientModule,
		DcsgNgxEcommerceLogModule.forRoot(LOGGER_CONFIG),
		DcsgNgxEcommerceCoreModule.forRoot(LOCAL_STORAGE_CONFIG),
		MatSelectModule,
		StaticModule
	],
	providers: [
		{ provide: APP_BASE_HREF, useValue: '/' },
		{ provide: Chain, useValue: chain || {} },
		{ provide: DCSG_APP_NAME, useValue: 'HOME' },
		{ provide: Environment, useValue: environment || {} },
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler
		},
		MAT_SELECT_SCROLL_STRATEGY_PROVIDER
	],
	bootstrap: [AppComponent]
})
export class AppBrowserModule {}
