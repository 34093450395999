/** @format */

export const HOME_CONSTANTS: any = {
	appPathPrefix: 'home',
	ggStaticColors: {
		primary: '#00203a',
		secondary: '#191f1c'
	},
	// used for Nike connect side panel
	// will support other brands in the future
	partyAttributes: {
		Nike: 'nike_connected'
	},
	noContentErrorMessages: {
		gg: {
			title: '4-0-Fore!',
			message: "We're sorry, this page is unavailable. Try heading back to the home page.",
			link: 'https://www.golfgalaxy.com',
			linkText: 'GO BACK HOME'
		},
		pl: {
			title: "You're off the map",
			message: "We're sorry, this page is unavailable. Let's get you back on the trail.",
			link: 'https://www.publiclands.com',
			linkText: 'GO BACK HOME'
		},
		plf: {
			title: "You're off the map",
			message: "We're sorry, this page is unavailable. Let's get you back on the trail.",
			link: 'https://www.publiclandsfund.org',
			linkText: 'GO BACK HOME'
		},
		dsg: {
			title: 'Error on the play',
			message: "We're sorry, this page is unavailable. Try heading back to the home page.",
			link: 'https://www.dickssportinggoods.com',
			linkText: 'GO BACK HOME'
		},
		sm: {
			title: 'Error on the play',
			message: "We're sorry, this page is unavailable. Try heading back to the home page.",
			link: 'https://www.sportsmatter.org',
			linkText: 'GO BACK HOME'
		},
		g3: {
			title: 'No Scores Here',
			message: "We're sorry, this page is unavailable. Try heading back to the home page.",
			link: 'https://www.goinggoinggone.com',
			linkText: 'GO BACK HOME'
		}
	}
};
