/** @format */

// Angular imports
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// Vendor imports
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

// App imports;
import { HomrDataService } from '@dcsg-ngx-ecommerce/engage';

@Injectable()
export class HomrContentResolve {
	constructor(private homrService: HomrDataService) {}

	public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		if (route.params['seoName']) {
			this.homrService.setPageName(route.params['seoName']);
			return this.homrService.getData$('static', 1, route.params['seoName'], true).pipe(
				catchError((error: any, caught: Observable<any>) => {
					return new Observable(null);
				})
			);
		}
		this.homrService.setPageName('homepage');
		return this.homrService.getData$('home', 1, 'homepage', true).pipe(
			catchError(() => {
				return new Observable(null);
			})
		);
	}
}
