/** @format */

import { Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class EventBusService {
	private subject$ = new Subject();
	private subjectSubscriptionMap: { [key: string]: Subscription } = {};
	private history = {};

	/**
	 * @description
	 * Event listener for provided Events enum value. Executes the callback provided
	 * when the Event is emitted
	 */
	public on(event: Events, action: any): Subscription {
		this.subjectSubscriptionMap[event] = this.subject$
			.pipe(
				filter((e: EmitEvent) => {
					return e.name === event;
				}),
				map((e: EmitEvent) => {
					return e.value;
				})
			)
			.subscribe(action);

		return this.subjectSubscriptionMap[event];
	}

	/**
	 * @description
	 * Emits the provided Event
	 */
	public emit(event: EmitEvent) {
		if (typeof this.history[event.name] === 'undefined') {
			this.history[event.name] = [event];
		} else {
			this.history[event.name].push(event);
		}

		this.subject$.next(event);
	}

	/**
	 * @description
	 * Unsubscribe from provided Events enum value
	 */
	public unsubscribe(event: Events) {
		// Ensure there is something to unsubscribe from
		if (typeof this.subjectSubscriptionMap[event] !== 'undefined') {
			this.subjectSubscriptionMap[event].unsubscribe();
		}
	}

	public checkHistoryForEvent(event: Events) {
		return this.history[event];
	}
}

/**
 * @description
 * EmitEvent
 */
export class EmitEvent {
	constructor(
		public name: any,
		public value?: any
	) {}
}

/**
 * @description
 * Events to be emitted and listened for
 */
// eslint-disable-next-line no-shadow

export enum Events {
	PriceBucketsInitDone,
	PromoEvent,
	AddToCartEvent,
	ChangeStore,
	ResetAddToCartButtonEvent,
	SetStoreLocationEvent,
	StoreSearchResult,
	StoreInventoryResults,
	OnlineInventoryResults,
	LowStock,
	ProductDataComplete,
	KillSwitchDataComplete,
	LaunchConfigDataComplete,
	PrimaryCategoryDataComplete,
	SkuResolve,
	NoCart,
	SkuStoreSelected,
	CartUpdateEvent,
	CheckoutUpdateEvent,
	InitiateATC,
	PostATCEvent,
	PaypalClick,
	PaypalError,
	TimerEnded,
	AddToBundle,
	CustomizeError,
	TruefitActivated,
	ProductAvailaiblityEvent,
	ShowOutOfStockRecs,
	QAndAEnabled,
	AttributeSelectionSkuResolveEvent,
	AttributeSelectionNotSkuResolveEvent,
	AttributeClickEvent,
	LoyaltyBalance,
	LoyaltyBalanceFetched,
	LoyaltyProfileFetched,
	ValidateForms,
	AlternativeInStock,
	AlternativeInStockDisableATC,
	AddressChanged,
	PCSPointsUpdated,
	StickyAtcClick,
	AtcButtonStateChange,
	AtcButtonAvailable,
	IndyPayMainScriptLoaded,
	PaymentButtonsScriptLoaded,
	PaymentButtonsErrored,
	CartRemoveProduct,
	FavoritesDeleteProduct,
	EmptyFavorites,
	CustomAttributesProcessed
}
